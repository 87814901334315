import React from 'react';
import { Avatar, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface Props {
    _id: string;
    text: string;
    createdAt: string;
    sent: boolean;
    received: boolean;
    pending: boolean;
    self: boolean;
    user: {
        _id: string;
        name: string;
        avatar: string;
    };
}

const ChatItem = (props: Props) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: props.self ? 'row-reverse' : 'row',
                width: '100%',
                alignItems: props.self ? 'end' : 'center',
                justifyContent: 'space-between',
            }}
        >
            <div className={'avatar w-1/6 h-full flex flex-col justify-center items-center'}>
                <Avatar src={props.user.avatar} />
                <div
                    style={{
                        fontSize: 10,
                    }}
                >
                    {props.user.name}
                </div>
            </div>
            <div
                className={'content bg-gray-100 w-5/6  p-2 h-full flex flex-col'}
                style={{
                    borderRadius: 10,
                    padding: 10,
                    background: props.self ? 'rgb(0, 132, 255)' : 'rgb(243 244 246 / var(--tw-bg-opacity))',
                }}
            >
                <div
                    style={{
                        color: props.self ? '#fff' : '#2C2C2E',
                    }}
                >
                    <div className={'font-normal'}>{props.text}</div>
                </div>
                <div
                    style={{
                        color: props.self ? '#fff' : 'rgb(163 163 163)',
                        fontSize: 10,
                        height: 15,
                    }}
                >
                    {dayjs(props.createdAt).format('LLL')}
                </div>
            </div>
        </div>
    );
};

export default ChatItem;
