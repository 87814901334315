import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Form, Input, InputNumber, message, Radio } from 'antd';
import { CategoryProps, ExtraListProps } from '../../../types/types.ds';
import { ProductAdminStore } from '../../../stores/Admin/ProductAdminStore';
import { CategoriesPicker } from '../../dropdowns';
import { CategoryAdminStore } from '../../../stores/Admin/CategoryAdminStore';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

interface Props {
    type: 'product' | 'category';
    handleSave: (values: any) => void;
}

export const ExtraAddForm = observer(({ type, handleSave }: Props) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [pricing, setPricing] = useState<'add' | 'replace'>('add');

    const categories = CategoryAdminStore.categories;

    return (
        <Form
            {...layout}
            form={form}
            initialValues={{
                pricing: 'add',
            }}
            onFinish={values => {
                const payload = {
                    name: values.name,
                    required: values.required || false,
                    multiple: values.multiple || false,
                    pricing: values.pricing,
                    min: values.min,
                    max: values.max,
                    categories: values.categories.map((c: any) => c.key),
                };
                handleSave(payload);
            }}
        >
            <Form.Item
                name="name"
                label="Título"
                help={'Elija un nombre para agrupar las opciones, por ejemplo Tamaño'}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            <Form.Item name="pricing" label="Cambio de Precio" rules={[{ required: true }]}>
                <Radio.Group
                    onChange={e => {
                        setPricing(e.target.value);
                    }}
                >
                    <Radio style={radioStyle} value={'add'}>
                        Sumar los valores al precio base
                    </Radio>
                    <Radio style={radioStyle} value={'replace'}>
                        Reemplaza al precio Base
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name="required"
                label="Obligatorio"
                rules={[{ required: false }]}
                help={'Selecciona si es que es obligatorio que los clientes elijan una opción para este grupo'}
                valuePropName={'checked'}
            >
                <Checkbox />
            </Form.Item>

            {pricing === 'add' && (
                <Form.Item
                    name="multiple"
                    label="Multiple Opción"
                    rules={[{ required: false }]}
                    help={'Los clientes podrán elegir varias opciones?'}
                    valuePropName={'checked'}
                >
                    <Checkbox />
                </Form.Item>
            )}

            {pricing === 'add' && (
                <Form.Item
                    name="min"
                    label="Mínimo Requerido"
                    rules={[{ required: false }]}
                    help={'Selecciona si es que hay una cantidad mínima de opciones que el cliente debe seleccionar'}
                >
                    <InputNumber />
                </Form.Item>
            )}

            {pricing === 'add' && (
                <Form.Item
                    name="max"
                    label="Máximo Permitido"
                    rules={[{ required: false }]}
                    help={'Selecciona si es que hay una cantidad máxima de opciones que el cliente debe seleccionar'}
                >
                    <InputNumber />
                </Form.Item>
            )}

            {type == 'category' && (
                <Form.Item
                    name="categories"
                    label="Categorías"
                    rules={[{ required: true }]}
                    help={'Seleccione las categorías a las que estará asociada esta variación'}
                >
                    <CategoriesPicker mode={'multiple'} categories={categories} />
                </Form.Item>
            )}

            <Form.Item {...tailLayout}>
                <Button type="primary" size={'large'} htmlType="submit" loading={isLoading}>
                    <i className="fas fa-search" /> &nbsp; Guardar
                </Button>
            </Form.Item>
        </Form>
    );
});

interface ExtraEditFormProps {
    item: ExtraListProps;
    onUpdated: () => void;
}

export const ExtraEditForm = observer(({ item, onUpdated }: ExtraEditFormProps) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [pricing, setPricing] = useState<'add' | 'replace'>(item.pricing);

    const categories = CategoryAdminStore.categories;

    return (
        <Form
            form={form}
            initialValues={{
                name: item.name,
                required: item.required,
                multiple: item.multiple || false,
                pricing: item.pricing,
                min: item.min,
                max: item.max,
                categories: item.categories_related.map(c => {
                    return {
                        key: c.uid,
                        label: c.name,
                    };
                }),
            }}
            onFinish={values => {
                const payload = {
                    name: values.name,
                    required: values.required || false,
                    multiple: values.multiple || false,
                    pricing: values.pricing,
                    min: values.min,
                    max: values.max,
                    categories: values.categories?.map((c: any) => c.key) ?? null,
                };
                setLoading(true);
                ProductAdminStore.updateExtra(item.uid, payload)
                    .then(() => {
                        message.success('Actualizado');
                        onUpdated();
                    })
                    .finally(() => setLoading(false));
            }}
            layout={'horizontal'}
        >
            <Form.Item label="Título" name={'name'} rules={[{ required: true, message: 'Requerido' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="pricing" label="Calculo de Precio" rules={[{ required: true }]}>
                <Radio.Group
                    onChange={e => {
                        setPricing(e.target.value);
                    }}
                >
                    <Radio style={radioStyle} value={'add'}>
                        Sumar los valores al precio Base
                    </Radio>
                    <Radio style={radioStyle} value={'replace'}>
                        Reemplaza al precio Base
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label={'Obligatorio'} name={'required'} valuePropName={'checked'}>
                <Checkbox />
            </Form.Item>

            {pricing === 'add' && (
                <Form.Item label={'Multiple'} name={'multiple'} valuePropName={'checked'}>
                    <Checkbox />
                </Form.Item>
            )}
            {pricing === 'add' && (
                <Form.Item name="min" label="Minímo Requerido" rules={[{ required: false }]}>
                    <InputNumber />
                </Form.Item>
            )}
            {pricing === 'add' && (
                <Form.Item name="max" label="Máximo Permitido" rules={[{ required: false }]}>
                    <InputNumber />
                </Form.Item>
            )}

            {!item.product && (
                <Form.Item
                    name="categories"
                    label="Categorías"
                    rules={[{ required: true }]}
                    help={'Seleccione las categorías a las que estará asociada esta variación'}
                >
                    <CategoriesPicker mode={'multiple'} categories={categories} />
                </Form.Item>
            )}
            <Form.Item>
                <Button type={'primary'} loading={loading} htmlType={'submit'}>
                    Guardar Cambios
                </Button>
            </Form.Item>
        </Form>
    );
});
