import React from 'react';
import { Button, Form } from 'antd';
import { ImagePicker } from '../../upload/image_picker';
import { useForm } from 'antd/es/form/Form';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

interface Props {
    isLoading?: boolean;
    handleSave: (values: any) => void;
}

const GalleryForm = ({ isLoading, handleSave }: Props) => {
    const [form] = useForm();
    return (
        <React.Fragment>
            <Form
                form={form}
                {...layout}
                onFinish={values => {
                    handleSave(values);
                    form.resetFields();
                }}
            >
                <Form.Item name="thumbnail" rules={[{ required: true }]} help={'Agregar más imágenes'}>
                    <ImagePicker />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};

export default GalleryForm;
