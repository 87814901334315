import React from 'react';

interface Props {
    fullName: string;
    onClick?: () => void;
}

const UserCard = (props: Props) => {
    return (
        <div
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
            className="font-bold text-xl"
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: '10px',
                paddingTop: '10px',
            }}
        >
            {props.fullName}
        </div>
    );
};

export default UserCard;
