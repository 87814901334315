import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Statistic, Table, Radio, Form, Button, FormInstance, Modal, Input } from 'antd';
import dayjs from 'dayjs';

import { FieldNumberOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { UserAdminStore, UserReportProps } from '../../../stores/Admin/UserAdminStore';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import DateRangePicker from '../../../components/DateRangePicker';
import { OrderProps } from '../../../types/types.ds';
import { OrderStore } from '../../../stores/OrderStore';
import { useHistory } from 'react-router-dom';
import { UserStore } from '../../../stores/UserStore';
import { StoresPicker } from '../../../components/dropdowns';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const columns: ColumnsType<OrderProps> = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Fecha',
        dataIndex: 'created_time',
        key: 'created_time',
        render: (t: string, r) => {
            return (
                <div>
                    <div>{dayjs(r.created_time).format('DD/MM/YYYY')}</div>
                    <div>{dayjs(r.created_time).fromNow()}</div>
                </div>
            );
        },
    },
    {
        title: 'Restaurante',
        dataIndex: 'owner_related.company',
        key: 'owner_related.company',
        render: (t, record) => {
            return record.owner_related.company;
        },
    },

    {
        title: 'Cliente',
        dataIndex: 'created_by.company',
        key: 'created_by.company',
        render: (t, record) => {
            return `${record.created_by.first_name} ${record.created_by.last_name}`;
        },
    },
    {
        title: 'Total',
        dataIndex: 'grand_total',
        key: 'grand_total',
        render: (t, record) => {
            return record.grand_total.toFixed(2);
        },
    },
    {
        title: 'PickUp',
        dataIndex: 'created_by.company',
        key: 'created_by.company',
        render: (t, record) => {
            return dayjs(record.pickup_time).format('hh:mm');
        },
    },

    {
        title: 'Estimated Delivery Time',
        dataIndex: 'created_by.company',
        key: 'created_by.company',
        render: (t, record) => {
            return (
                <div>
                    <div> {dayjs(record.estimated_arrival_time).format('hh:mm')}</div>
                    <div>{dayjs(record.estimated_arrival_time).fromNow()}</div>
                </div>
            );
        },
    },
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        render: (t, record) => {
            return (
                <div>
                    <div> {record.source}</div>
                </div>
            );
        },
    },
    {
        title: 'Estado',
        dataIndex: 'status_display',
        key: 'status_display',
        render: (t, record) => {
            return (
                <div
                    style={{
                        color: !record.completed ? 'green' : 'inherit',
                        fontWeight: 'bold',
                    }}
                >
                    {record.status_display}
                </div>
            );
        },
    },
];

const ReportOrders = () => {
    const formRef = React.createRef<FormInstance>();

    const history = useHistory();

    const handleGetData = (values: any) => {
        let params: any = {
            search: values.search,
        };

        if (values.created_time) {
            params = {
                ...params,
                created_time_min: values.created_time[0].format('YYYY-MM-DD'),
                created_time_max: values.created_time[1].format('YYYY-MM-DD'),
            };
        }

        if (values.owner) {
            params = {
                ...params,
                // owner: values.owner.key,
            };
        }

        OrderStore.getOrders(params);
    };

    return (
        <div className={'flex flex-col p-4'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reportes de Clientes</title>
            </Helmet>
            <div className={'w-full pt-4 flex flex-row'}>
                <Form layout="inline" onFinish={handleGetData} autoComplete="off" ref={formRef}>
                    {/*<Form.Item label="Fecha de Registro" name="owner">*/}
                    {/*    <StoresPicker />*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="Buscar" name="search" rules={[{ required: false }]}>
                        <Input.Search />
                    </Form.Item>

                    <Form.Item label="Fecha de Registro" name="created_time" rules={[{ required: false }]}>
                        <DateRangePicker />
                    </Form.Item>

                    <Button htmlType="button" onClick={() => formRef.current?.resetFields()}>
                        Reset
                    </Button>
                    <Button htmlType="submit" type={'primary'} loading={UserAdminStore.loading}>
                        Filter
                    </Button>
                </Form>
            </div>

            <div className={'pt-4'}>
                <Table<OrderProps>
                    pagination={false}
                    columns={columns}
                    rowClassName={'cursor-pointer'}
                    onRow={record => {
                        return {
                            onClick: () => {
                                OrderStore.setSelected(record);
                                history.push(`/orders/${record.id}/`);
                            },
                        };
                    }}
                    loading={OrderStore.loading}
                    dataSource={OrderStore.orders}
                    onChange={(pagination, filter, sorter) => {
                        //@ts-ignore
                        setSorter(sorter);
                    }}
                />
            </div>

            {/*<div className={'p-4 text-center'}>*/}
            {/*    <Row gutter={16}>*/}
            {/*        <Col span={24}>*/}
            {/*            <Statistic title="# Mostrados" value={OrderStore.} prefix={<FieldNumberOutlined />} />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</div>*/}
        </div>
    );
};

export default observer(ReportOrders);
