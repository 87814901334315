import { CartStore } from 'stores/CartStore';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { CartItemCard } from '../../components/cards';

interface Props {
    onPressOrderNow?: () => void;
}

const CartView = (props: Props) => {
    const line_items = CartStore.line_items;

    const CartContent = () => {
        return (
            <div>
                <ul
                    className="clearfix bg-grey"
                    style={{
                        height: '55vh',
                        overflow: 'auto',
                    }}
                >
                    {line_items.length === 0 && (
                        <div className="font-light text-center text-sm">
                            Tu carrito está vacío, agrega items para empezar una orden
                        </div>
                    )}
                    {line_items.map(cart_item => {
                        return (
                            <CartItemCard
                                key={cart_item.key}
                                cart_item={cart_item}
                                onRemove={() => {
                                    CartStore.removeToCart(cart_item.key);
                                }}
                            />
                        );
                    })}
                </ul>

                <div className="btn_1_mobile">
                    <a
                        onClick={props.onPressOrderNow}
                        className="btn_1 gradient full-width mb_5"
                        style={{
                            fontSize: 17,
                            padding: 10,
                            borderRadius: 24,
                        }}
                    >
                        Ordenar <span>${CartStore.subtotal.toFixed(2)} </span>
                    </a>
                    <div className="text-center"></div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="box_order mobile_fixed">
                <div className="head">
                    <h3>Su Orden</h3>
                    <a className="close_panel_mobile">
                        <i className="icon_close" />
                    </a>
                </div>
                <div className="main">
                    <CartContent />
                </div>
            </div>
            {CartStore.total > 0 && (
                <div
                    className="btn_reserve_fixed"
                    style={{
                        zIndex: 1,
                    }}
                >
                    {/*<Modal title="Su Orden" visible={show_modal} footer={[]} onCancel={() => setShowModal(false)}>*/}
                    {/*    <CartContent />*/}
                    {/*</Modal>*/}

                    <a
                        className="btn_1 gradient full-width"
                        onClick={props.onPressOrderNow}
                        style={{
                            fontSize: 17,
                            padding: 10,
                            borderRadius: 24,
                        }}
                    >
                        ({CartStore.number_cart_items} Items) ${CartStore.total.toFixed(2)} Continuar
                    </a>
                </div>
            )}
        </React.Fragment>
    );
};

export default observer(CartView);
