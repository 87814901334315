import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { UserProps } from '../../types/types.ds';
import { Avatar } from 'antd';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { Button } from 'antd';
import { MessageOutlined } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
};

interface ContactCardProps {
    user: UserProps;
    handleClose: () => void;
    onClickChat: () => void;
}

export default function ContactCard(props: ContactCardProps) {
    return (
        <div>
            <Modal open={true} onClose={props.handleClose} closeAfterTransition>
                <Box sx={style}>
                    <div className="flex flex-row">
                        <div className={'flex flex-row items-center justify-center'}>
                            <Avatar size="large" src={props.user.thumbnail ?? props.user.facebook_avatar ?? ''} />
                        </div>
                        <div className="ml-2">
                            <div>
                                <div className="font-bold text-lg">
                                    {props.user.company ?? `${props.user.first_name} ${props.user.last_name}`}
                                </div>
                                <div>{props.user.phone}</div>
                            </div>
                            <div className={'flex flex-row p-2'}>
                                <div
                                    className="ml-2 cursor-pointer"
                                    onClick={() => {
                                        const phone = props.user.phone;
                                        window.open(`tel:${phone}`);
                                    }}
                                >
                                    <CallIcon
                                        style={{
                                            fontSize: 35,
                                        }}
                                    />
                                </div>
                                <div
                                    className="ml-4 cursor-pointer"
                                    onClick={() => {
                                        props.onClickChat();
                                    }}
                                >
                                    <MessageOutlined
                                        style={{
                                            fontSize: 35,
                                        }}
                                    />
                                </div>

                                <div
                                    className="ml-4 cursor-pointer"
                                    onClick={() => {
                                        const phone = props.user.phone;

                                        if (phone.includes('+')) {
                                            window.open(`https://wa.me/${phone}`);
                                        } else {
                                            const whatsapp = '593' + phone?.substring(1, phone?.length);

                                            window.open(`https://wa.me/${whatsapp}`);
                                        }
                                    }}
                                >
                                    <WhatsAppIcon
                                        style={{
                                            fontSize: 35,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'text-center mt-4'}>
                        <Button type="link" onClick={props.handleClose}>
                            Cerrar
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
