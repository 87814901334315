import { useEffect } from 'react';
import useUserSocketConnection from '../services/useUserSocketConnection';
import { OrderStore } from '../stores/OrderStore';
import { observer } from 'mobx-react';
import { NotificationStore } from '../stores/NotificationStore';
import { ChatStore } from '../stores/ChatStore';

const LoadServices = (props: { user_uid: string; token: string }) => {
    const { new_notification } = useUserSocketConnection(props.user_uid, props.token);

    // const playSound = () => {
    //     const path = require('../assets/sounds/desk_bell.mp3').default;
    //     const audio = new Audio(path);
    //     audio.play();
    // };

    useEffect(() => {
        if (new_notification) {
            if (new_notification?.message?.type == 'ORDER-CREATED') {
                if (window.Notification && Notification.permission === 'granted') {
                    const options = {
                        body: JSON.stringify(new_notification?.message?.payload),
                        silent: false,
                        vibrate: [200, 100, 200],
                    };
                    new Notification('Nueva Order', options);
                    // playSound();
                }
            }

            if (
                new_notification?.message?.type == 'ORDER-CREATED' ||
                new_notification?.message?.type == 'ORDER-UPDATED'
            ) {
                OrderStore.setOrder(new_notification?.message.payload);
            }
            NotificationStore.getNotifications();
            ChatStore.getRooms();
        }
    }, [new_notification]);

    return null;
};

export default observer(LoadServices);
