import { action, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../utils/request';
import { CitiesProps, IDeliveryZones, OwnerProps, SponsorsProps, UserProps } from '../types/types.ds';
import axios, { CancelTokenSource } from 'axios';

class City {
    @observable loading = true;
    @observable cities: Array<CitiesProps> = [];
    @observable deliveryZones: Array<IDeliveryZones> = [];
    @observable city?: CitiesProps;

    @action async getCity(uid = {}) {
        const r = await get(`/cities/${uid}/`);
        this.city = r.data;
    }

    @action async setCity(city: CitiesProps) {
        this.city = city;
    }

    @action async getDeliveryZonesByCity(city_uid: string) {
        this.deliveryZones = [];
        const r = await get(`/delivery-zones/?city=${city_uid}`);
        this.deliveryZones = r.data.results;
    }

    @action addDeliveryZone(zone: IDeliveryZones) {
        this.deliveryZones.push(zone);
    }

    @action async getCities() {
        this.deliveryZones = [];
        const r = await get(`/cities/`);
        this.cities = r.data.results;
    }

    @action async createDeliveryZone(payload: any) {
        await post(`/delivery-zones/`, payload);
        if (this.city?.uid) {
            await this.getDeliveryZonesByCity(this.city.uid);
        }
    }

    @action async updateDeliveryZone(zone_uid: string, payload: any) {
        await patch(`/delivery-zones/${zone_uid}/`, payload);
        if (this.city?.uid) {
            await this.getDeliveryZonesByCity(this.city.uid);
        } else {
            alert('no city');
        }
    }

    @action async deleteDeliveryZone(zone_uid: string) {
        await remove(`/delivery-zones/${zone_uid}/`);
        if (this.city?.uid) {
            await this.getDeliveryZonesByCity(this.city.uid);
        }
    }
}

export const CityStore = new City();
