import { createContext } from 'react';
var DEFAULT_CONTEXT = {
    selected: '',
    refs: {},
    meta: {},
    scrollTo: function () { },
    registerRef: function () { return null; },
};
export var ScrollContext = createContext(DEFAULT_CONTEXT);
export var Consumer = ScrollContext.Consumer, Provider = ScrollContext.Provider;
