import React from 'react';
import ANTTextArea from 'antd/lib/input/TextArea';

interface TextAreaProps {
    placeHolder: string;
    value: string;
    onChange: (value: string) => void;
}

const TextArea = ({ placeHolder, value, onChange }: TextAreaProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'rgb(25, 25, 25)',
                outline: 'none',
                boxShadow: 'rgb(86 86 86 / 77%) 0px 0px 0px 0.2px',
                borderRadius: '5px',
            }}
        >
            <div
                style={{
                    width: '100%',
                    margin: '0px auto',
                    padding: '10px',
                }}
            >
                <ANTTextArea
                    style={{
                        fontSize: '16px',
                        width: '100%',
                        border: 'none',
                        flex: '1 1 100%',
                        padding: '0px',
                        height: '47px',
                        background: 'inherit',
                    }}
                    placeholder={placeHolder}
                    value={value}
                    onChange={e => {
                        onChange(e.target.value);
                    }}
                />
            </div>
        </div>
    );
};

export default TextArea;
