import React from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { IDeliveryZones } from '../../types/types.ds';

interface Props {
    zone: IDeliveryZones;
    handleSave: (values: any) => Promise<void>;
}

const FormDeliveryZone = (props: Props) => {
    const [form] = Form.useForm();
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const onFinish = (values: any) => {
        setIsSaving(true);
        props.handleSave(values).finally(() => setIsSaving(false));
    };

    return (
        <Form
            form={form}
            initialValues={{
                name: props.zone.name,
                cost: props.zone.cost,
            }}
            onFinish={onFinish}
        >
            <Form.Item label={'Name'} name={'name'} required={true}>
                <Input />
            </Form.Item>
            <Form.Item label={'Cost'} name={'cost'} required={true}>
                <InputNumber />
            </Form.Item>

            <Form.Item>
                <div className={'flex flex-col'}>
                    <Button type={'primary'} onClick={form.submit} loading={isSaving}>
                        Save Changes
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default observer(FormDeliveryZone);
