import React from 'react';
import { Button, Form, Input, InputNumber, Spin, Switch, Upload } from 'antd';
import { ImagePicker } from '../../upload/image_picker';
import { useHistory } from 'react-router-dom';
import { CategoryProps, ProductProps } from '../../../types/types.ds';
import { CategoriesPicker } from '../../dropdowns';
import { ProductAdminStore } from '../../../stores/Admin/ProductAdminStore';
import { PlusOutlined } from '@ant-design/icons';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

interface Props {
    product: ProductProps;
    categories: CategoryProps[];
    handleSave: (values: any) => Promise<void>;
}

const ProductEditForm = ({ product, categories, handleSave }: Props) => {
    const history = useHistory();

    const [isSaving, setIsSaving] = React.useState(false);

    return (
        <React.Fragment>
            <Form
                {...layout}
                initialValues={{
                    name: product.name,
                    price: product.price,
                    local_price: product.local_price,
                    original_price: product.original_price,
                    description: product.description,
                    published: product.published,
                    in_stock: product.in_stock,
                    is_promotion: product.is_promotion,
                    order: product.order || 0,
                    delivery_time: product.delivery_time,
                    delivery: product.delivery,
                    in_store: product.in_store,
                    category: {
                        key: product.category_related?.uid,
                        label: product.category_related?.name,
                    },
                }}
                onFinish={values => {
                    setIsSaving(true);
                    const payload = {
                        ...values,
                        image: values.image ? values.image.file : undefined,
                        category: values.category.key,
                    };
                    console.log(payload);

                    handleSave(payload).finally(() => {
                        setIsSaving(false);
                    });
                }}
            >
                <div className={'flex flex-row'}>
                    <div className={'mx-2'}>
                        {product.thumbnail && (
                            <img
                                alt={product.name}
                                src={product?.thumbnail ?? ''}
                                style={{
                                    width: 100,
                                    height: 'auto',
                                }}
                            />
                        )}
                    </div>
                    <Form.Item
                        label={'Imagen'}
                        name={'image'}
                        help={
                            'Usa una imagen de 800x800px o superior para que se vea bien en la web, esto es opcional pero una buena imagen puede ayudar a vender más'
                        }
                    >
                        <Upload multiple={false} maxCount={1} listType="picture-card" beforeUpload={() => false}>
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Cambiar Imagen</div>
                            </div>
                        </Upload>
                    </Form.Item>
                </div>

                <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <div className={'flex flex-row'}>
                    <div className={'border p-2'} style={{ minHeight: 200 }}>
                        <Form.Item
                            name="delivery"
                            label="Disponible para Delivery?"
                            rules={[{ required: true }]}
                            valuePropName={'checked'}
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            name="original_price"
                            label="Precio (Para Llevar)"
                            help={'Usa el punto como separador de decimales..'}
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder={'0.00'} />
                        </Form.Item>
                    </div>

                    <div className={'border p-2'} style={{ minHeight: 200 }}>
                        <Form.Item
                            name="in_store"
                            label="Disponible en el Local?"
                            rules={[{ required: false }]}
                            valuePropName={'checked'}
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            name="local_price"
                            label="Precio (Consumo Local)"
                            rules={[{ required: false }]}
                            help={
                                'Muy pronto los locales podrán tener acceso un nuevo terminal de punto de venta, y gestionar sus pedidos locales más rápidamente!'
                            }
                        >
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item name="description" label="Descripción" rules={[{ required: true }]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    name="category"
                    help={'Selecciona una opción'}
                    label="Categoría"
                    rules={[{ required: true }]}
                >
                    <CategoriesPicker categories={categories} />
                </Form.Item>

                <Form.Item
                    name="is_promotion"
                    label="Es una Promoción?"
                    rules={[{ required: false }]}
                    valuePropName={'checked'}
                    help={
                        'Al marcar esta opción este producto será listado en la sección promociones. La oferta tiene que ser sumamente atrativa para el cliente y tiene que ser mostrada en un tiempo limitado usando la sección *Disponiblidad*'
                    }
                >
                    <Switch />
                </Form.Item>

                <Form.Item name="delivery_time" label="Tiempo de Entrega (Minutos)" rules={[{ required: true }]}>
                    <InputNumber />
                </Form.Item>

                <Form.Item name="in_stock" label="En Stock" rules={[{ required: false }]} valuePropName={'checked'}>
                    <Switch />
                </Form.Item>

                <Form.Item
                    name="order"
                    label="Órden Mostrado"
                    rules={[{ required: false }]}
                    help={'Dejar en 0 para mostrar el más vendido primero'}
                >
                    <InputNumber precision={0} placeholder={''} />
                </Form.Item>

                <Form.Item name="published" label="Publicado" rules={[{ required: true }]} valuePropName={'checked'}>
                    <Switch />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="default"
                        style={{ backgroundColor: 'red', color: 'white' }}
                        onClick={() => {
                            ProductAdminStore.deleteProduct(product.uid);
                            history.push('/Admin/products/');
                        }}
                        loading={isSaving}
                    >
                        &nbsp; Eliminar
                    </Button>
                    &nbsp;
                    <Button type="primary" htmlType="submit" className="s-btn btn-link " loading={isSaving}>
                        <i className="fas fa-search" /> &nbsp; Guardar
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};

export default ProductEditForm;
