import { useEffect, useRef, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { BASE_URL_WS } from '../utils/settings';
import { AuthenticationStore } from '../stores/AuthenticationStore';

const options = {
    maxRetries: 10,
};

const useChat = (room_id: string) => {
    const socketRef = useRef<ReconnectingWebSocket>();
    const [new_notification, setNewNotification] = useState<any>();

    useEffect(() => {
        // Creates a WebSocket connection
        socketRef.current = new ReconnectingWebSocket(
            `${BASE_URL_WS}/ws/chat/?token=${AuthenticationStore.token}`,
            [],
            options
        );

        socketRef.current?.addEventListener('open', () => {
            const payload: any = {
                command: 'join',
                room: room_id,
            };
            socketRef.current?.send(JSON.stringify(payload));
        });

        socketRef.current.addEventListener('message', message => {
            setNewNotification(JSON.parse(message.data));
        });

        // Destroys the socket reference
        // when the connection is closed
        return () => {
            // const payload: any = {
            //     command: "leave",
            //     room: room_id
            // }
            // socketRef.current?.send(JSON.stringify(payload))
            socketRef.current?.close();
        };
    }, [room_id]);

    // Sends a message to the server that
    // forwards it to all users in the same room
    const sendMessage = (message: string) => {
        socketRef.current?.send(
            JSON.stringify({
                command: 'send',
                room: room_id,
                message: message,
            })
        );
    };

    return { new_notification, sendMessage };
};

export default useChat;
