import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, DatePicker, Row, Statistic, Table } from 'antd';

import { DollarOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { IDriverCommissions, OrdersAdminStore } from '../../../../stores/Admin/OrderStore';
import { StoresPicker } from '../../../../components/dropdowns';
import { UserStore } from '../../../../stores/UserStore';
import { OrderReportProps } from '../../../../types/types.ds';
import { Helmet } from 'react-helmet';
import { ColumnsType } from 'antd/es/table';
import { UserReportProps } from '../../../../stores/Admin/UserAdminStore';
import DateRangePicker from '../../../../components/DateRangePicker';

const ReportCommissionDrivers = () => {
    const [owner, setOwner] = useState<{ value: string; label: string }>();
    const [date, setDate] = useState<any>();

    useEffect(() => {
        if (!date) {
            return;
        }
        const params: any = {
            created_time_min: date[0].format('YYYY-MM-DD'),
            created_time_max: date[1].format('YYYY-MM-DD'),
        };

        if (owner?.value) {
            params['owner'] = owner.value;
        }

        OrdersAdminStore.getReportRushers(params);
    }, [date, owner]);

    const columns: ColumnsType<IDriverCommissions> = [
        {
            title: 'First Name',
            dataIndex: 'delivery_driver_first_name',
            key: 'delivery_driver_first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'delivery_driver_last_name',
            key: 'delivery_driver_last_name',
        },

        {
            title: '# orders',
            dataIndex: 'orders_count',
            key: 'orders_count',
        },
        {
            title: 'Shipping Total',
            dataIndex: 'shipping_total',
            key: 'shipping_total',
        },
        {
            title: '# Por definir',
            dataIndex: 'shipping_count_unknown',
            key: 'shipping_count_unknown',
        },
        {
            title: '10%',
            dataIndex: 'commission_10_percent',
            key: 'commission_10_percent',
            render: (t, r) => {
                return <div>{r.commission_10_percent.toFixed(2)}</div>;
            },
        },
        {
            title: '15%',
            dataIndex: 'commission_15_percent',
            key: 'commission_15_percent',
            render: (t, r) => {
                return <div>{r.commission_15_percent.toFixed(2)}</div>;
            },
        },
        {
            title: '20%',
            dataIndex: 'commission_20_percent',
            key: 'commission_20_percent',
            render: (t, r) => {
                return <div>{r.commission_20_percent.toFixed(2)}</div>;
            },
        },
    ];

    const total_10_percentage = OrdersAdminStore.commissionsByRushers.reduce(
        (t, order) => t + order.commission_10_percent,
        0
    );
    const total_15_percentage = OrdersAdminStore.commissionsByRushers.reduce(
        (t, order) => t + order.commission_15_percent,
        0
    );
    const total_20_percentage = OrdersAdminStore.commissionsByRushers.reduce(
        (t, order) => t + order.commission_20_percent,
        0
    );

    return (
        <div className={'flex flex-col p-4'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reportes</title>
            </Helmet>
            <div className={'w-full pt-4 flex flex-row items-center justify-center'}>
                <div className={'ml-2'}>
                    <label>Fecha:</label> <DateRangePicker onChange={date => setDate(date)} />
                </div>
            </div>

            <div className={'pt-4'}>
                <Table<IDriverCommissions>
                    pagination={false}
                    columns={columns}
                    loading={OrdersAdminStore.loading}
                    dataSource={OrdersAdminStore.commissionsByRushers}
                />
            </div>

            <div className={'p-4 text-center'}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic
                            title="# Total 10%"
                            value={total_10_percentage.toFixed(2)}
                            prefix={<FieldNumberOutlined />}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Total 15%"
                            value={total_15_percentage.toFixed(2)}
                            prefix={<DollarOutlined />}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Total 20%"
                            value={total_20_percentage.toFixed(2)}
                            prefix={<DollarOutlined />}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default observer(ReportCommissionDrivers);
