import React from 'react';
import { HomeOutlined, LogoutOutlined, OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';
import { Menu as AntMenu } from 'antd';
import { MenuMode } from 'antd/lib/menu';
import { useHistory } from 'react-router-dom';
import StoreIcon from '@mui/icons-material/Store';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import CalculateIcon from '@mui/icons-material/Calculate';
import DownloadAndroidStore from '../../components/buttons/DownloadAndroidStore';
import DownloadAppStore from '../../components/buttons/DownloadAppStore';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { OrderStore } from '../../stores/OrderStore';

interface MenuProps {
    isAuthenticated: boolean;
    isAPartner: boolean;
    isStaff: boolean;
    type: 'horizontal' | 'vertical';
}

const Menu = (props: MenuProps) => {
    const history = useHistory();

    const stylesMain = props.type === 'vertical' ? 'flex flex-col p-4' : 'flex flex-row items-center justify-center ';

    const StyleMenuItem =
        props.type === 'vertical'
            ? 'flex flex-row text-xl cursor-pointer mt-3'
            : 'flex flex-row text-md cursor-pointer mx-2';
    return (
        <div className={stylesMain}>
            <div className={StyleMenuItem} onClick={() => history.push('/')}>
                <StoreIcon
                    style={{
                        fontSize: 30,
                    }}
                />
                <div className="ml-2">Tiendas</div>
            </div>

            <div
                className={StyleMenuItem}
                onClick={() => {
                    OrderStore.getOrders();
                    history.push('/orders/');
                }}
            >
                <DeliveryDiningIcon
                    style={{
                        fontSize: 30,
                    }}
                />
                <div className="ml-2">Pedidos</div>
            </div>

            {props.isAPartner && (
                <>
                    <div className={StyleMenuItem} onClick={() => history.push('/Admin/products/')}>
                        <FactCheckOutlinedIcon
                            style={{
                                fontSize: 30,
                            }}
                        />
                        <div className="ml-2">Productos</div>
                    </div>

                    <div className={StyleMenuItem} onClick={() => history.push('/admin/open-hours/')}>
                        <ScheduleIcon
                            style={{
                                fontSize: 30,
                            }}
                        />

                        <div className="ml-2">Horario</div>
                    </div>

                    <div className={StyleMenuItem} onClick={() => history.push('/admin/reports/')}>
                        <CalculateIcon
                            style={{
                                fontSize: 30,
                            }}
                        />
                        <div className="ml-2">Reports</div>
                    </div>
                </>
            )}

            {props.isStaff && (
                <div className={StyleMenuItem} onClick={() => history.push('/Admin/delivery-zones/')}>
                    <DeliveryDiningIcon
                        style={{
                            fontSize: 30,
                        }}
                    />
                    <div className="ml-2">Zonas</div>
                </div>
            )}

            {props.type === 'vertical' && (
                <div className="absolute bottom-0">
                    <div className="flex flex-col items-center justify-center  m-auto max-w-md">
                        <div className="font-bold text-xl">Descarga la App Movil!</div>
                        <div className="font-thin px-2 mt-2 text-center">
                            Con la app todo es más fácil, ¡descarga la app a tu celular!{' '}
                        </div>

                        <div className="flex flex-row justify-center max-w-xs">
                            <DownloadAndroidStore />
                            <DownloadAppStore />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Menu;
