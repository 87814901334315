import React from 'react';
//@ts-ignore
import Lottie from 'react-lottie';

export const Loading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/animations/9644-delivery-riding.json'),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Lottie options={defaultOptions} height={400} width={400} />
        </div>
    );
};
