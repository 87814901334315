import { action, observable } from 'mobx';
import { get, patch, post } from '../../utils/request';
import { CategoryProps, ExtraListProps } from '../../types/types.ds';

class Extra {
    @observable loading = false;
    @observable extras: ExtraListProps[] = [];
    @observable extra: ExtraListProps | null = null;
    @observable total_records = 0;

    @action getAll(params = {}) {
        get('/extras/', params)
            .then(response => {
                this.extras = response.data.results;
            })
            .catch(err => console.log(err));
    }

    @action async getById(uid: string | null) {
        this.loading = true;
        await get(`/extras/${uid}/`)
            .then(response => {
                this.extra = response.data;
            })
            .catch(err => console.log(err));
        this.loading = false;
    }

    @action create(params = {}) {
        post('/extras/', params)
            .then(response => {
                this.extras = [...this.extras, response.data];
            })
            .catch(err => console.log(err));
    }

    @action
    async update(uid: string, payload: any) {
        this.loading = true;
        await patch(`/extras/`, payload)
            .then(response => {})
            .catch(err => console.log(err));
        this.loading = false;
    }
}

export const ExtraAdminStore = new Extra();
