import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Card, Modal, Popover, Steps } from 'antd';
import { OrderProps, UserProps } from '../../types/types.ds';
import { ExtraListCard } from './index';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { UserStore } from 'stores/UserStore';
import Chip from '@mui/material/Chip';
import ConfirmPicker from '../../containers/orders/ConfirmationPicker';
import { OrderStore } from '../../stores/OrderStore';
import { observer } from 'mobx-react';

const { Step } = Steps;

interface Props {
    order: OrderProps;
    handleShowContactDetails: (user: UserProps, order: OrderProps) => void;
}

const OrderCard = ({ order, ...props }: Props) => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    let currentPosition = 0;

    if (order.status === 'in-progress') {
        currentPosition = 1;
    } else if (order.status === 'ready-for-pickup') {
        currentPosition = 1;
    } else if (order.status === 'on-the-way') {
        currentPosition = 2;
    } else if (order.status === 'arrived') {
        currentPosition = 3;
    } else if (order.status === 'delivered') {
        currentPosition = 4;
    }

    return (
        <Card
            bordered
            style={{
                minWidth: 350,
                borderRadius: 15,
            }}
        >
            <div className="name-dt">
                <div
                    className={'order-top'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 15,
                    }}
                >
                    <div>
                        <div
                            className={'order-number'}
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            Orden #{order.id}
                        </div>
                        <div
                            style={{
                                marginTop: 5,
                            }}
                        >
                            {dayjs(order.created_time).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                    </div>
                    <div>
                        <div>${order.grand_total.toFixed(2)}</div>
                    </div>
                </div>

                <div
                    className={'company'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 15,
                    }}
                >
                    <div>
                        <div
                            className="flex flex-row cursor-pointer"
                            onClick={() => {
                                if (order.owner_related) {
                                    //@ts-ignore
                                    props.handleShowContactDetails(order.owner_related, order);
                                }
                            }}
                        >
                            <div className="font-bold">{order.owner_related.company}</div>
                            <div className="ml-1">{UserStore.user?.phone && <WhatsAppIcon />}</div>
                        </div>
                    </div>
                    <div>
                        {order.completed ? (
                            order.status_display
                        ) : (
                            <Chip
                                onClick={() => {
                                    if (order.status === 'pending' && UserStore.user?.is_seller) {
                                        setShowConfirmModal(true);
                                    }
                                }}
                                label={order.status}
                                color={order.status == 'pending' ? 'success' : 'default'}
                            />
                        )}
                    </div>
                </div>

                <div
                    className={'order-items'}
                    style={{
                        marginBottom: 15,
                    }}
                >
                    {order.order_items.map(item => {
                        return (
                            <>
                                <div
                                    className="mt-2"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ fontWeight: 'bold' }}>{item.quantity}</div>
                                        &nbsp; X
                                        <div style={{ fontWeight: 'bold', marginLeft: 5 }}>{item.product_name}</div>
                                    </div>
                                    <div>{item.total_price}</div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <ExtraListCard extras={item.extras} />
                                </div>

                                {item.special_instructions && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>Instrucciones Especiales:</div>
                                        <div>{item.special_instructions}</div>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className={'font-bold'}>Notas:</div>
                    <div>{order.notes ?? '---'}</div>
                </div>

                <div
                    className={'mt-4'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className={'font-bold'}>Subtotal:</div>
                    <div className={'font-normal'}>{order.subtotal.toFixed(2)}</div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className={'font-bold'}>Envío:</div>
                    <div className={'font-normal'}>{order.shipping_cost ? order.shipping_cost : 'Por definir'}</div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className={'font-bold'}>Total:</div>
                    <div className={'font-normal'}>{order.grand_total.toFixed(2)}</div>
                </div>

                {UserStore.user?.is_seller && (
                    <div
                        className={'mt-3'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>Hora Recogida:</div>
                        <div className={'font-bold text-lg'}>
                            {order?.pickup_time ? dayjs(order.pickup_time).format('HH:mm') : '---'}
                        </div>
                    </div>
                )}

                <div
                    className={'mt-3'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>Hora Aprox. Entrega:</div>
                    <div className={'font-normal'}>
                        {order?.estimated_arrival_time
                            ? dayjs(order.estimated_arrival_time).format('HH:mm')
                            : 'Por Confirmar'}
                    </div>
                </div>

                <div
                    className={'mt-3 cursor-pointer'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                    onClick={() => {
                        if (order.delivery_driver_related) {
                            props.handleShowContactDetails(order.delivery_driver_related, order);
                        }
                    }}
                >
                    <div>Conductor:</div>

                    <div className={'font-normal flex flex-row'}>
                        <div>{order.delivery_driver_related?.first_name}</div>
                        <div className="ml-1">
                            <WhatsAppIcon />
                        </div>
                    </div>
                </div>

                {!order.completed && (
                    <div className="p-4">
                        <Steps current={currentPosition}>
                            <Step title="Pendiente" />
                            <Step title="En Proceso" />
                            <Step title="En Camino" />
                            <Step title="En la Puerta" />
                            <Step title="Entregado" />
                        </Steps>
                    </div>
                )}

                {showConfirmModal && (
                    <Modal
                        title={<div className={'text-center font-bold'}>Tiempo de Preparacion en Minutos</div>}
                        visible
                        footer={[]}
                        onCancel={() => setShowConfirmModal(false)}
                    >
                        <ConfirmPicker
                            order_uid={order.uid}
                            onClose={() => {
                                setShowConfirmModal(false);
                            }}
                        />
                    </Modal>
                )}
            </div>
        </Card>
    );
};

export default observer(OrderCard);
