import ImgCrop from 'antd-img-crop';
import { Button, Radio, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
    value?: any;
    onChange?: (values: any) => void;
    aspect?: any;
}

export const ImagePicker = ({ value, onChange, aspect = null }: Props) => {
    const [preview, setPreview] = useState<File>();
    const [_aspect, setAspect] = useState(1.77);

    function getBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    useEffect(() => {
        if (value) {
            getBase64(value).then(file => {
                //@ts-ignore
                setPreview(file);
            });
        }
    }, [value]);

    const onPreviewImage = async (file: any) => {
        let src = file.url;

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        //@ts-ignore
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <ImgCrop
            modalWidth={800}
            restrictPosition={false}
            aspect={_aspect}
            //@ts-ignore
            modalTitle={
                <div>
                    <Radio.Group
                        onChange={e => {
                            setAspect(e.target.value);
                        }}
                        value={_aspect}
                    >
                        <Radio value={1}>Click para imágenes Cuadradas</Radio>
                        <Radio value={1.77}>Click para imágenes Reactangulares</Radio>
                    </Radio.Group>
                </div>
            }
        >
            <Upload
                showUploadList={false}
                accept={'png, jpeg, jpg'}
                beforeUpload={blob => {
                    if (onChange) {
                        const file = new File([blob], blob.name, {
                            type: blob.type,
                        });
                        onChange(file);
                    }
                    return false;
                }}
                onPreview={onPreviewImage}
            >
                <div
                    className="flex-direction-column"
                    style={{
                        display: 'flex',
                        maxWidth: 113,
                        flexDirection: 'column',
                    }}
                >
                    {preview && (
                        <img
                            alt="preview"
                            style={{ width: '100%' }}
                            //@ts-ignore

                            src={preview}
                        />
                    )}
                    <Button className={'my-2'} size={'small'} icon={<PlusOutlined />}>
                        Seleccionar
                    </Button>
                </div>
            </Upload>
        </ImgCrop>
    );
};
