import * as React from 'react';
import { useState, useEffect } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { observer } from 'mobx-react';
import { CompanyStore } from '../../stores/Admin/CompanyAdminStores';

interface Props extends SelectProps {
    OnSelect?: (value: any) => void;
    onChange?: (value: any) => void;
}
let busy: any;

const StoresPicker = (props: Props) => {
    const [loading, setLoading] = useState(false);

    const companies = CompanyStore.companies;

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            placeholder="Tienda"
            optionFilterProp="children"
            // filterOption={false}
            filterOption={(input, option: any) =>
                option?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={loading ? <Spin size="small" /> : null}
            onSearch={name => {
                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    search: name,
                };

                setLoading(true);

                busy = setTimeout(() => CompanyStore.getCompanies(params), 800);
            }}
            {...props}
        >
            {companies.map(entity => (
                <Select.Option key={entity.uid} value={entity.uid}>
                    {entity.company}
                </Select.Option>
            ))}
        </Select>
    );
};

export default observer(StoresPicker);
