import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CompanyStore } from '../../stores/CompanyStores';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../components/loading/loading';
import { Input } from 'antd';
import { MobileView } from 'react-device-detect';
import DownloadAndroidStore from 'components/buttons/DownloadAndroidStore';
import DownloadAppStore from 'components/buttons/DownloadAppStore';
import DownloadModal from './DownloadModal';
import { Skeleton } from '@mui/material';
import { TextField } from '@mui/material';
import { Helmet } from 'react-helmet';

const Home = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const history = useHistory();
    useEffect(() => {
        CompanyStore.getCompanies();
    }, []);

    const companies = CompanyStore.companies.filter(item => {
        if (searchQuery) {
            return item.company?.toLowerCase()?.indexOf(searchQuery?.toLowerCase()) >= 0;
        }
        return true;
    });

    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pedidos a domicilio con Rush Delivery - A tu puerta ahora, desde restaurantes cercanos</title>
            </Helmet>
            <div className="flex mx-4">
                <div className="w-full md:w-1/3 md:ml-auto ">
                    <TextField
                        id="outlined-search"
                        label="Buscar"
                        type="search"
                        className={'w-full'}
                        onChange={e => {
                            setSearchQuery(e.target.value);
                        }}
                    />

                    {/*<Input.Search*/}
                    {/*    value={searchQuery}*/}
                    {/*    onChange={e => setSearchQuery(e.target.value)}*/}
                    {/*    placeholder="Buscar"*/}
                    {/*    className={'w-full'}*/}
                    {/*/>*/}
                </div>
            </div>

            <MobileView>
                <DownloadModal />
            </MobileView>

            <div className="container margin_30_20">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="title_small">Restaurantes cerca de tí:</h2>
                            </div>

                            {CompanyStore.loading && CompanyStore.companies.length == 0 && (
                                <>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                        <Skeleton className="my-2" variant="rectangular" width={350} height={180} />
                                    </div>
                                </>
                            )}

                            {companies.map(c => {
                                return (
                                    <div
                                        key={c.uid}
                                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6"
                                        onClick={() => {
                                            CompanyStore.setCompany(c);
                                            history.push(`/restaurant/${c.uid}/`);
                                        }}
                                    >
                                        <div className="strip">
                                            <figure>
                                                {c.is_open_now && <span className="ribbon off">Abierto</span>}
                                                <img
                                                    style={{
                                                        opacity: !c.is_open_now ? 0.3 : 1,
                                                    }}
                                                    src={c.feature_graphic}
                                                    data-src="img/location_1.jpg"
                                                    className="img-fluid lazy"
                                                    alt={c.company}
                                                />
                                                <div className="strip_info">
                                                    {!c.is_open_now && (
                                                        <small
                                                            style={{
                                                                position: 'absolute',
                                                                top: '2px',
                                                                zIndex: 2,
                                                                background: 'white',
                                                                left: '2px',
                                                                padding: '2px',
                                                            }}
                                                        >
                                                            Cerrado {c.opens_at ? `Abre ${c.opens_at}` : ''}
                                                        </small>
                                                    )}
                                                    <div
                                                        className="item_title"
                                                        style={{
                                                            zIndex: 2,
                                                        }}
                                                    >
                                                        <h3>{c.company}</h3>
                                                        <small>{c.street}</small>
                                                    </div>
                                                </div>
                                            </figure>
                                            <ul>
                                                <li>
                                                    {/*<span className="take yes">Takeaway</span>*/}
                                                    <span className="deliv yes">Delivery</span>
                                                </li>
                                                <li>
                                                    <div className="score">
                                                        <strong>{c.reviews?.score?.toFixed(1) ?? 0}</strong>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default observer(Home);
