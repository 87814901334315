import React from 'react';

const Quantity = ({ quantity, handleChange }: { quantity: number; handleChange: (value: number) => void }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <button
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    border: 'none',
                    fontSize: 23,
                }}
                onClick={() => {
                    handleChange(quantity - 1);
                }}
            >
                -
            </button>
            <input
                style={{
                    width: 40,
                    margin: '0 10px',
                    border: 'none',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    height: 31,
                }}
                id="qty_1"
                type="text"
                value={quantity}
                className="qty2 form-control"
                readOnly
            />
            <button
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    border: 'none',
                    fontSize: 23,
                }}
                onClick={() => {
                    handleChange(quantity + 1);
                }}
            >
                +
            </button>
        </div>
    );
};

export default Quantity;
