import { action, observable } from 'mobx';
import { get } from '../utils/request';
import { CategoryProps } from '../types/types.ds';

class Category {
    @observable loading = false;

    @observable categories: Array<CategoryProps> = [];
    @observable search_categories: Array<CategoryProps> = [];

    @observable default_category?: CategoryProps;
    @observable default_search_category?: CategoryProps;
    @observable default_search_subcategory?: CategoryProps;

    @observable home_category_filter?: CategoryProps;

    @action getCategories(params = {}) {
        this.loading = true;

        return get('/categories/', params)
            .then(response => {
                this.categories = response.data.results;
                this.search_categories = response.data.results;

                //Set default category if is empty
                if (!this.default_category) {
                    this.default_category = this.categories.find(c => c.default);
                }
                if (!this.default_search_category) {
                    this.default_search_category = this.categories.find(c => c.default);
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.loading = false;
            });
    }

    @action setDefaultCategory = (category?: CategoryProps) => {
        this.default_category = category;
        //Clear filter
        this.home_category_filter = undefined;
    };

    @action setDefaultSearchCategory = (category?: CategoryProps) => {
        this.default_search_category = category;
        //Clear the other filter when the main category is changed
        this.default_search_subcategory = undefined;
    };

    @action setDefaultSearchSubcategory = (category?: CategoryProps) => {
        this.default_search_subcategory = category;
    };

    @action setHomeCategoryFilter = (category?: CategoryProps) => {
        this.home_category_filter = category;
    };
}

export const CategoryStore = new Category();
