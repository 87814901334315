import React from 'react';
import { Button, Form, Input, InputNumber, Switch, Upload } from 'antd';
import { ImagePicker } from '../../upload/image_picker';
import { CategoriesPicker } from '../../dropdowns';
import { CategoryProps } from '../../../types/types.ds';
import { PlusOutlined } from '@ant-design/icons';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

interface Props {
    categories: CategoryProps[];
    handleSave: (values: any) => Promise<void>;
}

const ProductAddForm = ({ categories, handleSave }: Props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <React.Fragment>
            <Form
                {...layout}
                onFinish={values => {
                    setIsLoading(true);
                    const payload = {
                        ...values,
                        image: values.image ? values.image.file : undefined,
                        category: values.category?.key,
                    };
                    console.log(payload);

                    handleSave(payload).finally(() => {
                        setIsLoading(false);
                    });
                }}
            >
                <Form.Item
                    name="image"
                    label="Imagen"
                    rules={[{ required: false }]}
                    help={
                        'Usa una imagen de 800x800px o superior para que se vea bien en la web, esto es opcional pero una buena imagen puede ayudar a vender más'
                    }
                >
                    <Upload multiple={false} maxCount={1} listType="picture-card" beforeUpload={() => false}>
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Seleccionar Imagen</div>
                        </div>
                    </Upload>
                </Form.Item>

                <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <div className={'flex flex-row'}>
                    <div className={'border p-2'}>
                        <Form.Item
                            initialValue={true}
                            name="delivery"
                            label="Disponible para Delivery?"
                            rules={[{ required: true }]}
                            valuePropName={'checked'}
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            name="original_price"
                            label="Precio (Para Llevar)"
                            help={'Usa el punto como separador de decimales'}
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder={'10.00'} />
                        </Form.Item>
                    </div>

                    <div className={'border p-2'}>
                        <Form.Item
                            initialValue={false}
                            name="in_store"
                            label="Disponible en el Local?"
                            rules={[{ required: false }]}
                            valuePropName={'checked'}
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            initialValue={0}
                            name="local_price"
                            label="Precio (Consumo Local)"
                            rules={[{ required: false }]}
                            help={
                                'Muy pronto los locales podrán tener acceso un nuevo terminal de punto de venta, y gestionar sus pedidos locales más rápidamente!'
                            }
                        >
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item name="description" label="Descripción" rules={[{ required: true }]}>
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    initialValue={0}
                    name="order"
                    label="Orden"
                    rules={[{ required: false }]}
                    help={'Dejar en 0 para mostrar el más vendido primero'}
                >
                    <InputNumber precision={0} placeholder={''} />
                </Form.Item>

                <Form.Item
                    name="category"
                    help={'Selecciona una opción'}
                    label="Categoría"
                    rules={[{ required: true }]}
                >
                    <CategoriesPicker categories={categories} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" className="s-btn btn-link " loading={isLoading}>
                        <i className="fas fa-search" /> &nbsp; Guardar y Continuar
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};

export default ProductAddForm;
