import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, message, Modal, PageHeader, Tabs } from 'antd';
import { ProductStore } from '../../../stores/ProductStore';
import { useHistory, useParams } from 'react-router-dom';
import { ExtraAddForm } from '../../../components/products/extras';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ScheduleForm } from '../../../components/products/product/ScheduleForm';
import { ProductAdminStore } from '../../../stores/Admin/ProductAdminStore';
import { ProductProps } from '../../../types/types.ds';
import { GalleryForm, ProductEditForm } from '../../../components/products/product';
import { Loading } from '../../../components/loading/loading';
import { CategoryAdminStore } from '../../../stores/Admin/CategoryAdminStore';
import { Helmet } from 'react-helmet';
import { UserStore } from '../../../stores/UserStore';
import ExtraCard from './components/ExtraCard';

const ProductEdit = observer(() => {
    const [isLoading, setLoading] = useState(false);
    const [product, setProduct] = useState<ProductProps>();
    const [addExtra, showAddExtra] = useState(false);

    const history = useHistory();

    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        CategoryAdminStore.getCategories({
            owner: UserStore.user?.uid,
        });
    }, [UserStore.user?.uid]);

    const handleSave = async (values: any) => {
        if (product?.uid) {
            await ProductAdminStore.updateProduct(product?.uid, values)
                .then(() => {
                    // history.goBack();
                    message.success('Guardado');
                    loadProduct();
                })
                .catch(err => {
                    message.error(err?.response?.data);
                });
        }
    };

    //@ts-ignore
    const { uid } = params;

    const loadProduct = () => {
        setLoading(true);
        ProductStore.getProductsById(uid)
            .then(response => setProduct(response.data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        loadProduct();
    }, []);

    if (!product) {
        return <Loading />;
    }

    const extras = product?.extras_list?.map(item => {
        return <ExtraCard key={item.uid} item={item} onUpdate={loadProduct} onDelete={() => history.goBack()} />;
    });
    const categories = UserStore.user?.is_staff
        ? CategoryAdminStore.categories
        : CategoryAdminStore.categories.filter(c => c.parent == UserStore.user?.category_related?.uid);
    return (
        <div className="container margin_30_20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Modificar Producto</title>
            </Helmet>
            <PageHeader
                style={{
                    marginTop: 20,
                }}
                onBack={() => history.goBack()}
                title={<div className={'capitalizeert'}>Regresar</div>}
            />

            <div className="container">
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Información Básica" key="1">
                        {!isLoading && (
                            <ProductEditForm product={product} categories={categories} handleSave={handleSave} />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Variaciones" key="2">
                        <Form.Item
                            help={
                                'Pudes agregar opciones como Tamaños, Ensaldas, Bebidas, Extras y puedes incluir un valor adicional si en caso el cliente selecciona alguna de estas opciones'
                            }
                        >
                            {extras}

                            <Col xs={24} className={'my-2 flex align-items-center'}>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        showAddExtra(true);
                                    }}
                                    block
                                >
                                    <PlusOutlined /> Agregar un grupo
                                </Button>
                            </Col>
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Disponibilidad" key="3">
                        <ScheduleForm
                            product={product}
                            handleSave={values => {
                                setLoading(true);
                                ProductAdminStore.updateProduct(product.uid, values)
                                    .then(() => {
                                        message.success('Guardado');
                                        loadProduct();
                                    })
                                    .catch(err => {
                                        message.error(err?.response?.data);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Galería" key="4">
                        <ul>
                            {product.gallery?.map(g => {
                                return (
                                    <li key={g.uid} className={'p-2'}>
                                        <div
                                            onClick={() => {
                                                ProductAdminStore.deleteProductGallery(g.uid).then(r => {
                                                    loadProduct();
                                                });
                                            }}
                                        >
                                            <DeleteOutlined
                                                style={{
                                                    color: 'red',
                                                }}
                                            />
                                        </div>
                                        <img
                                            style={{
                                                maxWidth: 101,
                                            }}
                                            src={g.thumbnail}
                                        />
                                    </li>
                                );
                            })}
                        </ul>

                        <GalleryForm
                            // product={product}
                            handleSave={values => {
                                setLoading(true);

                                ProductAdminStore.addProductGallery({
                                    product: product.uid,
                                    ...values,
                                })
                                    .then(() => {
                                        message.success('Guardado');
                                        loadProduct();
                                    })
                                    .catch(err => {
                                        message.error(err?.response?.data);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>

            <Modal
                bodyStyle={{ height: 500, width: '100%' }}
                title="Nuevo Grupo"
                visible={addExtra}
                onCancel={() => showAddExtra(false)}
                footer={[]}
            >
                <ExtraAddForm
                    type="product"
                    handleSave={values => {
                        values['product'] = product.uid;
                        setLoading(true);
                        ProductAdminStore.AddExtra(values)
                            .then(r => {
                                loadProduct();
                                showAddExtra(false);
                                setLoading(false);
                            })
                            .catch(err => {
                                alert(err?.response?.data);
                                setLoading(false);
                            });
                    }}
                />
            </Modal>
        </div>
    );
});

export default ProductEdit;
