import { action, observable, runInAction } from 'mobx';
import { get } from '../utils/request';
import { ProductProps } from '../types/types.ds';
import { CategoryStore } from './CategoryStore';
import { CancelTokenSource } from 'axios';

class Product {
    @observable products: Array<ProductProps> = [];

    @observable loading_promotions = false;
    @observable promotions: Array<ProductProps> = [];

    @observable loading_search = false;
    @observable search_products: Array<ProductProps> = [];
    @observable next_page_for_search_products?: number;

    @action getProductsByCompany(uid: string, cancelRequest: CancelTokenSource, category?: string) {
        const params: any = {
            owner: uid,
        };

        if (category) {
            params['category'] = category;
        }

        return get(`/products/`, params, cancelRequest);
    }

    @action getProductsById(uid: string) {
        return get(`/products/${uid}/`);
    }

    @action searchProducts(params: { search?: string; page: number }) {
        const payload: any = {
            ...params,
        };

        if (!params?.page || params?.page < 2) {
            this.loading_search = true;
        }

        // Search by subcategory if is available otherwise for the main category
        if (CategoryStore.default_search_subcategory?.uid) {
            payload['category'] = CategoryStore.default_search_subcategory?.uid;
        } else if (CategoryStore.default_search_category?.uid) {
            payload['category'] = CategoryStore.default_search_category?.uid;
        }

        payload['owner__is_open_now'] = true;

        return get('/products/', payload)
            .then(response => {
                const search_products = response.data.results.map((item: ProductProps) => {
                    return {
                        key: item.uid,
                        ...item,
                        discountPercentage: 0,
                    };
                });

                if (params?.page > 1) {
                    this.search_products = [...this.search_products, ...search_products];
                } else {
                    this.search_products = [...search_products];
                }
                runInAction(() => {
                    this.next_page_for_search_products = response.data?.links.next;
                });
            })
            .catch(err => {
                console.log(JSON.stringify(err?.response?.data));
                runInAction(() => {
                    this.next_page_for_search_products = undefined;
                });
            })
            .finally(() => (this.loading_search = false));
    }

    @action getPromotions(params = {}) {
        const payload: any = {
            ...params,
            is_promotion: true,
            owner__is_open_now: true,
        };

        this.loading_promotions = true;

        return get('/products/', payload)
            .then(response => {
                runInAction(() => {
                    this.promotions = response.data.results;
                });
            })
            .catch(err => {
                console.log(JSON.stringify(err));
            })
            .finally(() => (this.loading_promotions = false));
    }
}

export const ProductStore = new Product();
