import React from 'react';
import { ExtraListProps } from '../../types/types.ds';

interface Props {
    extras?: ExtraListProps[];
}

const ExtraListCard = ({ extras }: Props) => {
    return (
        <div>
            {extras?.map((extra, index) => {
                if (extra.options?.filter(option => option.picked).length > 0) {
                    return (
                        <div key={index} className="flex flex-col">
                            <div className="">{extra.name}:</div>
                            <div className={'flex flex-row'}>
                                {extra.options
                                    ?.filter(option => option.picked)
                                    .map((option, index2) => {
                                        return <div key={index2}>{option.name},</div>;
                                    })}
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default ExtraListCard;
