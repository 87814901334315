import { CartProps, CartStore } from '../../stores/CartStore';
import { ExtraListCard } from './index';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const CartItem = ({ cart_item, onRemove }: { cart_item: CartProps; onRemove: () => void }) => {
    return (
        <div className="flex flex-row justify-between border-b p-2">
            <div className="w-1/6 font-bold"> {cart_item.quantity} x</div>
            <div className="w-3/6 flex flex-col justify-left items-left">
                <div className="font-bold">{cart_item.product.name}</div>
                <div className={'extras text-sm'}>
                    <ExtraListCard extras={cart_item.extras_list} />
                </div>
                {cart_item.special_instructions && (
                    <>
                        <div className={''}>{cart_item.special_instructions}</div>
                    </>
                )}
            </div>
            <div className="w-1/6">${cart_item.total.toFixed(2)}</div>

            <div className="w-1/6 text-center" onClick={onRemove}>
                <DeleteIcon />
            </div>
        </div>
    );
};

export default CartItem;
