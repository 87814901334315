import React, { useState } from 'react';
import { Form, Input, Button, Modal, PageHeader } from 'antd';
import 'antd/dist/antd.css';
import { Helmet } from 'react-helmet';

const AccountDeletionRequestPage = () => {
    const [form] = Form.useForm();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleAccountDeletionRequest = async (values: any) => {
        // Simulate backend API call for account deletion request
        setIsProcessing(true);

        try {
            // Assuming you have an API endpoint for account deletion request
            // Replace this with your actual backend API call
            await fetch('/account-deletion-request/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: values.email,
                    comments: values.comments,
                }),
            });

            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting account deletion request:', error);
            Modal.error({
                title: 'Error',
                content: 'Error submitting account deletion request. Please try again later.',
            });
        } finally {
            setIsProcessing(false);
        }
    };

    if (isSubmitted) {
        return (
            <div>
                <div className="container margin_30_20">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Modificar Variacion</title>
                    </Helmet>
                    <h2>Your account deletion request has been submitted.</h2>
                    <p>Thank you for reaching out. We will process your request shortly.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="container margin_30_20">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Modificar Variacion</title>
                </Helmet>
                <PageHeader
                    style={{
                        marginTop: 20,
                    }}
                    title={<div className={'capitalizeert'}>Account Deletion Request</div>}
                />
                <p>Please provide your email and any additional comments to initiate the account deletion process.</p>

                <Form form={form} onFinish={handleAccountDeletionRequest} layout="vertical">
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter your email' },
                            { type: 'email', message: 'Please enter a valid email address' },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Comments" name="comments">
                        <Input.TextArea rows={4} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isProcessing}>
                            {isProcessing ? 'Submitting...' : 'Submit Request'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default AccountDeletionRequestPage;
