import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FourOhFour, Home, PrivacyPolicy, RestaurantDetail, TermsAndConditions } from './pages';
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';

import './App.css';

import Checkout from './pages/Checkout/Checkout';
import { UserStore } from './stores/UserStore';
import { UIStore } from './stores/UIStore';
import { Loading } from './components/loading/loading';
import Orders from './pages/Orders/Orders';
import { Header, Footer } from './layouts';
import { Layout, notification } from 'antd';
import { AuthenticationStore } from './stores/AuthenticationStore';
import Authentication from './pages/authentication/Authentication';
import DeliveryZones from 'pages/Admin/DeliveryZones';
import ProductList from './pages/Admin/products/ProductList';
import ProductNew from './pages/Admin/products/ProductNew';
import ProductEdit from './pages/Admin/products/ProductEdit';
import ReportCommission from './pages/Admin/Reports/ReportCommission';
import OpenHours from './pages/Admin/OpenHours';
import { AccountEdit } from './pages/Admin/AccountEdit';
import { NotificationStore } from './stores/NotificationStore';
import { initializeApp } from 'firebase/app';
import { getToken, onMessage, getMessaging } from 'firebase/messaging';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { ChatStore } from './stores/ChatStore';
import { OrderStore } from './stores/OrderStore';
import ReportCustomers from './pages/Admin/Reports/ReportCustomers';
import ReportCommissionDrivers from './pages/Admin/Reports/Orders/ReportCommissionDrivers';
import ReportDeliveryTimes from './pages/Admin/Reports/Orders/ReportDeliveryTimes';
import ReportOrders from './pages/Admin/Reports/ReportOrders';
import OrderDetail from './pages/Orders/OrderDetail';
import CategoryList from './pages/Admin/categories/CategoryList';
import CategoryEdit from './pages/Admin/categories/CategoryEdit';
import ExtraList from './pages/Admin/extras/ExtraList';
import ExtraEdit from './pages/Admin/extras/ExtraEdit';
import { CompanyStore } from './stores/Admin/CompanyAdminStores';
import AccountDeletionRequestPage from './pages/AccountDeletionRequestPage';

const App = observer(() => {
    const firebaseConfig = {
        apiKey: 'AIzaSyDHd-3YRLFpm6zkNm6WglQjDgdHElcenqY',
        authDomain: 'jm-services-ab0d9.firebaseapp.com',
        databaseURL: 'https://jm-services-ab0d9.firebaseio.com',
        projectId: 'jm-services-ab0d9',
        storageBucket: 'jm-services-ab0d9.appspot.com',
        messagingSenderId: '120497830262',
        appId: '1:120497830262:web:e6fa30d4c9c9c6e5ffa257',
        measurementId: 'G-3QQ3CJPS1E',
    };

    const hasNotificationsSupport = 'Notification' in window;

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    const analytics = getAnalytics(app);

    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        UIStore.setIsReadOnly(queryParams.get('readOnly') === 'true');
    }, [queryParams]);

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            logEvent(analytics, 'screen_view', {
                firebase_screen: window.location.pathname + window.location.search,
                firebase_screen_class: window.location.search,
            });
        }
    }, [history?.location]);

    React.useEffect(() => {
        setLoading(true);
        UserStore.loadCurrentUser()
            .then(() => {
                NotificationStore.getNotifications();
            })
            .finally(() => setLoading(false));
    }, []);

    onMessage(messaging, payload => {
        if (payload.notification?.title) {
            notification.success({
                description: payload.notification.body,
                message: payload.notification.title,
                duration: 15,
            });

            // NotificationStore.getNotifications();
            // ChatStore.getRooms();
            // OrderStore.getOrders();

            new Notification(payload.notification.title, {
                body: payload.notification.body,
                silent: false,
            });
        }
    });

    /**
     * Get FCM token
     */
    React.useEffect(() => {
        if (hasNotificationsSupport && AuthenticationStore.isAuthenticated) {
            getToken(messaging, {
                vapidKey: 'BF6yxeTxOGFumJSoNWLgG5Y3tPoKCF64MbStALLJokA_Ih8rdbK_7qvx7bxB66IEXZcfgeg30517dFMQbHyHeg4',
            }).then(currentToken => {
                if (currentToken) {
                    UserStore.setPushNotificationToken(currentToken);
                }
            });
        }
    }, [AuthenticationStore.token]);

    React.useEffect(() => {
        if (AuthenticationStore.isAuthenticated) {
            UserStore.savePushNotificationToken();

            // NotificationStore.getNotifications();
            // OrderStore.getOrders();
            // ChatStore.getRooms();
        }
    }, [AuthenticationStore.token, UserStore.push_notification_token]);

    /**
     * Add Header and footer to the rendered component
     * @param ChildComponent
     * @param props
     * @returns {*}
     */
    const withLayout = (ChildComponent: any, props: any) => {
        return (
            <React.Fragment>
                <Layout
                    style={{
                        minHeight: '100vh',
                        maxWidth: '98%',
                        margin: 'auto',
                        backgroundColor: '#fff',
                    }}
                >
                    {!UIStore.isReadOnly ? <Header /> : null}

                    <Layout.Content style={{ margin: '7px 0' }}>
                        <div style={{ padding: 0, minHeight: 360 }}>
                            <ChildComponent {...props} />
                        </div>
                    </Layout.Content>
                    <Footer />
                </Layout>
            </React.Fragment>
        );
    };

    /**
     * Check if the user is logged in before render a component
     * @param ChildComponent
     * @param rest
     * @returns {*}
     * @constructor
     */

    const PublicRoute = ({ component: ChildComponent, ...rest }: any) => {
        return (
            <Route
                {...rest}
                render={(props: any) => {
                    return withLayout(ChildComponent, props);
                }}
            />
        );
    };

    const PrivateRoute = ({ component: ChildComponent, ...rest }: any) => {
        if (!AuthenticationStore.isAuthenticated) {
            const next = window.location.pathname;
            return <Redirect to={'/login?next=' + next} />;
        }
        return (
            <Route
                {...rest}
                render={(props: any) => {
                    return withLayout(ChildComponent, props);
                }}
            />
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <PublicRoute path="/restaurant/:company_uid/" exact component={RestaurantDetail} />
                    <PrivateRoute path="/checkout/" exact component={Checkout} />
                    <PrivateRoute path="/orders/" exact component={Orders} />
                    <PrivateRoute path="/orders/:id/" exact component={OrderDetail} />
                    <PublicRoute path="/login/" exact component={Authentication} />

                    <PublicRoute path="/admin/delivery-zones/" exact component={DeliveryZones} />
                    <PublicRoute path="/admin/reports/customers" exact component={ReportCustomers} />
                    <PublicRoute path="/admin/reports/commission/rushers" exact component={ReportCommissionDrivers} />
                    <PublicRoute path="/admin/reports/delivery-times" exact component={ReportDeliveryTimes} />
                    <PublicRoute path="/admin/reports/orders" exact component={ReportOrders} />
                    <PublicRoute path="/admin/reports/" exact component={ReportCommission} />
                    <PublicRoute path="/admin/open-hours/" exact component={OpenHours} />
                    <PublicRoute path="/admin/my-account/" exact component={AccountEdit} />

                    <PublicRoute path="/admin/products/" exact component={ProductList} />
                    <PublicRoute path="/admin/product/new/" exact component={ProductNew} />
                    <PublicRoute path="/admin/product/:uid/edit" exact component={ProductEdit} />

                    <PublicRoute path="/admin/categories/" exact component={CategoryList} />
                    <PublicRoute path="/admin/categories/:uid/edit" exact component={CategoryEdit} />

                    <PublicRoute path="/admin/extras/" exact component={ExtraList} />
                    <PublicRoute path="/admin/extras/:uid/edit" exact component={ExtraEdit} />

                    <PublicRoute path="/terms-and-conditions/" exact component={TermsAndConditions} />
                    <PublicRoute path="/privacy-policy" exact component={PrivacyPolicy} />
                    <PublicRoute path="/" exact component={Home} />
                    <PublicRoute path="/account-deletion" exact component={AccountDeletionRequestPage} />
                    <PublicRoute component={FourOhFour} />
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
});

export default App;
