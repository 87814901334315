import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, DatePicker, Row, Statistic, Table } from 'antd';

import { DollarOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { IDeliveryTimes, IDriverCommissions, OrdersAdminStore } from '../../../../stores/Admin/OrderStore';
import { StoresPicker } from '../../../../components/dropdowns';
import { UserStore } from '../../../../stores/UserStore';
import { OrderReportProps } from '../../../../types/types.ds';
import { Helmet } from 'react-helmet';
import { ColumnsType } from 'antd/es/table';
import { UserReportProps } from '../../../../stores/Admin/UserAdminStore';
import DateRangePicker from '../../../../components/DateRangePicker';

function getColorByTime(minutes: number) {
    if (minutes > 60) {
        return 'red';
    }

    if (minutes > 40) {
        return 'orange';
    }

    return 'inherit';
}
const ReportDeliveryTimes = () => {
    const [owner, setOwner] = useState<{ value: string; label: string }>();
    const [date, setDate] = useState<any>();

    useEffect(() => {
        if (!date) {
            return;
        }
        const params: any = {
            created_time_min: date[0].format('YYYY-MM-DD'),
            created_time_max: date[1].format('YYYY-MM-DD'),
        };

        if (owner?.value) {
            params['owner'] = owner.value;
        }

        OrdersAdminStore.getReportDeliveryTimes(params);
    }, [date, owner]);

    const columns: ColumnsType<IDeliveryTimes> = [
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
        },
        {
            title: 'Store',
            dataIndex: 'store',
            key: 'store',
        },

        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
        },
        {
            title: 'Direccion',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Tiempo de Confirmacion',
            dataIndex: 'confirmed-time',
            key: 'confirmed-time',
        },
        {
            title: 'Tiempo de Recogida',
            dataIndex: 'pickup-time',
            key: 'pickup-time',
        },
        {
            title: 'Tiempo de Entrega',
            dataIndex: 'delivered-time',
            key: 'delivered-time',
            render: (t, record) => {
                const array = record['delivered-time'].split(':');

                const minutes = parseInt(array[0]);

                return (
                    <div
                        style={{
                            color: getColorByTime(minutes),
                            fontSize: minutes >= 40 ? 18 : 'inherit',
                        }}
                    >
                        {record['delivered-time']}
                    </div>
                );
            },
        },
    ];

    return (
        <div className={'flex flex-col p-4'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reportes</title>
            </Helmet>
            <div className={'w-full pt-4 flex flex-row items-center justify-center'}>
                {UserStore.isStaff && (
                    <div className={'flex flex-row items-center justify-center w-1/2'}>
                        <label>Establecimiento:</label>{' '}
                        <StoresPicker
                            onChange={value => {
                                setOwner(value);
                            }}
                        />
                    </div>
                )}
                <div className={'ml-2'}>
                    <label>Fecha:</label> <DateRangePicker onChange={date => setDate(date)} />
                </div>
            </div>

            <div className={'pt-4'}>
                <Table<IDeliveryTimes>
                    pagination={false}
                    columns={columns}
                    loading={OrdersAdminStore.loading}
                    dataSource={OrdersAdminStore.deliveryTimes}
                />
            </div>

            {/*<div className={'p-4 text-center'}>*/}
            {/*    <Row gutter={16}>*/}
            {/*        <Col span={8}>*/}
            {/*            <Statistic*/}
            {/*                title="# Total 10%"*/}
            {/*                value={total_10_percentage.toFixed(2)}*/}
            {/*                prefix={<FieldNumberOutlined />}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*        <Col span={8}>*/}
            {/*            <Statistic*/}
            {/*                title="Total 15%"*/}
            {/*                value={total_15_percentage.toFixed(2)}*/}
            {/*                prefix={<DollarOutlined />}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*        <Col span={8}>*/}
            {/*            <Statistic*/}
            {/*                title="Total 20%"*/}
            {/*                value={total_20_percentage.toFixed(2)}*/}
            {/*                prefix={<DollarOutlined />}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</div>*/}
        </div>
    );
};

export default observer(ReportDeliveryTimes);
