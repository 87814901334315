import React from 'react';
import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
    return (
        <div
            className={'privacy-policy'}
            style={{
                marginTop: 10,
                marginBottom: 40,
                padding: 20,
            }}
        >
            <Title level={1}>Política de privacidad</Title>
            <Paragraph>Última actualización: 31 de Diciembre de 2023</Paragraph>
            <Paragraph>
                Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y
                la divulgación de su información cuando utiliza el Servicio y le informa sobre Sus derechos de
                privacidad y cómo la ley lo protege.
            </Paragraph>
            <Paragraph>
                Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la
                recopilación y el uso de información de acuerdo con esta Política de privacidad.
            </Paragraph>
            <Title level={2}>1. Interpretación y Definiciones</Title>
            <Title level={3}>1.1 Interpretación</Title>
            <Paragraph>
                Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes
                condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que
                aparezcan en singular o en plural.
            </Paragraph>
            <Title level={3}>1.2 Definiciones</Title>

            <Paragraph>A los efectos de esta Política de privacidad:</Paragraph>

            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>
                        <Paragraph>
                            <strong>Cuenta</strong> significa una cuenta única creada para que Usted acceda a nuestro
                            Servicio o partes de nuestro Servicio.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Afiliado</strong> significa una entidad que controla, es controlada o está bajo el
                            control común de una parte, donde &quot;control&quot; significa la propiedad del 50 % o más
                            de las acciones, participación accionaria u otros valores con derecho a voto para la
                            elección de directores u otra autoridad administrativa.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Aplicación</strong> significa el programa de software proporcionado por la Compañía
                            descargado por Usted en cualquier dispositivo electrónico, denominado Rush Delivery App
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Compañía</strong> (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro" en
                            este Acuerdo) se refiere a la aplicación Rush Delivery, Gualaceo.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>País</strong> se refiere a: Ecuador
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio,
                            como una computadora, un teléfono celular o una tableta digital.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Datos personales</strong> es cualquier información relacionada con una persona
                            física identificada o identificable.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Servicio</strong> hace referencia a la Aplicación.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Proveedor de servicios</strong> significa cualquier persona física o jurídica que
                            procesa los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas
                            empleadas por la Empresa para facilitar el Servicio, proporcionar el Servicio en nombre de
                            la Empresa, realizar servicios relacionados con el Servicio o ayudar a la Empresa a analizar
                            cómo se utiliza el Servicio.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Servicio de redes sociales de terceros</strong> se refiere a cualquier sitio web o
                            cualquier sitio web de red social a través del cual un Usuario puede iniciar sesión o crear
                            una cuenta para utilizar el Servicio.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Consumo de Datos</strong> se refiere a los datos recopilados automáticamente, ya sea
                            generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo,
                            la duración de una visita a la página).
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Usted</strong> se refiere a la persona que accede o utiliza el Servicio, o la
                            empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el
                            Servicio, según corresponda.
                        </Paragraph>
                    </li>
                </ul>
            </Paragraph>

            <Title level={2}>2. Recopilación y uso de sus datos personales</Title>
            <Title level={3}>2.1 Tipos de datos recopilados</Title>

            <Title level={4}>2.1.1 Datos personales</Title>
            <Paragraph>
                Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación
                personal que se puede usar para contactarlo o identificarlo. La información de identificación personal
                puede incluir, entre otros:
            </Paragraph>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>
                        <Paragraph>Dirección de correo electrónico</Paragraph>
                    </li>
                    <li>
                        <Paragraph>Nombre y apellido</Paragraph>
                    </li>
                    <li>
                        <Paragraph>Número de teléfono</Paragraph>
                    </li>
                    <li>
                        <Paragraph>Dirección, estado, provincia, código postal, ciudad</Paragraph>
                    </li>
                    <li>
                        <Paragraph>Datos de Uso</Paragraph>
                    </li>
                    <li>Información Sensible: No procesamos información sensible.</li>
                    <li>
                        Datos de inicio de sesión en redes sociales: Podemos brindarle la opción de registrarse con
                        nosotros utilizando los detalles de su cuenta de redes sociales existente, como su cuenta de
                        Facebook, Google o Apple. Si elige registrarse de esta manera, recopilaremos la información
                        descrita en la sección denominada "<em>¿Cómo manejamos sus inicio de sesión social?</em>"
                    </li>
                </ul>
            </Paragraph>

            <Title level={4}>2.2.2 Datos de Uso</Title>
            <Paragraph>Los datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</Paragraph>
            <Paragraph>
                Los datos de uso pueden incluir información como la dirección del protocolo de Internet de su
                dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas
                de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas. ,
                identificadores únicos de dispositivos y otros datos de diagnóstico.
            </Paragraph>
            <Paragraph>
                Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información
                automáticamente, incluido, entre otros, el tipo de dispositivo móvil que utiliza, la identificación
                única de su dispositivo móvil, la dirección IP de su dispositivo móvil , su sistema operativo móvil, el
                tipo de navegador de Internet móvil que utiliza, identificadores únicos de dispositivos y otros datos de
                diagnóstico.
            </Paragraph>
            <Paragraph>
                También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o
                cuando accede al Servicio a través de un dispositivo móvil.
            </Paragraph>
            <Title level={4}>2.2.3 Información de servicios de redes sociales de terceros</Title>
            <Paragraph>
                La Compañía le permite crear una cuenta e iniciar sesión para usar el Servicio a través de los
                siguientes Servicios de redes sociales de terceros:
            </Paragraph>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>Google</li>
                    <li>Facebook</li>
                    <li>Apple</li>
                </ul>
            </Paragraph>
            <Paragraph>
                Si decide registrarse a través de servicio de redes sociales de terceros, podemos recopilar datos
                personales que ya están asociados con la cuenta de su Servicio de redes sociales de terceros, como su
                nombre y su dirección de correo electrónico.
            </Paragraph>
            <Title level={4}>2.2.4 Información recopilada durante el uso de la aplicación</Title>
            <Paragraph>
                Mientras usa Nuestra Aplicación, para proporcionar funciones de Nuestra Aplicación, podemos recopilar,
                con su permiso previo:
            </Paragraph>
            <Title level={4}>2.2.5 ¿Cómo manejamos sus inicio de sesión social?</Title>
            <Paragraph>
                En resumen: si elige registrarse o iniciar sesión en nuestros Servicios utilizando una cuenta de red
                social, es posible que tengamos acceso a cierta información sobre usted.
            </Paragraph>
            <Paragraph>
                Nuestros Servicios le ofrecen la posibilidad de registrarse e iniciar sesión utilizando los detalles de
                su cuenta de redes sociales de terceros (como sus inicios de sesión de Facebook, Gmail o Apple). Cuando
                elija hacer esto, recibiremos cierta información de su perfil de su proveedor de redes sociales. La
                información de perfil que recibimos puede variar según el proveedor de redes sociales en cuestión, pero
                a menudo incluirá su nombre, dirección de correo electrónico, foto de perfil, así como otra información
                que elija hacer pública en dicha plataforma de redes sociales.
            </Paragraph>
            <Paragraph>
                Utilizaremos la información que recibimos solo para los fines que se describen en este aviso de
                privacidad o que de otro modo se le aclaran en los Servicios correspondientes. Tenga en cuenta que no
                controlamos ni somos responsables de otros usos de su información personal por parte de su proveedor
                externo de redes sociales. Le recomendamos que revise su aviso de privacidad para comprender cómo
                recopilan, usan y comparten su información personal, y cómo puede configurar sus preferencias de
                privacidad en sus sitios y aplicaciones.
            </Paragraph>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>Información sobre su ubicación</li>
                </ul>
            </Paragraph>
            <Paragraph>
                Usamos esta información para proporcionar características de Nuestro Servicio, para mejorar y
                personalizar Nuestro Servicio. La información se puede cargar en los servidores de la Compañía y/o en el
                servidor de un Proveedor de servicios o simplemente se puede almacenar en su dispositivo.
            </Paragraph>
            <Paragraph>
                Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la
                configuración de su dispositivo.
            </Paragraph>
            <Title level={3}>2.2 Uso de sus datos personales</Title>
            <Paragraph>La Empresa podrá utilizar los Datos Personales para los siguientes fines:</Paragraph>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>
                        <Paragraph>
                            <strong>Para proporcionar y mantener nuestro Servicio</strong>, incluido el control del uso
                            de nuestro Servicio.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Para administrar su Cuenta:</strong> para administrar su registro como usuario del
                            Servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes
                            funcionalidades del Servicio que están disponibles para Usted como usuario registrado.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Para la ejecución de un contrato:</strong> el desarrollo, cumplimiento y realización
                            del contrato de compra de los productos, artículos o servicios que haya adquirido o de
                            cualquier otro contrato con Nosotros a través del Servicio.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Para contactarlo:</strong> para contactarlo por correo electrónico, llamadas
                            telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como
                            notificaciones automáticas de una aplicación móvil con respecto a actualizaciones o
                            comunicaciones informativas relacionadas con las funcionalidades, productos o servicios
                            contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable
                            para su implementación.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Para proporcionarle</strong> noticias, ofertas especiales e información general
                            sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya ha
                            comprado o sobre los que ha consultado, a menos que haya optado por no hacerlo. recibir
                            dicha información.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Para gestionar Sus solicitudes:</strong> Para atender y gestionar Sus solicitudes
                            hacia Nosotros.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Para transferencias comerciales:</strong> podemos usar su información para evaluar o
                            realizar una fusión, desinversión, reestructuración, reorganización, disolución u otra venta
                            o transferencia de algunos o todos nuestros activos, ya sea como negocio en marcha o como
                            parte de una quiebra, liquidación o procedimiento similar, en el que los datos personales
                            que tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos
                            transferidos.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph>
                            <strong>Para otros fines</strong>: podemos utilizar su información para otros fines, como el
                            análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia
                            de nuestras campañas promocionales y la evaluación y mejora de nuestro Servicio, productos,
                            servicios, marketing y su experiencia.
                        </Paragraph>
                    </li>
                </ul>
            </Paragraph>
            <Paragraph>Podemos compartir su información personal en las siguientes situaciones:</Paragraph>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>
                        <strong>Con Proveedores de Servicios:</strong> Podemos compartir su información personal con
                        Proveedores de Servicios para monitorear y analizar el uso de nuestro Servicio, para
                        contactarlo.
                    </li>
                    <li>
                        <strong>Para transferencias comerciales:</strong> podemos compartir o transferir su información
                        personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de
                        la Compañía, financiación o adquisición de la totalidad o una parte de Nuestro negocio a otra
                        empresa.
                    </li>
                    <li>
                        <strong>Con afiliados:</strong> podemos compartir su información con nuestros afiliados, en cuyo
                        caso les exigiremos que respeten esta Política de privacidad. Los afiliados incluyen nuestra
                        empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que
                        controlamos o que están bajo control común con nosotros.
                    </li>
                    <li>
                        <strong>Con socios comerciales:</strong> podemos compartir su información con Nuestros socios
                        comerciales para ofrecerle ciertos productos, servicios o promociones.
                    </li>

                    <li>
                        <strong>Con su consentimiento</strong>: podemos divulgar su información personal para cualquier
                        otro propósito con su consentimiento.
                    </li>
                </ul>
            </Paragraph>
            <Title level={3}>2.3 Retención de sus datos personales</Title>
            <Paragraph>
                La Compañía conservará sus Datos personales solo durante el tiempo que sea necesario para los fines
                establecidos en esta Política de privacidad. Conservaremos y utilizaremos sus datos personales en la
                medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a
                conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestras
                políticas y acuerdos legales.
            </Paragraph>
            <Paragraph>
                La Empresa también conservará los Datos de uso para fines de análisis interno. Los Datos de uso
                generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se usan para
                fortalecer la seguridad o mejorar la funcionalidad de Nuestro Servicio, o cuando estamos legalmente
                obligados a retener estos datos por períodos de tiempo más largos.
            </Paragraph>

            <Title level={3}>2.4 Acceso, Rectificación, Supresión.</Title>
            <Paragraph>
                Respetamos sus derechos de privacidad y le brindamos un acceso razonable a los Datos personales que
                puede haber proporcionado a través del uso de los Servicios. Si desea acceder o modificar cualquier otro
                dato personal que tengamos sobre usted, o solicitar que eliminemos o transfiramos cualquier información
                sobre usted que hayamos obtenido de un Servicio Integrado, puede comunicarse con nosotros como se
                establece en la sección "Contáctenos". Si lo solicita, eliminaremos o bloquearemos cualquier referencia
                a usted en nuestra base de datos.
            </Paragraph>
            <Title level={3}>2.5 Transferencia de sus datos personales</Title>
            <Paragraph>
                Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía y
                en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que
                esta información se puede transferir y mantener en computadoras ubicadas fuera de su estado, provincia,
                país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de
                su jurisdicción.
            </Paragraph>
            <Paragraph>
                Su consentimiento a esta Política de privacidad seguido de su envío de dicha información representa su
                acuerdo con esa transferencia.
            </Paragraph>
            <Paragraph>
                La Empresa tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de
                forma segura y de conformidad con esta Política de privacidad, y no se realizará ninguna transferencia
                de sus Datos personales a una organización o país, a menos que existan controles adecuados establecidos,
                incluidos la seguridad de Sus datos y otra información personal.
            </Paragraph>
            <Title level={3}>2.6 Divulgación de sus datos personales</Title>
            <Title level={4}>2.6.1 Transacciones comerciales</Title>
            <Paragraph>
                Si la Compañía está involucrada en una fusión, adquisición o venta de activos, Sus Datos personales
                pueden transferirse. Le enviaremos un aviso antes de que sus Datos personales se transfieran y queden
                sujetos a una Política de privacidad diferente.
            </Paragraph>
            <Title level={4}>2.6.2 Cumplimiento de la ley</Title>
            <Paragraph>
                En determinadas circunstancias, es posible que se le solicite a la Empresa que divulgue Sus Datos
                personales si así lo requiere la ley o en respuesta a solicitudes válidas de las autoridades públicas
                (p. ej., un tribunal o una agencia gubernamental).
            </Paragraph>
            <Title level={4}>2.6.3 Otros requisitos legales</Title>
            <Paragraph>
                La Compañía puede divulgar sus datos personales de buena fe cuando considere que esta acción es
                necesaria para lo siguiente:
            </Paragraph>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>Cumplir con una obligación legal</li>
                    <li>Proteger y defender los derechos o la propiedad de la Compañía</li>
                    <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
                    <li>Proteger la seguridad personal de los Usuarios del Servicio o del público</li>
                    <li>Proteger contra responsabilidad legal</li>
                </ul>
            </Paragraph>
            <Title level={3}>2.7 Seguridad de sus datos personales</Title>
            <Paragraph>
                La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de
                transmisión por Internet o método de almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos
                por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar
                su seguridad absoluta.
            </Paragraph>
            <Title level={2}>3. Privacidad infantil</Title>
            <Paragraph>
                Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas información
                de identificación personal de ninguna persona menor de 13 años. Si es padre o tutor y sabe que su hijo
                nos ha proporcionado Datos, por favor contáctenos. Si nos damos cuenta de que hemos recopilado Datos
                personales de cualquier persona menor de 13 años sin verificación del consentimiento de los padres,
                tomamos medidas para eliminar esa información de Nuestros servidores.
            </Paragraph>
            <Paragraph>
                Si necesitamos confiar en el consentimiento como base legal para procesar su información y su país
                requiere el consentimiento de uno de sus padres, es posible que necesitemos el consentimiento de su
                padre antes de recopilar y usar esa información.
            </Paragraph>
            <Title level={2}>4. Enlaces a otros sitios web</Title>
            <Paragraph>
                Nuestro Servicio puede contener enlaces a otros sitios web que no operamos Nosotros. Si hace clic en el
                enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise
                la Política de privacidad de cada sitio que visite.
            </Paragraph>
            <Paragraph>
                No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o
                las prácticas de sitios o servicios de terceros.
            </Paragraph>
            <Title level={2}> 5. Cambios a esta Política de Privacidad</Title>
            <Paragraph>
                Podemos actualizar nuestra política de privacidad de vez en cuando. Le notificaremos cualquier cambio
                publicando la nueva Política de privacidad en esta página.
            </Paragraph>
            <Paragraph>
                Le informaremos por correo electrónico y/o un aviso destacado en Nuestro Servicio, antes de que el
                cambio entre en vigencia y actualizaremos la &quot;Última actualización&quot; fecha en la parte superior
                de esta Política de Privacidad.
            </Paragraph>
            <Paragraph>
                Le recomendamos que revise esta Política de privacidad periódicamente para ver si ha habido cambios. Los
                cambios a esta Política de privacidad son efectivos cuando se publican en esta página.
            </Paragraph>

            <Title level={2}>6. ¿Cómo puedes revisar, actualizar o eliminar los datos que recopilamos de ti?</Title>

            <Paragraph>
                Según las leyes aplicables de su país, es posible que tenga derecho a solicitar acceso a la información
                personal información que recopilamos de usted, cambiar esa información o eliminarla. Para solicitar la
                revisión, actualización o eliminar su información personal puede hacerlo desde la sección de perfil de
                la aplicación o enviando un correo electrónico a{' '}
                <a href={'mailto:info@rushdelivery.app'}>info@rushdelivery.app</a>
            </Paragraph>
            <Title level={2}>7. Contáctenos</Title>
            <Paragraph>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:</Paragraph>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: 'square',
                    }}
                >
                    <li>
                        Por correo electrónico: <a href="mailto:info@rushdelivery.app">info@rushdelivery.app</a>
                    </li>
                </ul>
            </Paragraph>
        </div>
    );
};

export default PrivacyPolicy;
