import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, DatePicker, Row, Statistic, Table } from 'antd';

import { DollarOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { OrdersAdminStore } from '../../../stores/Admin/OrderStore';
import { StoresPicker } from '../../../components/dropdowns';
import { UserStore } from '../../../stores/UserStore';
import { OrderReportProps } from '../../../types/types.ds';
import { Helmet } from 'react-helmet';
import DateRangePicker from '../../../components/DateRangePicker';

export const ReportCommission = () => {
    const [owner, setOwner] = useState<{ value: string; label: string }>();
    const [date, setDate] = useState<any>();

    useEffect(() => {
        if (!date) {
            return;
        }
        const params: any = {
            created_time_min: date[0].format('YYYY-MM-DD'),
            created_time_max: date[1].format('YYYY-MM-DD'),
        };

        if (owner?.value) {
            params['owner'] = owner.value;
        }

        OrdersAdminStore.getReportAll(params);
    }, [date, owner]);

    const columns = [
        {
            title: 'Día',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Nro.',
            dataIndex: 'count',
            key: 'count',
        },

        {
            title: 'Total',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Inversión',
            dataIndex: 'commission',
            key: 'commission',
        },
    ];

    const count = OrdersAdminStore.ordersCommissions.reduce((t, order) => t + order.count, 0);
    const amount = OrdersAdminStore.ordersCommissions.reduce((t, order) => t + order.amount, 0);
    const inversion = OrdersAdminStore.ordersCommissions.reduce((t, order) => t + order.commission, 0);

    return (
        <div className={'flex flex-col p-4'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reportes</title>
            </Helmet>
            <div className={'w-full pt-4 flex flex-row items-center justify-center'}>
                {UserStore.isStaff && (
                    <div className={'flex flex-row items-center justify-center w-1/2'}>
                        <label>Establecimiento:</label>{' '}
                        <StoresPicker
                            onChange={value => {
                                setOwner(value);
                            }}
                        />
                    </div>
                )}

                <div className={'ml-2'}>
                    <label>Fecha:</label> <DateRangePicker onChange={date => setDate(date)} />
                </div>
            </div>

            <div className={'pt-4'}>
                <Table<OrderReportProps>
                    pagination={false}
                    columns={columns}
                    loading={OrdersAdminStore.loading}
                    dataSource={OrdersAdminStore.ordersCommissions}
                />
            </div>

            <div className={'p-4 text-center'}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="# Ventas" value={count} prefix={<FieldNumberOutlined />} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Total" value={amount.toFixed(2)} prefix={<DollarOutlined />} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="Inversión" value={inversion.toFixed(2)} prefix={<DollarOutlined />} />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default observer(ReportCommission);
