import { action, observable, computed, runInAction } from 'mobx';
import { get, post } from '../utils/request';
import { UserProps } from '../types/types.ds';
import { persist } from 'mobx-persist';

export interface MessageProps {
    uid: string;
    room: string;
    message: string;
    created_by: UserProps;
    created_time: string;
    read_by?: Array<string>;
}

export interface RoomProps {
    uid: string;
    name: string;
    created_time: string;
    messages: Array<MessageProps>;
    has_pending: boolean;
}

export function UUID(length = 25) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

class Chat {
    @persist @observable loading = false;
    @persist('list') @observable rooms: Array<RoomProps> = [];
    @persist('object') @observable room?: RoomProps;

    @action getRooms = () => {
        this.loading = true;

        get(`/rooms/`)
            .then(response => {
                this.rooms = response.data.results;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action updateOrAppendRoomToTheList = (new_room: RoomProps) => {
        let updated = false;
        this.rooms = this.rooms.map(current_room => {
            if (new_room.uid === current_room.uid) {
                updated = true;
                return new_room;
            }
            return current_room;
        });

        if (!updated) {
            this.rooms.push(new_room);
        }
    };

    @action setRoom = (room: RoomProps) => {
        this.room = room;
    };

    @computed get has_pending() {
        return this.rooms.filter(item => item.has_pending).length > 0;
    }

    @action getRoomById = (room_uid: string) => {
        get(`/rooms/${room_uid}/`)
            .then(response => {
                this.room = response?.data;

                //Update messages from room list
                // this.rooms = this.rooms.map(room => {
                //     if (room.uid === this.room?.uid) {
                //         return this.room
                //     }
                //     return room
                // })
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action markAsViewed = (room_uid: string) => {
        this.rooms = this.rooms.map(room => {
            return {
                ...room,
                has_pending: false,
            };
        });
        return post(`/rooms/${room_uid}/viewed/`).then(response => {});
    };

    @action getRoomByOrder = (order_uid: string) => {
        this.loading = true;

        get(`/orders/${order_uid}/room/`)
            .then(response => {
                this.room = response?.data;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action appendMessage(new_message: MessageProps) {
        let inserted = false;
        if (this.room) {
            this.room.messages = this.room.messages.map(message => {
                if (message.uid === new_message.uid) {
                    inserted = true;
                    return new_message;
                }
                return message;
            });

            if (!inserted) {
                this.room.messages = [new_message, ...this.room.messages];
            }
        }
        //Update messages from room list
        // this.rooms = this.rooms.map(room => {
        //     if (room.uid === this.room?.uid) {
        //         return this.room
        //     }
        //     return room
        // })
    }

    @action updateLocalMessage(local_message: MessageProps, new_message: MessageProps) {
        if (this.room) {
            this.room.messages = this.room.messages.map(current => {
                if (current.uid === local_message.uid) {
                    return new_message;
                }
                return current;
            });
        }
    }

    @action sendMessage(local_message: MessageProps) {
        if (this.room) {
            this.room.messages = this.room.messages = [local_message, ...this.room.messages];

            const payload: any = {
                room: this.room.uid,
                message: local_message.message,
            };

            post(`/messages/`, payload)
                .then(response => {
                    const new_message = response.data;
                    runInAction(() => {
                        if (this.room) {
                            this.updateLocalMessage(local_message, new_message);
                        }
                    });
                })
                .catch(err => {
                    console.log(JSON.stringify(err));
                });
        }
    }

    @action onSend = (message: string) => {
        if (this.room?.uid) {
            const payload: any = {
                room: this.room.uid,
                message: message,
            };

            post(`/messages/`, payload)
                .then(response => {
                    const new_message = response.data;
                    this.appendMessage(new_message);
                })
                .catch(err => {
                    console.log(JSON.stringify(err));
                });
        }
    };
}

export const ChatStore = new Chat();
