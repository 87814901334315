import { CartStore } from '../../stores/CartStore';
import { ShoppingCartOutlined } from '@ant-design/icons';
import React from 'react';

interface Props {
    onClick: () => void;
}

const CartButton = (props: Props) => {
    return (
        <div
            className={'cursor-pointer'}
            style={{
                backgroundColor: 'rgb(0, 148, 90)',
                width: '62px',
                borderRadius: '19px',
                textAlign: 'center',
                color: 'white',
                height: '35px',
                display: 'flex',
                placeContent: 'center',
                alignItems: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
            }}
            onClick={props.onClick}
        >
            <div
                style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                }}
            >
                {CartStore.number_cart_items ?? 0}
            </div>
            <ShoppingCartOutlined
                style={{
                    fontSize: 30,
                }}
            />
        </div>
    );
};

export default CartButton;
