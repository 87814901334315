import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
// import './hamburgerIcon.css';

interface Props {
    className?: string;
    onClick?: () => void;
}

const HamburgerIcon: React.FC<Props> = ({ className, onClick }) => {
    return (
        <div className={className} onClick={onClick}>
            <MenuOutlined
                style={{
                    fontSize: 30,
                }}
            />
        </div>
    );
};

export default HamburgerIcon;
