import React, { useEffect, useState } from 'react';
import { Button, Form, message, Row, Spin, Switch } from 'antd';
import { UserStore } from '../../stores/UserStore';
import { observer } from 'mobx-react';
import { CompanyStore } from '../../stores/Admin/CompanyAdminStores';
import { Input } from 'antd/es';
import { OpenHoursProps, OwnerProps } from '../../types/types.ds';
import { Loading } from '../../components/loading/loading';

const DAYS = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

const OpenHours = observer(() => {
    const [isLoading, setLoading] = useState(false);
    const [monday, setMonday] = useState<OpenHoursProps>({
        weekday: 0,
        is_open: false,
        from_hour: '',
        to_hour: '',
        day: '',
    });

    const [tuesday, setTuesday] = useState<OpenHoursProps>({
        weekday: 1,
        is_open: false,
        from_hour: '',
        to_hour: '',
        day: '',
    });

    const [wed, setWed] = useState<OpenHoursProps>({
        weekday: 2,
        is_open: false,
        from_hour: '',
        to_hour: '',
        day: '',
    });

    const [thursday, setThursday] = useState<OpenHoursProps>({
        weekday: 3,
        is_open: false,
        from_hour: '',
        to_hour: '',
        day: '',
    });

    const [friday, setFriday] = useState<OpenHoursProps>({
        weekday: 4,
        is_open: false,
        from_hour: '',
        to_hour: '',
        day: '',
    });

    const [saturday, setSaturday] = useState<OpenHoursProps>({
        weekday: 5,
        is_open: false,
        from_hour: '',
        to_hour: '',
        day: '',
    });

    const [sunday, setSunday] = useState<OpenHoursProps>({
        weekday: 6,
        is_open: false,
        from_hour: '',
        to_hour: '',
        day: '',
    });

    useEffect(() => {
        if (UserStore.user?.uid) {
            setLoading(true);
            CompanyStore.getCompany(UserStore.user.uid)
                .then(r => {
                    const company: OwnerProps = r.data;
                    const open_hours = company.open_hours;
                    open_hours.forEach(item => {
                        if (item.weekday === 0) {
                            setMonday(item);
                        }
                        if (item.weekday === 1) {
                            setTuesday(item);
                        }
                        if (item.weekday === 2) {
                            setWed(item);
                        }
                        if (item.weekday === 3) {
                            setThursday(item);
                        }
                        if (item.weekday === 4) {
                            setFriday(item);
                        }
                        if (item.weekday === 5) {
                            setSaturday(item);
                        }
                        if (item.weekday === 6) {
                            setSunday(item);
                        }
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const RenderDay = ({ values, handleChange }: { values: any; handleChange: (values: OpenHoursProps) => void }) => {
        const { weekday, is_open, from_hour, to_hour } = values || {};

        return (
            <div className={'flex flex-row w-full'}>
                <Form.Item className={'w-1/4'}>
                    <div>{DAYS[weekday]}</div>
                </Form.Item>
                <Form.Item className={'w-1/4'}>
                    <Switch
                        checked={is_open}
                        onChange={checked => {
                            handleChange({
                                ...values,
                                is_open: checked,
                            });
                        }}
                        size="default"
                        checkedChildren="Abierto"
                        unCheckedChildren="Cerrado"
                    />
                </Form.Item>
                <Form.Item className={'w-1/4'}>
                    <Input
                        type={'time'}
                        value={from_hour}
                        onChange={e => {
                            handleChange({
                                ...values,
                                from_hour: e.target.value,
                            });
                        }}
                        step="15"
                    />
                </Form.Item>

                <Form.Item className={'w-1/4'}>
                    <Input
                        type={'time'}
                        value={to_hour}
                        onChange={e => {
                            handleChange({
                                ...values,
                                to_hour: e.target.value,
                            });
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const formatForServer = (val: OpenHoursProps) => {
        return {
            weekday: val.weekday,
            is_open: val.is_open || false,
            from_hour: val.from_hour || null,
            to_hour: val.to_hour || null,
        };
    };

    if (!UserStore.user?.uid || isLoading) {
        return <Loading />;
    }

    const store_id = UserStore.user.uid;

    return (
        <div className={'flex flex-col p-4 m-4'}>
            <Row>
                <RenderDay
                    values={monday}
                    handleChange={new_item => {
                        setMonday(new_item);
                    }}
                />
            </Row>

            <Row>
                <RenderDay
                    values={tuesday}
                    handleChange={new_item => {
                        setTuesday(new_item);
                    }}
                />
            </Row>

            <Row>
                <RenderDay
                    values={wed}
                    handleChange={new_item => {
                        setWed(new_item);
                    }}
                />
            </Row>

            <Row>
                <RenderDay
                    values={thursday}
                    handleChange={new_item => {
                        setThursday(new_item);
                    }}
                />
            </Row>

            <Row>
                <RenderDay
                    values={friday}
                    handleChange={new_item => {
                        setFriday(new_item);
                    }}
                />
            </Row>

            <Row>
                <RenderDay
                    values={saturday}
                    handleChange={new_item => {
                        setSaturday(new_item);
                    }}
                />
            </Row>

            <Row>
                <RenderDay
                    values={sunday}
                    handleChange={new_item => {
                        setSunday(new_item);
                    }}
                />
            </Row>

            <Row>
                <Form.Item>
                    <Button
                        loading={isLoading}
                        type={'primary'}
                        htmlType={'submit'}
                        onClick={() => {
                            setLoading(true);

                            const payload = {
                                open_hours: [
                                    { ...formatForServer(monday) },
                                    { ...formatForServer(tuesday) },
                                    { ...formatForServer(wed) },
                                    { ...formatForServer(thursday) },
                                    { ...formatForServer(friday) },
                                    { ...formatForServer(saturday) },
                                    { ...formatForServer(sunday) },
                                ],
                            };

                            CompanyStore.updateOpenHours(store_id, payload)
                                .then(() => {
                                    message.success('Horario actualizado');
                                })
                                .catch(error => {
                                    message.error(JSON.stringify(error?.response?.data));
                                })
                                .finally(() => setLoading(false));
                        }}
                    >
                        Guardar
                    </Button>
                </Form.Item>
            </Row>
        </div>
    );
});

export default OpenHours;
