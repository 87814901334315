import React from 'react';
import dayjs from 'dayjs';
import EmailIcon from '@mui/icons-material/Email';
import { RoomProps } from '../../stores/ChatStore';

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);

export const MessageItem = ({ room, onClick }: { room: RoomProps; onClick: () => void }) => {
    const last_message =
        room.messages.length > 0 ? `${room.messages[0].created_by.first_name}: ${room.messages[0].message}` : '';

    return (
        <div className="flex flex-row  border-b mb-4 pb-2" onClick={onClick}>
            <div className="media-left">
                <EmailIcon />
            </div>
            <div className="ml-2">
                <h4 className="media-heading">{room.name}</h4>
                <p>{last_message}</p>
                <div className="comment-date">{dayjs(room.created_time).format('L LT')}</div>
            </div>
        </div>
    );
};
