import { action, observable, runInAction } from 'mobx';

class UI {
    @observable isReadOnly = true;

    @action setIsReadOnly = (isReadOnly: boolean) => {
        this.isReadOnly = isReadOnly;
    };
}

export const UIStore = new UI();
