import * as React from 'react';
import { Select, SelectProps } from 'antd';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { CategoryProps } from '../../types/types.ds';

const { Option, OptGroup } = Select;

interface Props extends SelectProps {
    categories: CategoryProps[];
}

const CategoriesPicker = observer((props: Props) => {
    const categories = props.categories;

    const by_group = _.groupBy(categories, c => {
        return c.parent;
    });

    const options = Object.keys(by_group).map(key => {
        const first = by_group[key][0];
        return (
            <OptGroup key={key} label={first.parent_related?.name ?? first.name}>
                {by_group[key].map(i => {
                    return (
                        <Option key={i.uid} value={i.uid}>
                            {i.name}
                        </Option>
                    );
                })}
            </OptGroup>
        );
    });

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            size={'small'}
            style={{ width: '100%' }}
            notFoundContent={<div>No encuentras lo que necesitas? Agrega una nueva desde el menu Categorias</div>}
            dropdownMatchSelectWidth={false}
            filterOption={(input, option: any) =>
                option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            {...props}
        >
            {options}
        </Select>
    );
});

export default CategoriesPicker;
