import React from 'react';
import { Drawer as DrawerD } from 'antd';
import { observer } from 'mobx-react';

interface DrawerProps {
    title: string | React.ReactNode;
    visible: boolean;
    onClose: () => void;
    children: any;
}

const Drawer = ({ visible, onClose, children, ...props }: DrawerProps) => (
    <DrawerD
        className="inline-block"
        placement="left"
        width={350}
        bodyStyle={{ padding: 0 }}
        title={props.title}
        visible={visible}
        onClose={onClose}
        closable
    >
        {children}
    </DrawerD>
);

export default observer(Drawer);
