import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { OrderStore } from '../../stores/OrderStore';
import OrderCard from '../../components/cards/OrderCard';
import { Alert, Button, message, Modal } from 'antd';
import { Link, Skeleton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from 'react-router-dom';
import { OrderProps, UserProps } from '../../types/types.ds';
import ContactCard from '../../components/cards/ContactCard';
import { Chat } from '../../components/Chat/Chat';
import { RoomProps } from '../../stores/ChatStore';
import { UserStore } from '../../stores/UserStore';
import { Helmet } from 'react-helmet';

const Orders = () => {
    const history = useHistory();
    const [notificationEnabled, setNotificationsEnabled] = useState<boolean>(false);

    const hasNotificationsSupport = 'Notification' in window;

    useEffect(() => {
        if (hasNotificationsSupport) {
            if (Notification.permission == 'granted' || Notification.permission == 'default') {
                setNotificationsEnabled(true);
            }
        }
    }, []);

    const [showContactDetail, setShowContactDetails] = useState<{
        user: UserProps;
        order: OrderProps;
    } | null>(null);

    const [room, setRoom] = useState<RoomProps | null>();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const loadOrders = () => {
        if (UserStore.user?.is_seller) {
            OrderStore.getOrders({
                completed: false,
            });
        } else {
            OrderStore.getOrders();
        }
    };

    useEffect(() => {
        loadOrders();
    }, []);

    const orders = OrderStore.orders.map(order => {
        return (
            <div
                key={order.id}
                style={{
                    marginBottom: 15,
                }}
            >
                <OrderCard
                    key={order.id}
                    order={order}
                    handleShowContactDetails={(user, order) => {
                        setShowContactDetails({
                            user: user,
                            order: order,
                        });
                    }}
                />
            </div>
        );
    });
    const handleRequestNotification = () => {
        Notification.requestPermission().then(perm => {
            if (perm !== 'granted') {
                message.error('El navegador ha bloqueado las notificaciones');
            }
            setNotificationsEnabled(perm === 'granted');
        });
    };

    return (
        <div
            style={{
                margin: 10,
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tus Órdenes</title>
            </Helmet>
            <div
                className="row"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                }}
            >
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 1200,
                        margin: 'auto',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <h4 className={'text-xl'} style={{ fontWeight: 'bold', marginTop: 15 }}>
                            Tus Órdenes
                        </h4>
                    </div>

                    {hasNotificationsSupport && !notificationEnabled && (
                        <div className={'my-2'}>
                            <Alert
                                message={
                                    <div>
                                        <Button type={'primary'} onClick={() => handleRequestNotification()}>
                                            Click para recibir notificaciones de tus órdenes
                                        </Button>
                                    </div>
                                }
                                type="warning"
                            />
                        </div>
                    )}
                    {OrderStore.loading && OrderStore.orders.length == 0 && (
                        <div>
                            <div>
                                <Skeleton variant="text" width={300} />
                                <Skeleton variant="rectangular" width={'100%'} height={218} />
                            </div>
                            <div>
                                <Skeleton variant="text" width={300} />
                                <Skeleton variant="rectangular" width={'100%'} height={218} />
                            </div>
                        </div>
                    )}

                    <div className={'order-card'} style={{ marginTop: 15 }}>
                        {orders}
                    </div>

                    {!OrderStore.loading && OrderStore.orders.length == 0 && (
                        <div className={'m-4 text-thin text-center'}>
                            <div className="mt-4">No haz realizado una orden todavía</div>
                            <div className="mt-4">
                                <AddShoppingCartIcon
                                    style={{
                                        fontSize: 40,
                                    }}
                                />
                            </div>
                            <div className="mt-4">
                                <Button
                                    type={'dashed'}
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                >
                                    <div>
                                        COMPRAR AHORA <ArrowForwardIcon />
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {room && (
                <div
                    style={{
                        marginTop: 40,
                    }}
                >
                    <Chat room={room} currentUserId={UserStore?.user?.uid ?? ''} onClose={() => setRoom(null)} />
                </div>
            )}

            {showContactDetail?.user && (
                <ContactCard
                    user={showContactDetail.user}
                    handleClose={() => setShowContactDetails(null)}
                    onClickChat={() => {
                        OrderStore.getOrCreateRoomByOrder(showContactDetail.order.uid)
                            .then(r => {
                                setRoom(r.data);
                                setShowContactDetails(null);
                            })
                            .finally(() => {});
                    }}
                />
            )}
        </div>
    );
};

export default observer(Orders);
