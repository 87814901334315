import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import { Affix, Modal, PageHeader } from 'antd';

import { CategoryProps, ExtraListProps, ProductProps } from 'types/types.ds';

import { CompanyStore } from 'stores/CompanyStores';
import { ProductStore } from 'stores/ProductStore';
import { UIStore } from 'stores/UIStore';
import { CartStore } from 'stores/CartStore';

import CartView from './CartView';
import CategoryItem from '../../components/cards/CategoryItem';
import Dish from '../../components/cards/Dish';
import ProductView from 'pages/RestaurantDetail/ProductView';

import Toast from '../../components/Toast/toast';
import { ModalViewer } from 'pages/RestaurantDetail/ModalViewer';

import { ScrollingProvider, Section } from 'react-scroll-section';
import { AuthenticationStore } from '../../stores/AuthenticationStore';
import { Login } from '../authentication/login';
import { Loading } from '../../components/loading/loading';
import { useHistory } from 'react-router-dom';
import { Chip } from '@mui/material';
import Swal from 'sweetalert2';
import { Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet';

const RestaurantDetail = () => {
    const params = useParams();

    const history = useHistory();

    // @ts-ignore
    const { company_uid } = params;

    const [isLoadingCategories, setIsLoadingCategories] = useState(true);
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [categories, setCategories] = useState<CategoryProps[]>([]);

    const [product, setProduct] = useState<ProductProps | null>(null);

    const [isAffixed, setIsAffixed] = useState<boolean | undefined>(false);
    const [displayAuthForm, setDisplayAuthForm] = useState<boolean>(false);

    const company = CompanyStore.company;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (product?.uid) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [product]);

    /**
     * Load company
     */
    useEffect(() => {
        const cancelRequest = axios.CancelToken.source();

        CompanyStore.getCompanyById(company_uid, cancelRequest);

        return () => {
            if (cancelRequest) {
                cancelRequest.cancel();
            }
        };
    }, [company_uid]);

    /**
     * Load categories of company
     */
    useEffect(() => {
        const cancelRequest = axios.CancelToken.source();

        CompanyStore.getSubcategoriesByCompany(company_uid, cancelRequest)
            .then(response => {
                setCategories(response.data);
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoadingCategories(false);
            });

        return () => {
            if (cancelRequest) {
                cancelRequest.cancel();
            }
        };
    }, [company_uid]);

    /**
     * Load products of the company
     */
    useEffect(() => {
        const cancelRequest = axios.CancelToken.source();

        ProductStore.getProductsByCompany(company_uid, cancelRequest)
            .then((r: any) => {
                setProducts(r.data.results);
            })
            .finally(() => {
                setIsLoadingProducts(false);
            });

        return () => {
            if (cancelRequest) {
                cancelRequest.cancel();
            }
        };
    }, [company_uid]);

    const onPressOrderNow = () => {
        if (!AuthenticationStore.isAuthenticated) {
            setDisplayAuthForm(true);
        } else {
            history.push('/checkout/');
        }
    };

    const handleCategoryClick = (category: CategoryProps) => {};

    const onPressAddToCart = async (
        product: ProductProps,
        extras_list: ExtraListProps[],
        quantity: number,
        total: number,
        special_instructions: string
    ) => {
        if (!product) {
            return;
        }

        if (!product.in_stock) {
            Toast.show(`Producto agotado por hoy`, {
                position: Toast.position.BOTTOM,
                duration: 2000,
                type: 'error',
            });
            return;
        }

        if (!product.owner?.is_open_now) {
            Toast.show(`Lo sentimos, este local se encuentra cerrado por ahora`, {
                position: Toast.position.BOTTOM,
                duration: 2000,
                type: 'error',
            });

            return;
        }

        const isNotCompleted = extras_list.find(group => {
            const total_picked = group.options.filter(option => option.picked).length;

            if (group.min > 0 && group.min > total_picked) {
                Toast.show(`${group.name}: Mínimo ${group.min} opciones`, {
                    position: Toast.position.BOTTOM,
                    duration: 1500,
                    type: 'error',
                });
                return true;
            }

            if (group.required && total_picked === 0) {
                Toast.show(`${group.name} es requerido`, {
                    position: Toast.position.BOTTOM,
                    duration: 1500,
                    type: 'error',
                });
            }

            return group.required && total_picked === 0;
        });

        if (isNotCompleted) {
            return;
        }

        if (!CartStore.isSameStore(product)) {
            Swal.fire({
                title: '¿Empezar una nueva order?',
                html:
                    'Por el momento solo puedes ordenar productos de una misma tienda, ¿deseas borrar los productos de tu lista de compras para iniciar una nueva order?',
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: 'rgb(0, 185, 112)',
            }).then(result => {
                if (result.isConfirmed) {
                    CartStore.emptyCart();
                    CartStore.addToCart(product, quantity, extras_list, total, special_instructions);
                    Toast.sweet(`Producto agregado a tu lista de compras`, {
                        position: Toast.position.CENTER,
                        duration: 400,
                    });

                    setProduct(null);
                }
            });
        } else {
            CartStore.addToCart(product, quantity, extras_list, total, special_instructions);

            Toast.sweet(`Producto agregado a tu lista de compras`, {
                position: Toast.position.CENTER,
                duration: 400,
            });

            setProduct(null);
        }
    };

    if (CompanyStore.loading || !company?.uid) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <Loading />
            </div>
        );
    }

    const isReadOnly = UIStore.isReadOnly;

    return (
        <ScrollingProvider>
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`${company.company} pedidos a domicilio por Rush Delivery en Gualaceo`}</title>

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://rushdelivery.app/" />
                    <meta property="og:site_name" content={`${company.company} "`} />
                    <meta property="og:image" itemProp="image primaryImageOfPage" content={company.feature_graphic} />
                </Helmet>

                {!isReadOnly ? (
                    <div className="restaurant-header">
                        <div
                            className="h-48 md:h-60 xl:h-96"
                            style={{
                                width: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                                marginTop: '0px',
                            }}
                        >
                            <div
                                className="h-full"
                                style={{
                                    position: 'absolute',
                                    left: '0px',
                                    top: '0px',
                                    borderRadius: '16px',
                                    width: '100%',
                                    backgroundColor: 'rgb(247, 247, 247)',
                                }}
                            >
                                <img
                                    src={company.feature_graphic}
                                    alt={company.company}
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        borderRadius: 23,
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={'top-40 md:top-60 xl:top-96'}
                            style={{
                                left: '3%',
                                position: 'absolute',
                            }}
                        >
                            <img
                                src={company.thumbnail}
                                width="100"
                                height="100"
                                alt={company.company}
                                style={{
                                    borderRadius: '50%',
                                }}
                            />
                        </div>
                    </div>
                ) : null}

                {!isReadOnly ? (
                    <div className="md:mt-8">
                        <PageHeader
                            onBack={() => history.push('/')}
                            title={<div className={'capitalizeert'}>{company.company}</div>}
                            subTitle={
                                <div className="hidden md:block">
                                    {company.is_open_now ? (
                                        <Chip style={{ backgroundColor: '#00b970', color: '#fff' }} label="Abierto" />
                                    ) : (
                                        <Chip
                                            style={{
                                                backgroundColor: 'rgb(255, 72, 66)',
                                                color: '#fff',
                                            }}
                                            label="Cerrado"
                                        />
                                    )}
                                </div>
                            }
                        />
                    </div>
                ) : null}

                <main>
                    <Affix
                        onChange={v => {
                            setIsAffixed(v);
                        }}
                    >
                        <nav
                            className="secondary_nav sticky_horizontal"
                            style={{
                                boxShadow: isAffixed ? '0px 5px 5px 0px rgb(0 0 0 / 5%)' : 'initial',
                            }}
                        >
                            <div className="container">
                                <ul id="secondary_nav">
                                    {categories.map(category => (
                                        <CategoryItem
                                            key={category.uid}
                                            category={category}
                                            handleClick={handleCategoryClick}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </nav>
                    </Affix>

                    <div className="">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 list_menu">
                                    {isLoadingCategories && (
                                        <>
                                            <div className="flex flex-col">
                                                <div className="mr-auto">
                                                    <Skeleton variant="rectangular" width={200} height={30} />
                                                    <Skeleton
                                                        className="mt-2"
                                                        variant="rectangular"
                                                        width={350}
                                                        height={120}
                                                    />
                                                </div>
                                                <div className="mr-auto mt-4">
                                                    <Skeleton variant="rectangular" width={200} height={30} />
                                                    <Skeleton
                                                        className="mt-2"
                                                        variant="rectangular"
                                                        width={350}
                                                        height={120}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {categories.map(category => {
                                        return (
                                            <Section key={category.uid} id={category.uid}>
                                                <h4
                                                    className={'text-xl'}
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {category.name}
                                                </h4>

                                                <div className="row">
                                                    {isLoadingProducts ? (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <div className="flex flex-col md:flex-row justify-between">
                                                                <div className="m-auto">
                                                                    <Skeleton
                                                                        variant="rectangular"
                                                                        width={350}
                                                                        height={200}
                                                                    />
                                                                </div>
                                                                <div className="m-auto">
                                                                    <Skeleton
                                                                        className="mt-4 md:mt-0"
                                                                        variant="rectangular"
                                                                        width={350}
                                                                        height={200}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        products
                                                            .filter(product => product.category === category.uid)
                                                            .map(product => {
                                                                return (
                                                                    <Dish
                                                                        key={product.uid}
                                                                        product={product}
                                                                        handleClick={() => setProduct(product)}
                                                                    />
                                                                );
                                                            })
                                                    )}
                                                </div>
                                            </Section>
                                        );
                                    })}
                                </div>

                                <div className="col-lg-4" id="sidebar_fixed">
                                    {product?.uid && (
                                        <ModalViewer
                                            title={product?.name}
                                            onCancel={() => {
                                                setProduct(null);
                                            }}
                                        >
                                            {product?.uid && (
                                                <ProductView
                                                    product={product}
                                                    isReadOnly={isReadOnly}
                                                    onPressAddToCart={onPressAddToCart}
                                                    onPressCancel={() => setProduct(null)}
                                                />
                                            )}
                                        </ModalViewer>
                                    )}

                                    <div>
                                        {!isReadOnly ? (
                                            <Affix offsetTop={80}>
                                                <CartView onPressOrderNow={onPressOrderNow} />
                                            </Affix>
                                        ) : null}
                                    </div>
                                </div>

                                {displayAuthForm && (
                                    <Modal
                                        title={'Login'}
                                        visible
                                        onCancel={() => {
                                            setDisplayAuthForm(false);
                                        }}
                                        footer={[]}
                                    >
                                        <Login
                                            onSuccess={() => {
                                                setDisplayAuthForm(false);
                                                history.push('/checkout/');
                                            }}
                                        />
                                    </Modal>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </ScrollingProvider>
    );
};

export default observer(RestaurantDetail);
