import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParameters } from '../../../../hooks/useQueryParameters';
import { UserStore } from '../../../../stores/UserStore';
import { StoresPicker } from '../../../../components/dropdowns';
import { ProductAdminStore } from '../../../../stores/Admin/ProductAdminStore';
import { observer } from 'mobx-react';

const Search = Input.Search;

const ProductFilterForm = () => {
    const history = useHistory();
    const parameters = useQueryParameters();

    const handleSubmit = (values: any) => {
        const params: any = {
            search: values.search ?? '',
        };

        if (values.is_promotion) {
            params['is_promotion'] = values.is_promotion;
        }
        if (values.owner?.key) {
            params['owner'] = values.owner?.key;
        }

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        history.replace(`/admin/products/?${queryString}`);
    };

    return (
        <Row className={'row-filters'}>
            <Form
                layout="inline"
                onFinish={handleSubmit}
                initialValues={{
                    search: parameters.get('search'),
                    is_promotion: parameters.get('is_promotion') ?? false,
                    owner: parameters.get('owner')
                        ? {
                              key: parameters.get('owner'),
                              label: parameters.get('owner'),
                          }
                        : undefined,
                }}
            >
                <Form.Item name={'search'}>
                    <Search placeholder="Buscar" style={{ width: 400 }} />
                </Form.Item>

                <Form.Item name={'is_promotion'} label={'Mostrar solo Promociones?'} valuePropName="checked">
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    name={'owner'}
                    label={'Tienda'}
                    style={{
                        minWidth: 200,
                        display: !UserStore.isStaff ? 'none' : 'inherit',
                    }}
                >
                    <StoresPicker disabled={!UserStore.isStaff} />
                </Form.Item>

                <Button
                    htmlType="button"
                    onClick={() => {
                        history.replace(`/admin/products/`);
                    }}
                >
                    Reset
                </Button>
                <Button htmlType="submit" type={'primary'} loading={ProductAdminStore.loading_search}>
                    Filtrar
                </Button>
            </Form>
        </Row>
    );
};

export default observer(ProductFilterForm);
