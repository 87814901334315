import React, { useState } from 'react';
import { TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

interface Props {
    onSendMessage: (text: string) => void;
}

const ChatInput = (props: Props) => {
    const [text, setText] = useState<string>('');
    return (
        <div className={'flex flex-row w-full items-center justify-center'}>
            <div
                style={{
                    width: '90%',
                }}
            >
                <TextField
                    placeholder={'Ingresa tu mensaje'}
                    style={{
                        width: '100%',
                    }}
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
            </div>
            <div
                style={{
                    width: '10%',
                    textAlign: 'center',
                }}
            >
                <SendIcon
                    style={{
                        fontSize: 40,
                    }}
                    onClick={() => {
                        if (text) {
                            props.onSendMessage(text);
                            setText('');
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ChatInput;
