import { Modal } from 'antd';
import React, { ReactNode } from 'react';

import useWindowDimensions from '../../hooks/useWindowDimensions';

const ModalTitle = ({ title }: { title: string }) => {
    return (
        <div
            // className="small-dialog-header"
            style={{
                width: '100%',
                position: 'relative',
                left: 0,
                top: 0,
                borderRadius: '4px 4px',
                display: 'inline-block',
            }}
        >
            <h3
                style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 20,
                    fontWeight: 'bold',
                }}
            >
                {title}
            </h3>
        </div>
    );
};

interface Props {
    title: string;
    children: ReactNode;
    onCancel: () => void;
}

export const ModalViewer = ({ title, children, onCancel }: Props) => {
    const { height } = useWindowDimensions();

    return (
        <Modal
            className={'modal-viewer'}
            title={<ModalTitle title={title} />}
            visible
            centered
            onCancel={onCancel}
            footer={[]}
            style={{
                // top: 0,
                // height: height - 74,
                maxHeight: 'calc(100vh - 32px)',
            }}
            bodyStyle={
                {
                    // height: height - 80,
                }
            }
        >
            <div
                className={'content'}
                style={
                    {
                        // height: height - 120,
                    }
                }
            >
                {children}
            </div>
        </Modal>
    );
};
