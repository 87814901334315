import React from 'react';

import DownloadAndroidStore from 'components/buttons/DownloadAndroidStore';
import DownloadAppStore from 'components/buttons/DownloadAppStore';

const Footer = () => {
    return (
        <div className="mt-4 mb-8">
            <div className="flex flex-row items-center justify-center  m-auto max-w-md">
                <div className="flex flex-row justify-center max-w-xs">
                    <DownloadAndroidStore />
                    <DownloadAppStore />
                </div>
            </div>
        </div>
    );
};

export default Footer;
