import EmailIcon from '@mui/icons-material/Email';
import * as React from 'react';

interface Props {
    count: number;
    onClick: () => void;
}
const MessageIconCount = (props: Props) => {
    return (
        <div
            className="cursor-pointer"
            style={{
                display: 'flex',
            }}
            onClick={props.onClick}
        >
            <div
                style={{
                    backgroundColor: 'rgb(255, 72, 66)',
                    width: 25,
                    height: 25,
                    borderRadius: '50%',
                    color: '#fff',
                    position: 'relative',
                    top: 0,
                    left: 0,
                }}
            >
                {props.count}
            </div>
            <EmailIcon />
        </div>
    );
};

export default MessageIconCount;
