import React from 'react';
import dayjs from 'dayjs';
import { NotificationProps } from '../../types/types.ds';
import NotificationsIcon from '@mui/icons-material/Notifications';

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);

export const NotificationItem = ({ notification }: { notification: NotificationProps }) => {
    return (
        <div className="flex flex-row  border-b mb-4 pb-2">
            <div className="media-left">
                <NotificationsIcon />
            </div>
            <div className="ml-2">
                <h4 className="media-heading">{notification.title}</h4>
                <p>{notification.message}</p>
                <div className="comment-date">{dayjs(notification.created_time).format('L LT')}</div>
            </div>
        </div>
    );
};
