import React from 'react';
import { Pagination } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { PaginationProps } from 'antd/lib/pagination/Pagination';

export const PaginationComponent = (props: PaginationProps) => {
    const history = useHistory();
    const searchParams = new URLSearchParams(useLocation().search);

    const page = searchParams.get('page');
    const current = page ? parseInt(page) : 1;

    return (
        <div style={{ margin: 'auto', textAlign: 'center' }}>
            <Pagination
                showSizeChanger={false}
                current={current}
                onChange={page => {
                    searchParams.set('page', page.toString());
                    history.replace(`?${searchParams.toString()}`);
                }}
                {...props}
            />
        </div>
    );
};
