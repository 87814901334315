import React from 'react';

const TermsAndConditions = () => {
    return (
        <section>
            <div className="row">
                <div className="container">
                    <h2>Términos y condiciones</h2>
                    <br />
                    <p>
                        Condiciones de servicio de Rush Delivery <br />
                        1. Condiciones <br />
                        Al acceder al sitio web o aplicación movil en https://rushdelivery.app, usted acepta estar
                        sujeto a estos términos de servicio, todas las leyes y regulaciones aplicables, y acepta que es
                        responsable del cumplimiento de las leyes locales aplicables. Si no está de acuerdo con alguno
                        de estos términos, tiene prohibido utilizar o acceder a este sitio. Los materiales contenidos en
                        este sitio web están protegidos por las leyes de derechos de autor y marcas registradas
                        aplicables. <br />
                        2. Licencia de uso <br />
                        Se otorga permiso para descargar temporalmente una copia de los materiales (información o
                        software) en el sitio web de Rush Delivery para visualización transitoria personal y no
                        comercial únicamente. Esta es la concesión de una licencia, no una transferencia de título, y
                        bajo esta licencia no puede: <br />
                        modificar o copiar los materiales; utilizar los materiales para cualquier propósito comercial o
                        para cualquier exhibición pública (comercial o no comercial); <br />
                        intentar descompilar o realizar ingeniería inversa de cualquier software contenido en el sitio
                        web de Rush Delivery; <br />
                        eliminar cualquier copyright u otras anotaciones de propiedad de los materiales; o transferir
                        los materiales a otra persona o "reflejar" los materiales en cualquier otro servidor. Esta
                        licencia terminará automáticamente si viola cualquiera de estas restricciones y Wilian Tapia
                        puede cancelarla en cualquier momento. Al terminar su visualización de estos materiales o al
                        finalizar esta licencia, debe destruir cualquier material descargado en su posesión, ya sea en
                        formato electrónico o impreso. <br />
                        3. Descargo de responsabilidad <br />
                        Los materiales del sitio web de Rush Delivery se proporcionan "tal cual". Rush Delivery no
                        ofrece ninguna garantía, expresa o implícita, y por la presente renuncia y niega todas las demás
                        garantías, incluidas, entre otras, las garantías implícitas o condiciones de comerciabilidad,
                        idoneidad para un propósito particular o no infracción de la propiedad intelectual u otra
                        violación de derechos. Además, Rush Delivery no garantiza ni hace ninguna declaración con
                        respecto a la precisión, los resultados probables o la confiabilidad del uso de los materiales
                        en su sitio web o en relación con dichos materiales o en cualquier sitio vinculado a este sitio.{' '}
                        <br />
                        4. Limitaciones <br />
                        En ningún caso Rush Delivery o sus proveedores serán responsables de ningún daño (incluidos,
                        entre otros, daños por pérdida de datos o ganancias, o debido a la interrupción del negocio) que
                        surjan del uso o la imposibilidad de usar los materiales en el sitio web de Rush Delivery.
                        incluso si Rush Delivery o un representante autorizado de Rush Delivery han sido notificados
                        verbalmente o por escrito de la posibilidad de tal daño. Debido a que algunas jurisdicciones no
                        permiten limitaciones sobre garantías implícitas o limitaciones de responsabilidad por daños
                        consecuentes o incidentales, estas limitaciones pueden no aplicarse en su caso. <br />
                        5. Precisión de los materiales <br />
                        Los materiales que aparecen en el sitio web de Rush Delivery pueden incluir errores técnicos,
                        tipográficos o fotográficos. Rush Delivery no garantiza que ninguno de los materiales de su
                        sitio web sea preciso, completo o actual. Rush Delivery puede realizar cambios en los materiales
                        contenidos en su sitio web en cualquier momento sin previo aviso. Sin embargo, Rush Delivery no
                        se compromete a actualizar los materiales. <br />
                        6. Enlaces <br />
                        Rush Delivery no ha revisado todos los sitios vinculados a su sitio web y no es responsable del
                        contenido de dichos sitios vinculados. La inclusión de cualquier enlace no implica la aprobación
                        del sitio por parte de Rush Delivery. El uso de cualquier sitio web vinculado es por cuenta y
                        riesgo del usuario. <br />
                        7. Modificaciones <br />
                        Rush Delivery puede revisar estos términos de servicio para su sitio web en cualquier momento
                        sin previo aviso. Al utilizar este sitio web, acepta regirse por la versión actual de estos
                        términos de servicio. <br />
                        8. Ley aplicable <br />
                        Estos términos y condiciones se rigen e interpretan de acuerdo con las leyes de Ecuador y usted
                        se somete irrevocablemente a la jurisdicción exclusiva de los tribunales de ese Estado o
                        ubicación. <br /> <br />
                    </p>
                </div>
            </div>
        </section>
    );
};

export default TermsAndConditions;
