import { action, observable, runInAction } from 'mobx';
import { get } from '../../utils/request';
import { OrderProps } from '../../types/types.ds';

export interface OrderReportProps {
    date: string;
    owner: string;
    count: number;
    amount: number;
    commission: number;
}

export interface IDriverCommissions {
    delivery_driver_first_name: string;
    delivery_driver_last_name: string;
    orders_count: number;
    shipping_total: string;
    shipping_count_unknown: number;
    commission_20_percent: number;
    commission_15_percent: number;
    commission_10_percent: number;
}

export interface IDeliveryTimes {
    order: number;
    store: string;
    driver: Driver;
    address: string;
    'confirmed-time': string;
    'pickup-time': string;
    'delivered-time': string;
}

export enum Driver {
    Ariel = 'Ariel',
    Christian = 'Christian',
    Erick = 'Erick',
    Sergio = 'Sergio',
}

class Order {
    @observable loading = false;
    @observable orders: Array<OrderProps> = [];
    @observable ordersCommissions: Array<OrderReportProps> = [];
    @observable commissionsByRushers: Array<IDriverCommissions> = [];
    @observable deliveryTimes: Array<IDeliveryTimes> = [];

    @action getOrders(params: any) {
        this.loading = true;
        get('/reports/orders/', params)
            .then(r => {
                this.orders = r.data;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    @action getReportAll(params: any) {
        this.loading = true;
        get('/reports/orders/all/', params)
            .then(r => {
                this.ordersCommissions = r.data;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }
    @action getReportRushers(params: any) {
        this.loading = true;
        get('/reports/orders/drivers-commission-report/', params)
            .then(r => {
                this.commissionsByRushers = r.data;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    @action getReportDeliveryTimes(params: any) {
        this.loading = true;
        get('/reports/orders/time-report/', params)
            .then(r => {
                this.deliveryTimes = r.data;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }
}

export const OrdersAdminStore = new Order();
