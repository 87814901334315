import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { message, PageHeader } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ProductAdminStore } from '../../../stores/Admin/ProductAdminStore';
import { ProductAddForm } from '../../../components/products/product';
import { CategoryAdminStore } from '../../../stores/Admin/CategoryAdminStore';
import { Helmet } from 'react-helmet';
import { UserStore } from '../../../stores/UserStore';

const ProductNew = observer(() => {
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        CategoryAdminStore.getCategories();
    }, []);

    const handleSave = async (values: any) => {
        setLoading(true);

        await ProductAdminStore.createProduct(values)
            .then(response => {
                history.push(`/admin/product/${response.data.uid}/edit/`);
                setLoading(true);
            })
            .catch(err => {
                setLoading(true);
                message.error(err?.response?.data);
            });
    };

    const categories = UserStore.user?.is_staff
        ? CategoryAdminStore.categories
        : CategoryAdminStore.categories.filter(c => c.parent == UserStore.user?.category_related?.uid);

    return (
        <div className="container margin_30_20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Agregar Producto</title>
            </Helmet>
            <PageHeader
                style={{
                    marginTop: 20,
                }}
                onBack={() => history.goBack()}
                title={<div className={'capitalizeert'}>Regresar</div>}
            />
            <ProductAddForm categories={categories} handleSave={handleSave} />
        </div>
    );
});

export default ProductNew;
