import React, { useCallback, useState } from 'react';
import '../../assets/css/template/detail-page.css';
import { observer } from 'mobx-react';
import { UserStore } from '../../stores/UserStore';
import { CartStore } from '../../stores/CartStore';
import { OrderStore } from '../../stores/OrderStore';
import { Loading } from '../../components/loading/loading';
import { Affix, Button, Input, PageHeader, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import Toast from '../../components/Toast/toast';
import { LocationPicker } from '../../components/location';
import { CartItemCard } from '../../components/cards';
import TextArea from '../../components/TextArea';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

const Checkout = () => {
    const [isGettingLocation, setIsGettingLocation] = useState<boolean>(true);

    const [sendingOrder, setSendingOrder] = useState(false);
    const [notes, setNotes] = useState<string>('');

    const history = useHistory();

    const getShippingCost = useCallback(
        _.debounce((lat, lng) => CartStore.getShippingCost(lat, lng), 900),
        []
    );

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    CartStore.setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setIsGettingLocation(false);
                },
                () => {
                    setIsGettingLocation(false);
                }
            );
        }
    }, []);

    const placeOrder = async () => {
        if (!CartStore.location?.longitude || !CartStore.location?.latitude) {
            Toast.show('No pudimos obtener tu ubicación automatiamtente, por favor selecciona tu ubicación extr', {
                type: 'error',
            });
            return;
        }
        const { latitude, longitude } = CartStore.location;

        const payload: any = {
            shipping_street: street,
            shipping_city: city,
            first_name: first_name,
            last_name: last_name,
            phone: phone,
            owner: owner ? owner.uid : undefined,
            shipping_cost: CartStore.shipping_cost,
            notes: notes,
            source: 'website',
            order_items: CartStore.line_items.map(cart_item => {
                return {
                    product: cart_item.product.uid,
                    product_name: cart_item.product.name,
                    extras: cart_item.extras_list,
                    quantity: cart_item.quantity,
                    unit_price: parseFloat(cart_item.product.price).toFixed(2),
                    total_price: cart_item.total?.toFixed(2),
                    special_instructions: cart_item.special_instructions,
                };
            }),
        };

        payload['location'] = `POINT(${longitude} ${latitude})`;

        // payload['coupons'] = CartStore.coupons.map(c => c.uid);

        setSendingOrder(true);

        OrderStore.placeOrder(payload)
            .then(r => {
                Toast.sweet('Su orden fue enviada correctamente!', {
                    type: 'success',
                });
                OrderStore.getOrders();

                history.push('/orders/');
            })
            .catch(error => {
                let message = 'Algo salió mal, por favor intenta nuevamente';
                if (error?.response?.status === 400) {
                    message = JSON.stringify(error.response?.data);
                }
                //@ts-ignore
                alert(message);
            })
            .finally(() => {
                setSendingOrder(false);
            });
    };

    if (!UserStore.user) {
        return <Loading />;
    }

    const { first_name, last_name, city, street, phone } = UserStore.user;
    const owner = CartStore.store;

    if (!owner) {
        history.push('/');
    }

    return (
        <>
            <div className="">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Checkout</title>
                </Helmet>

                <PageHeader
                    style={{
                        marginTop: 20,
                    }}
                    onBack={() => history.goBack()}
                    title={<div className={'capitalizeert'}>Regresar</div>}
                />
                <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-2/5">
                        <div className={'flex flex-col md:flex-row'}>
                            <div className="w-full md:w-1/2">
                                <label>Nombre</label>
                                <input
                                    className="form-control"
                                    placeholder="Nombre Completo"
                                    value={first_name}
                                    onChange={e => UserStore.setUserProperty('first_name', e.target.value)}
                                />
                            </div>
                            <div className="w-full md:w-1/2">
                                <label>Apellido</label>
                                <input
                                    className="form-control"
                                    placeholder="Nombre Completo"
                                    value={last_name}
                                    onChange={e => UserStore.setUserProperty('last_name', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-group">
                                <label>Phone</label>
                                <input
                                    className="form-control"
                                    placeholder="Celular"
                                    value={phone}
                                    onChange={e => UserStore.setUserProperty('phone', e.target.value)}
                                />
                            </div>
                        </div>

                        <div className={'flex flex-col md:flex-row'}>
                            <div className="w-full md:w-1/2">
                                <label>Dirección</label>
                                <input
                                    className="form-control"
                                    placeholder="Direccion"
                                    value={street}
                                    onChange={e => UserStore.setUserProperty('street', e.target.value)}
                                />
                            </div>

                            <div className="w-full md:w-1/2">
                                <div className="form-group">
                                    <label>Ciudad</label>
                                    <input
                                        className="form-control"
                                        placeholder="Ciudad"
                                        value={city}
                                        onChange={e => UserStore.setUserProperty('city', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-group">
                                <label>Tu Ubicación Exacta</label>
                                {!isGettingLocation ? (
                                    <LocationPicker
                                        lat={CartStore.location.latitude}
                                        lng={CartStore.location.longitude}
                                        onChange={({ lat, lng }) => {
                                            CartStore.setLocation({
                                                longitude: lng,
                                                latitude: lat,
                                            });
                                            getShippingCost(lat, lng);
                                        }}
                                    />
                                ) : (
                                    <div>
                                        <Spin tip={'Obteniendo tu ubicación..'} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 p-4">
                        <div className="form-group">
                            <div className="form-group">
                                <label className="font-bold text-xl	">Items</label>
                                <ul className="clearfix">
                                    {CartStore.line_items.map(cart_item => {
                                        return (
                                            <CartItemCard
                                                key={cart_item.key}
                                                cart_item={cart_item}
                                                onRemove={() => {
                                                    CartStore.removeToCart(cart_item.key);
                                                }}
                                            />
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className={'flex flex-col justify-between'}>
                            <div className="flex flex-row justify-between  mb-1">
                                <div className="font-bold">Subtotal</div>
                                <div className="summary-value">$${CartStore.subtotal.toFixed(2)}</div>
                            </div>

                            <div className="flex flex-row justify-between  mt-2">
                                <div className="font-bold">Envio</div>
                                <div className="summary-value">
                                    ${CartStore.shipping_cost ? CartStore.shipping_cost.toFixed(2) : 'Por definir'}
                                </div>
                            </div>

                            <div
                                className="flex flex-row justify-between  mt-2 pt-4"
                                style={{
                                    borderTop: 'solid 1px #d9d9d9',
                                }}
                            >
                                <div className="font-bold text-xl">Total</div>
                                <div className="font-bold text-xl">${CartStore.total.toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <div className="form-group">
                                <label>Notas adicionales</label>
                                <TextArea placeHolder="Notas" value={notes} onChange={v => setNotes(v)} />
                            </div>
                        </div>

                        <div className="mt-4">
                            <Button
                                type={'primary'}
                                loading={sendingOrder || isGettingLocation}
                                onClick={placeOrder}
                                style={{
                                    width: '100%',
                                    padding: '22px 0px',
                                    borderRadius: '25px',
                                    fontWeight: 'bold',
                                    fontSize: '17px',
                                    display: 'flex',
                                    flexDirection: 'revert',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                Ordenar ${CartStore.total.toFixed(2)}
                            </Button>
                        </div>

                        <div className="text-center p-2">
                            <Button type="link" onClick={() => history.goBack()}>
                                Regresar
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(Checkout);
