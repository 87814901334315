import { action, observable, runInAction } from 'mobx';
import { get, post } from '../utils/request';
import { CitiesProps, OwnerProps, SponsorsProps, UserProps } from '../types/types.ds';
import { CategoryStore } from './CategoryStore';
import axios, { CancelTokenSource } from 'axios';

class Company {
    private cancelRequest: any;

    @observable loading = true;
    @observable companies: Array<OwnerProps> = [];
    @observable company?: OwnerProps;
    @observable sponsors: Array<SponsorsProps> = [];
    @observable current?: UserProps;
    @observable cities: Array<CitiesProps> = [];

    @action getCompanies(params = {}) {
        if (this.cancelRequest) {
            this.cancelRequest.cancel('cancelled by other request');
        }

        this.cancelRequest = axios.CancelToken.source();

        this.loading = true;

        const payload: any = {
            ...params,
        };

        if (CategoryStore.home_category_filter?.uid) {
            payload['category'] = CategoryStore.home_category_filter?.uid;
        } else if (CategoryStore.default_category?.uid) {
            payload['category'] = CategoryStore.default_category?.uid;
        }

        return get('/companies/', payload, this.cancelRequest)
            .then(response => {
                this.companies = response.data.results.map((item: UserProps) => {
                    return {
                        title: item.company,
                        ...item,
                    };
                });
                runInAction(() => {
                    this.loading = false;
                });
            })
            .catch(err => console.log(err));
    }

    //Todo review this any type
    @action setReviews(uid: string, reviews: any) {
        this.companies = this.companies.map(c => {
            if (c.uid === uid) {
                return {
                    ...c,
                    reviews: reviews,
                };
            }
            return c;
        });
    }

    @action
    async getCompanyById(uid: string, cancelRequest: CancelTokenSource) {
        this.loading = true;
        const response = await get(`/companies/${uid}/`, cancelRequest);
        this.company = response.data;
        this.loading = false;
    }

    @action getSubcategoriesByCompany(uid: string, cancelRequest: CancelTokenSource) {
        const params = {
            owner: uid,
        };
        return get(`/companies/${uid}/subcategories/`, params, cancelRequest);
    }

    @action postReview(uid: string, score: number, comments: string) {
        return post(`/companies/${uid}/review/`, {
            score: score,
            comments: comments,
        });
    }

    @action getSponsors() {
        get(`/sponsors/`)
            .then(res => {
                this.sponsors = res.data.results;
            })
            .catch(err => {
                console.log(err);
            });
    }

    @action setCurrent(company: UserProps) {
        this.current = company;
    }
    @action setCompany(company: OwnerProps) {
        this.company = company;
    }

    @action getCities() {
        return get('/cities/').then(r => {
            this.cities = r.data.results;
        });
    }
}

export const CompanyStore = new Company();
