import { ExtraListProps } from '../../../../types/types.ds';
import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { ExtraEditForm } from '../../../../components/products/extras';
import { Tag, Divider } from 'antd';

const ExtraItem = (props: { item: ExtraListProps; loadProduct: () => void }) => {
    const [editing, setEditing] = useState(false);
    const { item, loadProduct } = props;
    if (!editing) {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row">
                    <div className={'text-lg'}>{item.name}</div>
                    <div
                        className={'font-thin ml-2'}
                        style={{
                            color: item.required ? 'red' : 'green',
                        }}
                    >
                        ({item.required ? 'Obligatorio' : 'Opcional'})
                    </div>
                    {!item.product && (
                        <div className={'ml-2'}>
                            <Tag
                                color="gold"
                                title={
                                    'Los cambios en esta variacion se reflejan en todos los productos que esten dentro de las categorias seleccionadas'
                                }
                            >
                                Global
                            </Tag>
                        </div>
                    )}

                    <div className={'underline cursor-pointer text-sm ml-2'} onClick={() => setEditing(true)}>
                        <EditOutlined />
                    </div>
                </div>
                <div className={'flex flex-col'}>
                    <div className={'font-thin'}>{`Los clientes pueden elegir ${
                        item.multiple ? 'varias' : '1'
                    }  opciones`}</div>
                    {item.multiple && item.min > 0 && (
                        <div
                            className={'font-thin'}
                        >{`Los clientes tienen que elegir al menos ${item.min} opciones`}</div>
                    )}
                    {item.multiple && item.max > 0 && (
                        <div className={'font-thin'}>{`Los clientes no pueden elegir más de ${item.max} opciones`}</div>
                    )}
                    {item.pricing == 'replace' && (
                        <div className={'font-thin'}>{`Cada una de las opciones reemplazan el precio base`}</div>
                    )}
                    {item.pricing == 'add' && (
                        <div className={'font-thin'}>{`Cada una de las opciones se suman al precio base`}</div>
                    )}

                    {item.categories_related.length > 0 && (
                        <div className={'font-thin'}>
                            Applica para las categorias:
                            {item.categories_related
                                ?.map(c => {
                                    return c.name;
                                })
                                .toString()}
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return (
        <ExtraEditForm
            item={item}
            onUpdated={() => {
                setEditing(false);
                loadProduct();
            }}
        />
    );
};

export default ExtraItem;
