import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { Button, Form, Input, InputNumber, message, PageHeader } from 'antd';
import { UserStore } from '../../stores/UserStore';
import { Loading } from '../../components/loading/loading';
import { CompanyStore } from '../../stores/Admin/CompanyAdminStores';
import { OwnerProps } from '../../types/types.ds';
import { CategoriesPicker } from '../../components/dropdowns';
import { ImagePicker } from '../../components/upload/image_picker';
import { Helmet } from 'react-helmet';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

export const AccountEdit = observer(() => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    //@ts-ignore
    const company: OwnerProps = UserStore.user;

    if (!company) {
        return <Loading />;
    }

    return (
        <div
            style={{
                margin: 'auto',
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tu Cuenta</title>
            </Helmet>
            <PageHeader
                style={{
                    marginTop: 20,
                }}
                onBack={() => history.goBack()}
                title={<div className={'capitalizeert'}>Regresar</div>}
            />
            <Form
                {...layout}
                onFinish={values => {
                    const payload = {
                        ...values,
                        category: values?.category?.key,
                    };

                    if (values.location?.lat) {
                        payload['location'] = `POINT(${values.location?.lng} ${values.location?.lat})`;
                    }

                    payload['is_seller'] = true;

                    setLoading(true);

                    CompanyStore.updateAccount(payload)
                        .then(res => {
                            setLoading(false);
                            history.push('/admin/my-account/');
                        })
                        .catch(err => {
                            message.error(JSON.stringify(err?.response?.data));
                            setLoading(false);
                        });
                }}
            >
                <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 p-4">
                        <h4 className={'font-bold text-lg'}>Información de la Tienda</h4>
                        <Form.Item
                            label={'Nombre'}
                            name="company"
                            initialValue={company.company}
                            rules={[
                                { max: 30, message: 'El nombre no debe contener más de 30 caracteres' },
                                { required: true, message: 'Requerido' },
                            ]}
                        >
                            <Input placeholder={'Nombre'} />
                        </Form.Item>

                        <Form.Item
                            label={'Dirección'}
                            name="street"
                            initialValue={company.street}
                            rules={[{ required: true, message: 'Requerido' }]}
                        >
                            <Input placeholder={'Dirección'} />
                        </Form.Item>

                        <Form.Item
                            label={'Ciudad'}
                            name="city"
                            initialValue={company.city}
                            rules={[{ required: true, message: 'Requerido' }]}
                        >
                            <Input placeholder={'Dirección'} />
                        </Form.Item>
                    </div>

                    <div className="w-full md:w-1/2 p-4">
                        <h4 className={'font-bold text-lg'}>Información del Dueño</h4>

                        <Form.Item
                            label={'Nombre'}
                            name="first_name"
                            initialValue={company.first_name}
                            rules={[{ required: true, message: 'Requerido' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={'Apellido'}
                            name="last_name"
                            initialValue={company.last_name}
                            rules={[{ required: true, message: 'Requerido' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={'Teléfono'}
                            name="phone"
                            initialValue={company.phone}
                            rules={[
                                { min: 7, message: 'Ingrese un número de teléfono válido' },
                                { max: 10, message: 'Ingrese un número de teléfono válido' },
                                { required: true, message: 'Requerido' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row">
                    <div className={'w-full md:w-1/2 p-4'}>
                        <Form.Item
                            label={'Imagen de Cabecera'}
                            name="feature_graphic"
                            help={'Esta imagen será mostrada en cuando el establecimiento es listado'}
                            rules={[{ required: false, message: 'Requerido' }]}
                        >
                            <ImagePicker aspect={1.77} />
                        </Form.Item>
                        {company.feature_graphic && (
                            <div className={'mb-4'}>
                                <img src={company.feature_graphic} alt={company.company} style={{ width: 64 }} />
                            </div>
                        )}
                    </div>

                    <div className={'w-full md:w-1/2 p-4'}>
                        <Form.Item
                            label={'Logo'}
                            name="thumbnail"
                            help={'¿No tienes un logo? contáctanos ahora y te ayudaremos con uno.'}
                            rules={[{ required: false, message: 'Requerido' }]}
                        >
                            <ImagePicker aspect={1} />
                        </Form.Item>
                        {company.thumbnail && (
                            <div className={'mb-4'}>
                                <img src={company.thumbnail} alt="avatar" style={{ width: 64 }} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="text-center">
                    <Button type="primary" size="large" loading={loading} htmlType="submit">
                        Guardar Cambios
                    </Button>
                </div>
            </Form>
        </div>
    );
});
