import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { UserStore } from '../../stores/UserStore';
import { AuthenticationStore } from '../../stores/AuthenticationStore';
import { useHistory } from 'react-router-dom';
import StoreIcon from '@mui/icons-material/Store';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SignInButton from '../../components/buttons/SignInButton';
import ScheduleIcon from '@mui/icons-material/Schedule';
import NotificationButton from '../../components/notifications/Notification';
import NotificationsList from '../../containers/notifications/NotificationList';
import { Modal } from 'antd';
import { useState } from 'react';
import MessageIconCount from '../../components/notifications/MessageIconCount';
import MessagesList from '../../containers/messages/MessagesList';
import { NotificationStore } from '../../stores/NotificationStore';
import { ChatStore, RoomProps } from '../../stores/ChatStore';
import { Chat } from '../../components/Chat/Chat';
import { OrderStore } from '../../stores/OrderStore';

interface Props {
    userId: string | null;
    firstName: string | undefined;
    isAuthenticated: boolean;
    isAPartner: boolean;
    isStaff: boolean;
    notificationCount: number;
    messagesCount: number;
    ordersCount: number;
    onClick?: () => void;
}

const MenuLinkItem = (props: {
    count?: number;
    label: string;
    icon: React.ReactElement;
    link: string;
    onClick?: () => void;
}) => {
    const history = useHistory();
    return (
        <div className="flex -flex-row mx-2" onClick={props?.onClick}>
            {!!props.count && props.count > 0 && (
                <div
                    style={{
                        backgroundColor: 'green',
                        width: 25,
                        height: 25,
                        borderRadius: '50%',
                        color: '#fff',
                        position: 'relative',
                        top: 0,
                        left: 0,
                        marginRight: '1px',
                    }}
                >
                    {props.count}
                </div>
            )}
            {/*{props.icon}*/}

            <Typography className="cursor-pointer" onClick={() => history.push(props.link)}>
                {props.label}
            </Typography>
        </div>
    );
};
export default function AccountMenu(props: Props) {
    const history = useHistory();
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const [showMessages, setShowMessages] = useState<boolean>(false);
    const [room, setRoom] = useState<RoomProps | null>(null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <div className={'flex flex-row hidden md:flex mr-4'}>
                    <MenuLinkItem
                        label={'Tiendas'}
                        icon={
                            <StoreIcon
                                style={{
                                    fontSize: 22,
                                }}
                            />
                        }
                        link="/"
                    />
                    <MenuLinkItem
                        count={props.ordersCount}
                        label={'Pedidos'}
                        icon={
                            <DeliveryDiningIcon
                                style={{
                                    fontSize: 22,
                                }}
                            />
                        }
                        link="/orders/"
                        // onClick={() => {
                        //     OrderStore.getOrders();
                        // }}
                    />

                    {props.isAPartner && (
                        <>
                            <MenuLinkItem
                                label={'Productos'}
                                icon={
                                    <FactCheckOutlinedIcon
                                        style={{
                                            fontSize: 22,
                                        }}
                                    />
                                }
                                link="/admin/products/"
                            />

                            <MenuLinkItem
                                label={'Categorias'}
                                icon={
                                    <FactCheckOutlinedIcon
                                        style={{
                                            fontSize: 22,
                                        }}
                                    />
                                }
                                link="/admin/categories/"
                            />

                            <MenuLinkItem
                                label={'Variaciones Globales'}
                                icon={
                                    <FactCheckOutlinedIcon
                                        style={{
                                            fontSize: 22,
                                        }}
                                    />
                                }
                                link="/admin/extras/"
                            />

                            <MenuLinkItem
                                label={'Horario'}
                                icon={
                                    <ScheduleIcon
                                        style={{
                                            fontSize: 22,
                                        }}
                                    />
                                }
                                link="/admin/open-hours/"
                            />

                            <MenuLinkItem
                                label={'Reportes Ventas'}
                                icon={
                                    <ScheduleIcon
                                        style={{
                                            fontSize: 22,
                                        }}
                                    />
                                }
                                link="/admin/reports/"
                            />

                            {props.isStaff && (
                                <MenuLinkItem
                                    label={'Reportes Clientes'}
                                    icon={
                                        <ScheduleIcon
                                            style={{
                                                fontSize: 22,
                                            }}
                                        />
                                    }
                                    link="/admin/reports/customers"
                                />
                            )}
                            {props.isStaff && (
                                <MenuLinkItem
                                    label={'Reportes Comisiones'}
                                    icon={
                                        <ScheduleIcon
                                            style={{
                                                fontSize: 22,
                                            }}
                                        />
                                    }
                                    link="/admin/reports/commission/rushers"
                                />
                            )}
                            {props.isStaff && (
                                <MenuLinkItem
                                    label={'Reportes Delivery Times'}
                                    icon={
                                        <ScheduleIcon
                                            style={{
                                                fontSize: 22,
                                            }}
                                        />
                                    }
                                    link="/admin/reports/delivery-times"
                                />
                            )}

                            {props.isStaff && (
                                <MenuLinkItem
                                    label={'Todas las Ordenes'}
                                    icon={
                                        <ScheduleIcon
                                            style={{
                                                fontSize: 22,
                                            }}
                                        />
                                    }
                                    link="/admin/reports/orders"
                                />
                            )}
                            {props.isStaff && (
                                <MenuLinkItem
                                    label={'Zonas'}
                                    icon={
                                        <DeliveryDiningIcon
                                            style={{
                                                fontSize: 22,
                                            }}
                                        />
                                    }
                                    link="/admin/delivery-zones/"
                                />
                            )}
                        </>
                    )}
                </div>

                {props.isAuthenticated && (
                    <div className={'flex'}>
                        <NotificationButton
                            count={props.notificationCount}
                            onClick={() => {
                                setShowNotifications(true);
                            }}
                        />
                        <MessageIconCount
                            count={props.messagesCount}
                            onClick={() => {
                                ChatStore.getRooms();
                                setShowMessages(true);
                            }}
                        />
                        <Modal
                            visible={showNotifications}
                            bodyStyle={{
                                height: 450,
                            }}
                            footer={[]}
                            onCancel={() => {
                                NotificationStore.getNotifications();
                                setShowNotifications(false);
                            }}
                        >
                            <div
                                style={{
                                    overflowY: 'auto',
                                }}
                            >
                                <NotificationsList />
                            </div>
                        </Modal>

                        <Modal
                            visible={showMessages}
                            bodyStyle={{
                                height: 450,
                            }}
                            footer={[]}
                            onCancel={() => {
                                ChatStore.getRooms();
                                setShowMessages(false);
                            }}
                        >
                            <div
                                style={{
                                    overflowY: 'auto',
                                }}
                            >
                                <MessagesList
                                    onClickItem={room => {
                                        setRoom(room);
                                    }}
                                />
                            </div>
                        </Modal>

                        {room && props.userId && (
                            <Chat currentUserId={props.userId} room={room} onClose={() => setRoom(null)} />
                        )}
                    </div>
                )}
                {props.isAuthenticated ? (
                    <Tooltip title="Cuenta">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar src={UserStore.thumbnail ?? ''} sx={{ width: 32, height: 32 }}>
                                {props.firstName?.charAt(0) || 'U'}
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                ) : (
                    <SignInButton
                        handleClick={() => {
                            history.push('/login/');
                        }}
                    />
                )}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => {
                        if (props.isAPartner) {
                            history.push('/admin/my-account/');
                        }
                    }}
                >
                    <Avatar /> {UserStore.fullName}
                </MenuItem>
                {/*<MenuItem>*/}
                {/*    <Avatar /> My account*/}
                {/*</MenuItem>*/}
                {/*<Divider />*/}
                {/*<MenuItem>*/}
                {/*    <ListItemIcon>*/}
                {/*        <PersonAdd fontSize="small" />*/}
                {/*    </ListItemIcon>*/}
                {/*    Add another account*/}
                {/*</MenuItem>*/}
                {/*<MenuItem>*/}
                {/*    <ListItemIcon>*/}
                {/*        <Settings fontSize="small" />*/}
                {/*    </ListItemIcon>*/}
                {/*    Settings*/}
                {/*</MenuItem>*/}
                <MenuItem
                    onClick={() => {
                        AuthenticationStore.logout();
                        history.push('/');
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Salir
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
