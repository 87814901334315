import React from 'react';

interface Props {
    handleClick: () => void;
}

const SignInButton = (props: Props) => {
    return (
        <div
            className={'cursor-pointer'}
            style={{
                backgroundColor: 'rgb(203 203 203 / 55%)',
                width: '103px',
                borderRadius: '19px',
                height: '40px',
                display: 'flex',
                placeContent: 'center',
                alignItems: 'center',
                marginTop: 'auto',
                marginBottom: 'auto',
                color: '#595959',
                fontWeight: 'bold',
            }}
            onClick={props.handleClick}
        >
            Ingresar
        </div>
    );
};

export default SignInButton;
