import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Switch } from 'antd';
import { CategoryProps } from '../../types/types.ds';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

interface Props {
    category?: CategoryProps | null;
    handleSave: (values: any) => void;
}

const CategoryAddForm = ({ category, handleSave }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <React.Fragment>
            <Form
                {...layout}
                onFinish={values => {
                    const payload = {
                        ...values,
                    };

                    handleSave(payload);
                }}
                initialValues={{
                    name: category?.name,
                    order: category?.order,
                }}
            >
                <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="order" label="Orden" rules={[{ required: false }]}>
                    <InputNumber />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" className="s-btn btn-link " loading={isLoading}>
                        <i className="fas fa-search" /> &nbsp; Guardar y Continuar
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};

export default CategoryAddForm;
