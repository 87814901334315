import { action, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../utils/request';
import { CategoryProps, ExtraListProps, OptionsProps, ProductProps } from '../../types/types.ds';
class Product {
    @observable loading_search = false;

    @observable search_products: ProductProps[] = [];
    @observable total_records = 0;
    @observable extras: ExtraListProps[] = [];
    @observable options: OptionsProps[] = [];
    @observable next_page_for_search_products = null;

    @action getProductsById(uid: string) {
        return get(`/products/${uid}/`);
    }

    @action searchProducts(params: any) {
        const payload = {
            ...params,
        };

        if (!params?.page || params?.page < 2) {
            this.loading_search = true;
        }

        return get('/products/?cache=false', payload)
            .then(response => {
                const search_products = response.data.results.map((item: any) => {
                    return {
                        key: item.uid,
                        ...item,
                        discountPercentage: 0,
                    };
                });

                runInAction(() => {
                    if (params.page && params?.page > 1) {
                        this.search_products = [...this.search_products, ...search_products];
                    } else {
                        this.search_products = [...search_products];
                    }
                    this.next_page_for_search_products = response.data.links.next;
                    this.total_records = response.data.count;
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.next_page_for_search_products = null;
                    this.total_records = 0;
                });
            })
            .finally(() => (this.loading_search = false));
    }

    @action createProduct(payload: any) {
        const formData = new FormData();

        for (const key in payload) {
            if (payload[key] !== undefined) {
                formData.append(key, payload[key]);
            }
        }
        return post('/products/', formData);
    }

    @action updateProduct(uid: string, payload: any) {
        this.search_products = this.search_products.map(product => {
            if (product.uid === uid) {
                return {
                    ...product,
                    ...payload,
                };
            }
            return product;
        });

        const formData = new FormData();

        for (const key in payload) {
            if (payload[key] !== undefined) {
                formData.append(key, payload[key]);
            }
        }
        return patch(`/products/${uid}/`, formData);
    }

    @action deleteProduct(uid: string) {
        return remove(`/products/${uid}/`)
            .then(response => {})
            .catch(err => console.error(err));
    }

    @action getExtras() {
        get('/extras/')
            .then(response => {
                this.extras = response.data.results;
            })
            .catch(err => console.log(err));
    }

    @action AddExtra(payload: any) {
        return post('/extras/', payload)
            .then(response => {
                this.extras.push(response.data);
            })
            .catch(err => console.log(err));
    }

    @action updateExtra(uid: string, payload: any) {
        return patch(`/extras/${uid}/`, payload)
            .then(response => {})
            .catch(err => console.log(err));
    }

    @action removeExtra(uid: string) {
        return remove(`/extras/${uid}/`)
            .then(response => {})
            .catch(err => console.log(err));
    }

    @action AddOption(payload: any) {
        return post('/options/', payload)
            .then(response => {})
            .catch(err => console.log(err));
    }

    @action updateOption(uid: string, payload: any) {
        return patch(`/options/${uid}/`, payload)
            .then(response => {})
            .catch(err => console.log(err));
    }

    @action removeOption(uid: string) {
        return remove(`/options/${uid}/`)
            .then(response => {})
            .catch(err => console.log(err));
    }

    @action addProductGallery(payload: any) {
        const formData = new FormData();

        for (const key in payload) {
            if (payload[key] !== undefined) {
                formData.append(key, payload[key]);
            }
        }
        return post(`/product-gallery/`, formData)
            .then(response => {})
            .catch(err => console.error(err));
    }

    @action deleteProductGallery(uid: string) {
        return remove(`/product-gallery/${uid}/`)
            .then(response => {})
            .catch(err => console.error(err));
    }
}

export const ProductAdminStore = new Product();
