import { action, observable } from 'mobx';
import { OwnerProps } from '../../types/types.ds';
import { get, post, patch } from '../../utils/request';
import { UserStore } from '../UserStore';
class Company {
    @observable isLoading = false;
    @observable companies: OwnerProps[] = [];
    @observable search_results = [];

    @action getCompanies(params = {}) {
        this.isLoading = true;
        get('/companies/', params)
            .then(response => {
                this.companies = response.data.results.map((item: any) => {
                    return {
                        title: item.company,
                        ...item,
                    };
                });
            })
            .catch(err => console.log(err))
            .finally(() => (this.isLoading = false));
    }

    @action
    getCompany(uid: string) {
        return get(`/companies/${uid}/`);
    }

    @action copyVariationsFromAnotherGroup = (product_uid: string, extra_uid: string) => {
        return post(`/products/${product_uid}/copy-variations/`, {
            extra: extra_uid,
        });
    };

    @action updateOpenHours = (uid: string, payload: any) => {
        return patch(`/companies/${uid}/`, payload);
    };
    @action updateAccount = (payload: any) => {
        const formData = new FormData();

        for (const key in payload) {
            //@ts-ignore
            if (payload[key]) {
                //@ts-ignore
                formData.append(key, payload[key]);
            }
        }

        return patch('/auth/user/', formData, true)
            .then(response => {
                UserStore.setUser(response.data);
            })
            .catch(err => {});
    };
}

export const CompanyStore = new Company();
