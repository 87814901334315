import axios, { CancelTokenSource } from 'axios';
import { UserStore } from '../stores/UserStore';
import { BASE_URL } from './settings';

const qs = require('qs');

const getHeaders = async (hasFiles = false) => {
    const headers = hasFiles
        ? {
              'Content-Type': 'multipart/form-data',
          }
        : {
              'Content-Type': 'application/json',
          };

    const token = localStorage.getItem('token');

    if (token) {
        return {
            ...headers,
            Authorization: `Token ${token}`,
        };
    }

    return headers;
};

export const get = async (url: string, params = {}, CancelTokenSource?: CancelTokenSource) => {
    if (UserStore.location) {
        params = {
            ...params,
            lat: UserStore.location.latitude,
            lon: UserStore.location.longitude,
            city: UserStore?.city?.uid,
        };
    }

    console.log(url);

    return axios.get(BASE_URL + url, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        withCredentials: false,
        headers: await getHeaders(),
        cancelToken: CancelTokenSource?.token,
        timeout: 10000,
    });
};

export const post = async (url: string, payload = {}) => {
    return axios.post(BASE_URL + url, payload, {
        headers: await getHeaders(),
        withCredentials: false,
        timeout: 10000,
    });
};

export const put = async (url: string, payload = {}) => {
    console.debug(url);
    return axios.put(BASE_URL + url, payload, {
        headers: await getHeaders(),
        withCredentials: false,
        timeout: 10000,
    });
};

export const patch = async (url: string, payload = {}, hasFiles = false) => {
    console.debug(url);
    return axios.patch(BASE_URL + url, payload, {
        headers: await getHeaders(hasFiles),
        withCredentials: false,
        timeout: 10000,
    });
};

export const remove = async (url: string) => {
    console.debug('delete', url);
    return axios.delete(BASE_URL + url, {
        headers: await getHeaders(),
        withCredentials: false,
        timeout: 10000,
    });
};
