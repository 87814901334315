import React, { useState, useRef, useEffect, useCallback } from 'react';
import { CityStore } from 'stores/CityStore';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Button, Modal as ModalAntd, Popconfirm } from 'antd';
import {
    GoogleMap,
    Polygon,
    Marker,
    InfoBox,
    OverlayView,
    LoadScript,
    useLoadScript,
    DrawingManager,
    useGoogleMap,
} from '@react-google-maps/api';

import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Cloud from '@mui/icons-material/Cloud';
import UndoIcon from '@mui/icons-material/Undo';
import { observer } from 'mobx-react';
import CityPicker from '../../components/dropdowns/CityPicker';
import { CitiesProps, IDeliveryZones } from '../../types/types.ds';
import { message } from 'antd';
import FormDeliveryZone from './FormDeliveryZone';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    p: 4,
};

const REACT_MAPS_KEY = 'AIzaSyDEDxS0tVB-dBKZTmvA4n_TSNCWOFkJ76k';

type IPolygonPath = { lat: any; lng: any }[];

const ViewZone = (props: {
    zone: IDeliveryZones;
    defaultPaths: IPolygonPath;
    onClickSavePath: (paths: IPolygonPath) => Promise<void>;
    onClickUpdate: (zone: IDeliveryZones) => Promise<void>;
    onClickSaveAsNew: (zone: IDeliveryZones) => Promise<void>;
    onClickDelete: () => Promise<void>;
}) => {
    const [editable, setEditable] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showEditZoneModal, setShowEditZoneModal] = useState<boolean>(false);

    const polygonRef = useRef<Polygon>(null);
    const listenersRef = useRef([]);

    //@ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    props.defaultPaths.forEach(coord => {
        //@ts-ignore
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
    });
    const center = bounds.getCenter();

    const onLoad = useCallback(
        polygon => {
            // @ts-ignore
            polygonRef.current = polygon;
            // const path = polygon.getPath();
            // listenersRef.current.push(
            //     path.addListener('set_at', onEdit),
            //     path.addListener('insert_at', onEdit),
            //     path.addListener('remove_at', onEdit)
            // );
        },
        [editable]
    );

    const onUnmount = useCallback(() => {
        // if (polygonRef) polygonRef?.current = null;
    }, []);

    const handleUpdatePaths = () => {
        // @ts-ignore
        const paths = polygonRef?.current.getPath();

        const coords = paths.getArray().map((latLng: any) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });

        props.onClickSavePath(coords).then(() => {
            setShowModal(false);
            setEditable(false);
        });
    };

    return (
        <>
            <Polygon
                ref={polygonRef}
                editable={editable}
                onLoad={onLoad}
                onUnmount={onUnmount}
                path={props.defaultPaths}
                visible
                onDblClick={() => {
                    setEditable(true);
                }}
                onRightClick={() => {
                    setShowModal(true);
                }}
                options={{
                    fillColor: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
                    // fillColor: '#d8d8d8',
                    fillOpacity: 0.4,
                    strokeColor: '#000',
                    strokeOpacity: 0.8,
                    // strokeWeight: 1,
                }}
            />

            <OverlayView
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                position={{ lat: center.lat(), lng: center.lng() }}
            >
                <div style={{ backgroundColor: 'beige', padding: 5 }}>
                    <div
                        style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        {props.zone.name}
                    </div>
                    <div>${props.zone.cost}</div>
                </div>
            </OverlayView>

            <ModalAntd visible={showModal} onCancel={() => setShowModal(false)} footer={null}>
                <MenuList>
                    <MenuItem>
                        <ListItemIcon>
                            <Cloud fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            onClick={() => {
                                setShowEditZoneModal(true);
                                setShowModal(false);
                            }}
                        >
                            Modificar Zona
                        </ListItemText>
                    </MenuItem>

                    <MenuItem>
                        <ListItemIcon>
                            <Cloud fontSize="small" />
                        </ListItemIcon>
                        <ListItemText onClick={handleUpdatePaths}>Guardar Coordenadas</ListItemText>
                    </MenuItem>

                    <MenuItem>
                        <ListItemIcon>
                            <Cloud fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Popconfirm
                                title="Estas seguro que deseas eliminar esta zona?"
                                onConfirm={props.onClickDelete}
                            >
                                <Button type="link">Delete</Button>
                            </Popconfirm>
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </ModalAntd>

            <ModalAntd
                title="Edit Delivery Zone"
                visible={showEditZoneModal}
                onCancel={() => setShowEditZoneModal(false)}
                footer={null}
            >
                <FormDeliveryZone
                    zone={props.zone}
                    handleSave={async values => {
                        await props.onClickUpdate({
                            ...props.zone,
                            ...values,
                        });
                        setShowEditZoneModal(false);
                    }}
                />
            </ModalAntd>
        </>
    );
};

const containerStyle = {
    width: '100%',
    height: '90vh',
};

const RenderMap = ({
    markers,
    city,
    showCityCoordinates,
}: {
    markers: IDeliveryZones[];
    city?: CitiesProps;
    showCityCoordinates: boolean;
}) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: REACT_MAPS_KEY,
        libraries: ['drawing'],
    });

    // Create a event listener scape key to clean up the drawing

    useEffect(() => {
        const handleEsc = (e: any) => {
            if (e.keyCode === 27) {
                setCoordinates([]);
            }
        };

        document.addEventListener('keydown', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const [defaultCenter, setDefaultCenter] = useState({
        lat: -2.893130399444993,
        lng: -78.77150066392298,
    });

    // const map = useGoogleMap();

    const [showSaveZoneModal, setShowSaveZoneModal] = useState<boolean>(false);

    const [zoom, setZoom] = useState<number>(14);

    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        if (city?.poly?.coordinates) {
            // Get center coordinates based on the city
            const coords = city.location?.coordinates;

            if (coords) {
                setDefaultCenter({
                    lat: coords[1],
                    lng: coords[0],
                });
            }
        }
    }, [city]);

    const handleOverlayComplete = (e: any) => {
        const polygon = e.overlay;
        const paths = polygon.getPaths().getArray()[0];
        const coords = paths.getArray().map((latLng: any) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });
        // Make sure the first and last coordinates are the same
        coords.push(coords[0]);
        setCoordinates(coords);

        const exteriorRing = paths.getArray().map((latLng: any) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });
        // Make sure the first and last coordinates are the same
        exteriorRing.push(exteriorRing[0]);
        setShowSaveZoneModal(true);
    };

    const delivery_zones = markers.map(zone => {
        const defaultPaths = zone.poly.coordinates[0].map((lat_lng: any) => {
            return {
                lat: lat_lng[1],
                lng: lat_lng[0],
            };
        });

        return (
            <ViewZone
                key={`zone-${zone.uid}`}
                zone={zone}
                defaultPaths={defaultPaths}
                onClickSavePath={async paths => {
                    await CityStore.updateDeliveryZone(zone.uid, {
                        poly: {
                            type: 'Polygon',
                            coordinates: [
                                paths.map(item => {
                                    return [item.lng, item.lat];
                                }),
                            ],
                        },
                    });
                }}
                onClickUpdate={async values => {
                    await CityStore.updateDeliveryZone(zone.uid, values);
                }}
                onClickSaveAsNew={async values => {
                    await CityStore.createDeliveryZone(values);
                }}
                onClickDelete={async () => {
                    await CityStore.deleteDeliveryZone(zone.uid);
                }}
            />
        );
    });

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps...</div>;

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={defaultCenter}
            zoom={14}
            // onZoomChanged={() => {
            //     alert(1);
            //     alert(map?.getZoom());
            //     // setZoom(zoom);
            // }}
        >
            {delivery_zones}

            <DrawingManager
                onOverlayComplete={handleOverlayComplete}
                options={{
                    drawingControl: true,
                    drawingControlOptions: { position: window.google.maps.ControlPosition.TOP_CENTER },
                }}
            />

            {coordinates.length > 0 && (
                <Polygon
                    paths={coordinates}
                    options={{
                        fillColor: '#00FF00',
                        fillOpacity: 0.4,
                        strokeWeight: 2,
                        clickable: false,
                        editable: true,
                        zIndex: 1,
                    }}
                />
            )}

            {showCityCoordinates && city?.poly?.coordinates && (
                <Polygon
                    paths={city.poly.coordinates[0].map((lat_lng: any) => {
                        return {
                            lat: lat_lng[1],
                            lng: lat_lng[0],
                        };
                    })}
                    options={{
                        fillColor: 'transparent',
                        strokeColor: 'red',
                        fillOpacity: 0.1,
                        strokeWeight: 2,
                        clickable: false,
                        editable: false,
                        zIndex: 1,
                    }}
                />
            )}

            {showSaveZoneModal && (
                <ModalAntd
                    title="Edit Delivery Zone"
                    visible={true}
                    onCancel={() => setShowSaveZoneModal(false)}
                    footer={null}
                >
                    <FormDeliveryZone
                        //@ts-ignore
                        zone={{
                            id: 0,
                            uid: '',
                            name: 'New Zone',
                            cost: '0',
                        }}
                        handleSave={async values => {
                            const payload = {
                                name: values.name,
                                cost: values.cost,
                                poly: {
                                    coordinates: [
                                        coordinates.map((item: any) => {
                                            return [item.lng, item.lat];
                                        }),
                                    ],
                                    type: 'Polygon',
                                },
                                // @ts-ignore
                                city: CityStore.city?.uid,
                            };

                            await CityStore.createDeliveryZone(payload);

                            setCoordinates([]);

                            setShowSaveZoneModal(false);
                        }}
                    />
                </ModalAntd>
            )}
        </GoogleMap>
    );
};

const DeliveryZones = () => {
    const deliveryZones = CityStore.deliveryZones;
    const [showCityCoordinates, setShowCityCoordinates] = useState<boolean>(false);

    return (
        <div>
            <div
                style={{
                    zIndex: 999,
                    position: 'absolute',
                    top: '10%',
                    right: '5%',
                }}
            >
                <CityPicker
                    onChange={city => {
                        if (city?.uid) {
                            CityStore.setCity(city);
                            CityStore.getDeliveryZonesByCity(city.uid);
                        }
                    }}
                />

                <Button
                    style={{
                        width: '100%',
                    }}
                    onClick={() => {
                        setShowCityCoordinates(!showCityCoordinates);
                    }}
                >
                    {showCityCoordinates ? 'Ocultar Cobertura' : 'Mostrar Cobertura'}
                </Button>
            </div>
            <RenderMap markers={deliveryZones} city={CityStore.city} showCityCoordinates={showCityCoordinates} />
        </div>
    );
};

export default observer(DeliveryZones);
