import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, InputNumber, Popconfirm, Space } from 'antd';
import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { ProductAdminStore } from '../../../stores/Admin/ProductAdminStore';
import { OptionsProps } from '../../../types/types.ds';

interface Props {
    in_local?: boolean;
    pricing: 'add' | 'replace';
    delivery?: boolean;
    option: OptionsProps;
    handleChanged: () => void;
}

export const OptionEditForm = ({ option, handleChanged, in_local, ...props }: Props) => {
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    if (isEditing) {
        return (
            <Form
                initialValues={{
                    name: option.name,
                    extra_price: option.extra_price,
                    local_price: option.local_price,
                    original_price: option.original_price,
                    picked: option.picked,
                    order: option.order,
                }}
                onFinish={values => {
                    setLoading(true);

                    ProductAdminStore.updateOption(option.uid, values).then(() => {
                        setLoading(false);
                        setIsEditing(false);
                        handleChanged();
                    });
                }}
                className={'flex flex-row my-4 pb-2'}
                style={{
                    borderBottom: '1px solid #f0f0f0',
                }}
            >
                <Form.Item name={'name'} className={'w-1/6'}>
                    <Input style={{ maxWidth: 200 }} />
                </Form.Item>
                <Form.Item name={'original_price'} className={'w-1/6'}>
                    <InputNumber />
                </Form.Item>
                {props.pricing == 'replace' && (
                    <Form.Item className={'w-1/6'}>
                        <span>--</span>
                    </Form.Item>
                )}
                <div className={'w-1/6'}>
                    <Form.Item name={'picked'} valuePropName={'checked'}>
                        <Checkbox />
                    </Form.Item>
                </div>

                <div className={'w-1/6'}>
                    <Form.Item name={'order'}>
                        <InputNumber />
                    </Form.Item>
                </div>
                <Form.Item className={'w-1/6'}>
                    <Button type={'primary'} loading={loading} htmlType={'submit'}>
                        Guardar Cambios
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    return (
        <div
            className={'flex flex-row my-4 pb-2'}
            style={{
                borderBottom: '1px solid #f0f0f0',
            }}
        >
            <div className={'w-1/6'}>{option.name}</div>
            <div className={'w-1/6'}>{option.original_price}</div>

            {props.pricing == 'replace' && <div className={'w-1/6'}>{option.extra_price}</div>}

            <div className={'w-1/6'}>{option.picked ? 'Sí' : 'No'}</div>
            <div className={'w-1/6'}>{option.order}</div>
            <div className={'w-1/6'}>
                <Space>
                    <EditOutlined
                        onClick={() => {
                            setIsEditing(true);
                        }}
                    />

                    <Popconfirm
                        title="Esta seguro que desea eliminar permanetemente?"
                        onConfirm={() => {
                            ProductAdminStore.removeOption(option.uid).then(() => {
                                handleChanged();
                            });
                        }}
                        okText="Sí, borrar"
                        cancelText="No"
                    >
                        <MinusCircleOutlined />
                    </Popconfirm>
                </Space>
            </div>
        </div>
    );
};

interface OptionFormProps {
    isLoading?: boolean;
    pricing: 'add' | 'replace';
    in_local?: boolean;
    handleSave: (values: any) => void;
}

export const OptionAddForm = ({ isLoading, handleSave, in_local, pricing }: OptionFormProps) => {
    const [form] = Form.useForm();

    return (
        <Form
            className={'flex flex-row'}
            form={form}
            onFinish={values => {
                handleSave(values);
                form.resetFields();
            }}
        >
            <div className={'w-1/6'}>
                <Form.Item name="name" initialValue={''} rules={[{ required: true, message: 'Requerido' }]}>
                    <Input style={{ maxWidth: 300 }} size="small" placeholder={'Opción'} />
                </Form.Item>
            </div>

            <div className={'w-1/6'}>
                <Form.Item name="original_price" rules={[{ required: true }]}>
                    <InputNumber size="small" placeholder="0" />
                </Form.Item>
            </div>

            {in_local && (
                <div className={'w-1/6'}>
                    <Form.Item name="local_price" rules={[{ required: true }]}>
                        <InputNumber size="small" placeholder="0" />
                    </Form.Item>
                </div>
            )}

            {pricing == 'replace' && <div className={'w-1/6'}>--</div>}

            <div className={'w-1/6'}>
                <Form.Item name={'picked'} valuePropName={'checked'}>
                    <Checkbox />
                </Form.Item>
            </div>

            <div className={'w-1/6'}>
                <Form.Item name={'order'}>
                    <InputNumber />
                </Form.Item>
            </div>

            <div className={'w-1/6'}>
                <Form.Item>
                    <Button size="small" type="primary" htmlType="submit" loading={isLoading}>
                        Agregar
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};
