import React from 'react';
import IOS_LOGO from '../../assets/images/ios.svg';

const DownloadAppStore = () => {
    return (
        <div
            style={{
                width: 230,
            }}
        >
            <a href="https://apps.apple.com/app/id1528856307">
                <img src={IOS_LOGO}></img>
            </a>
        </div>
    );
};

export default DownloadAppStore;
