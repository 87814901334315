import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../../components/loading/loading';
import { Helmet } from 'react-helmet';
import { ExtraAdminStore } from '../../../stores/Admin/ExtraAdminStore';
import { CategoryAdminStore } from '../../../stores/Admin/CategoryAdminStore';
import ExtraCard from '../products/components/ExtraCard';
import { UserStore } from '../../../stores/UserStore';

const ExtraEdit = observer(() => {
    const history = useHistory();

    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        CategoryAdminStore.getCategories({
            owner: UserStore.user?.uid,
        });
    }, []);

    //@ts-ignore
    const { uid } = params;

    const loadExtra = () => {
        ExtraAdminStore.getById(uid);
    };

    useEffect(() => {
        loadExtra();
    }, []);

    const item = ExtraAdminStore.extra;

    if (ExtraAdminStore.loading || !item?.uid) {
        return <Loading />;
    }
    return (
        <div className="container margin_30_20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Modificar Variacion</title>
            </Helmet>
            <PageHeader
                style={{
                    marginTop: 20,
                }}
                onBack={() => history.goBack()}
                title={<div className={'capitalizeert'}>Regresar</div>}
            />

            <div className="container">
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Información Básica" key="1">
                        <ExtraCard key={item.uid} item={item} onUpdate={loadExtra} onDelete={() => history.goBack()} />;
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
});

export default ExtraEdit;
