import { action, observable } from 'mobx';
import { get, patch, post, remove } from '../../utils/request';
import { CategoryProps, ExtraListProps } from '../../types/types.ds';

class Category {
    @observable loading = false;
    @observable categories: CategoryProps[] = [];
    @observable category: CategoryProps | null = null;
    @observable extra_list: ExtraListProps[] = [];
    @observable total_records = 0;

    @action getCategories(params = {}) {
        get('/categories/?show-all=true', params)
            .then(response => {
                this.categories = response.data.results;
            })
            .catch(err => console.log(err));
    }

    @action async getById(uid: string | null) {
        this.loading = true;
        await get(`/categories/${uid}/?show-all=true`)
            .then(response => {
                this.category = response.data;
            })
            .catch(err => console.log(err));
        this.loading = false;
    }

    @action create(params = {}) {
        post('/categories/', params)
            .then(response => {
                this.categories = [...this.categories, response.data];
            })
            .catch(err => console.log(err));
    }

    @action
    async update(uid: string, payload: any) {
        this.loading = true;
        await patch(`/categories/${uid}/?show-all=true`, payload)
            .then(response => {})
            .catch(err => console.log(err));
        this.loading = false;
    }

    @action async getExtraList(uid: string | null) {
        this.loading = true;
        await get(`/categories/${uid}/extra-list?show-all=true`)
            .then(response => {
                this.extra_list = response.data.results;
            })
            .catch(err => console.log(err));
        this.loading = false;
    }

    @action async delete(uid: string | null) {
        this.loading = true;
        await remove(`/categories/${uid}/?show-all=true`);
        this.loading = false;
    }
}

export const CategoryAdminStore = new Category();
