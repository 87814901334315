import { action, observable } from 'mobx';
import { get, patch, remove } from '../utils/request';
import { NotificationProps } from '../types/types.ds';

class Notification {
    @observable notifications: Array<NotificationProps> = [];

    @action getNotifications = () => {
        get('/notifications/')
            .then(response => {
                this.notifications = response?.data.results;
            })
            .catch(err => {
                console.log(err?.response?.data);
            });
    };

    @action setReadOut = (uid: string) => {
        this.notifications = this.notifications.map(n => {
            if (n.uid === uid) {
                return {
                    ...n,
                    read: true,
                };
            }
            return n;
        });
        patch(`/notifications/${uid}/`, {
            read: true,
        })
            .then(response => {})
            .catch(err => {
                console.log(err?.response?.data);
            });
    };

    @action deleteNotifications = (uid: string) => {
        this.notifications = this.notifications.filter(n => {
            return n.uid !== uid;
        });
        remove(`/notifications/${uid}/`)
            .then(response => {})
            .catch(err => {
                console.log(err?.response?.data);
            });
    };
}

export const NotificationStore = new Notification();
