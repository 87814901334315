import { action, computed, observable } from 'mobx';
import { post } from '../utils/request';
import { UserStore } from './UserStore';

class Authentication {
    @observable isAuthenticating = false;
    @observable token: string | null = '';

    constructor() {
        this.isAuthenticating = true;
        this.token = localStorage.getItem('token');
    }

    @action
    async setToken(token: string) {
        localStorage.setItem('token', token);
        this.token = token;
        UserStore.loadCurrentUser();
    }

    @action
    async removeToken() {
        localStorage.removeItem('token');
        this.token = null;
    }

    @action
    async getToken() {
        return localStorage.getItem('token');
    }

    @computed get isAuthenticated() {
        return !!this.token;
    }

    @action
    async createAccount(payload: {
        first_name: string;
        last_name: string;
        email: string;
        phone: string;
        password1: string;
        password2: string;
    }) {
        const response = await post('/auth/registration/', payload);
        await this.setToken(response.data?.key);
    }

    @action
    async login(email: string, password: string) {
        const response = await post('/auth/login/', {
            email: email,
            password: password,
        });
        await this.setToken(response.data.key);
    }

    /**
     * Do a google login
     */
    @action loginWithGoogle = async (token: string) => {
        const response = await post('/auth/google/', {
            access_token: token,
        });

        await this.setToken(response.data?.key);
    };

    /**
     * Attempt a login using the Facebook login dialog asking for default permissions.
     */
    @action loginWithFacebook = async (token: string) => {
        const response = await post('/auth/facebook/', {
            access_token: token,
        });
        await this.setToken(response.data?.key);
    };

    @action
    async logout() {
        try {
            await post('/auth/logout/').finally(() => {
                this.token = null;
                this.removeToken();
                UserStore.setUser(null);
            });
        } catch (e) {
            console.log(e);
        }
    }

    @action
    async resetPassword(email: string) {
        await post('/auth/password/reset/', {
            email: email,
        });
    }
}

export const AuthenticationStore = new Authentication();
