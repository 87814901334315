import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Col, Modal, Row, Space, Statistic, Table } from 'antd';
import { UserStore } from '../../../stores/UserStore';
import { Link, useLocation } from 'react-router-dom';
import { PaginationComponent } from '../../../components/pagination';
import { ColumnsType } from 'antd/es/table';
import { ExtraListProps } from '../../../types/types.ds';
import { Helmet } from 'react-helmet';
import { Loading } from '../../../components/loading/loading';
import { FieldNumberOutlined } from '@ant-design/icons';
import { useQueryParameters } from '../../../hooks/useQueryParameters';
import { Key } from 'antd/lib/table/interface';
import { CategoryAdminStore } from '../../../stores/Admin/CategoryAdminStore';
import ExtraFilterForm from './components/ExtraFilterForm';
import { ExtraAdminStore } from '../../../stores/Admin/ExtraAdminStore';
import { ExtraAddForm } from '../../../components/products/extras';

const ExtraList = observer(() => {
    const parameters = useQueryParameters();
    const location = useLocation();
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([]);

    const [showAddCategory, setShowAddCategory] = useState(false);

    const handleGetData = (values: { search: string; owner: string }) => {
        ExtraAdminStore.getAll({
            ...values,
            is_by_category: true,
        });
    };

    useEffect(() => {
        CategoryAdminStore.getCategories({
            owner: UserStore.user?.uid,
        });
    }, [UserStore.user?.uid]);

    useEffect(() => {
        const params: any = {};

        if (parameters.get('search')) {
            params['search'] = parameters.get('search');
        }

        if (parameters.get('owner')) {
            params['owner'] = parameters.get('owner');
        }

        if (!UserStore.isStaff) {
            // Not Staff User get only its own products
            params['owner'] = UserStore.user?.uid;
        }

        handleGetData(params);
    }, [location.search]);

    const columns: ColumnsType<ExtraListProps> = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: text => <div>{text}</div>,
        },

        {
            title: 'Calculo de Precio',
            dataIndex: 'pricing',
            key: 'pricing',
            render: text => <div>{text == 'add' ? 'Suma' : 'Reemplaza'} al precio base</div>,
        },

        {
            title: 'Requerido',
            dataIndex: 'required',
            key: 'required',
            render: (text, r) => <div>{r.required ? 'Si' : 'No'} </div>,
        },
        {
            title: 'Categorias',
            dataIndex: 'categories_related',
            key: 'categories_related',
            render: (text, r) => <div>{r.categories_related.map(c => c.name).toString()} </div>,
        },
        {
            title: 'Orden',
            dataIndex: 'order',
            key: 'order',
            render: (text, r) => <div>{r.order}</div>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/admin/extras/${record.uid}/edit`}>Modificar</Link>
                </Space>
            ),
        },
    ];

    if (!UserStore.user?.uid) {
        return (
            <div>
                <Loading />
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
            </Helmet>

            <section className="mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            {UserStore.user.is_seller && !UserStore.user.published && (
                                <div className={'my-4'}>
                                    <Alert
                                        message="El establemiento no esta publicado todavía, comunicate con un representante de rush delivery para ayudarte"
                                        type="warning"
                                    />
                                </div>
                            )}

                            <Button
                                className="rounded"
                                type="primary"
                                onClick={() => {
                                    setShowAddCategory(true);
                                }}
                            >
                                Nueva Variacion
                            </Button>

                            <div className={'my-6'}>{<ExtraFilterForm />}</div>

                            {showAddCategory && (
                                <Modal
                                    bodyStyle={{ height: 600, width: '100%' }}
                                    title="Nueva Variacion"
                                    visible={true}
                                    onCancel={() => setShowAddCategory(false)}
                                    footer={[]}
                                >
                                    <ExtraAddForm
                                        type={'category'}
                                        handleSave={payload => {
                                            ExtraAdminStore.create(payload);
                                            setShowAddCategory(false);
                                        }}
                                    />
                                </Modal>
                            )}
                            <div className={'my-4'}>
                                <Table
                                    rowSelection={{
                                        selectedRowKeys: selectedRowsKeys,
                                        onChange: selectedRows => {
                                            setSelectedRowsKeys(selectedRows);
                                        },
                                    }}
                                    pagination={false}
                                    columns={columns}
                                    loading={ExtraAdminStore.loading}
                                    dataSource={ExtraAdminStore.extras}
                                    footer={() => {
                                        return (
                                            <PaginationComponent pageSize={100} total={ExtraAdminStore.total_records} />
                                        );
                                    }}
                                />
                            </div>
                            <div className={'p-4 text-center'}>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Statistic
                                            title="#"
                                            value={ExtraAdminStore.total_records}
                                            prefix={<FieldNumberOutlined />}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
});

export default ExtraList;
