import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Statistic, Table, Radio, Form, Button, FormInstance, Input } from 'antd';
import dayjs from 'dayjs';

import { FieldNumberOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { UserAdminStore, UserReportProps } from '../../../stores/Admin/UserAdminStore';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ColumnsType } from 'antd/es/table';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { InputNumber } from 'antd/es';
import { SorterResult } from 'antd/lib/table/interface';
import DateRangePicker from '../../../components/DateRangePicker';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const columns: ColumnsType<UserReportProps> = [
    {
        title: 'Fecha de Registro',
        dataIndex: 'date_joined',
        key: 'date_joined',
        sorter: true,
        render: (t: string, r) => {
            return (
                <div>
                    <div>{dayjs(r.date_joined).format('DD/MM/YYYY')}</div>
                    <div>{dayjs(r.date_joined).fromNow()}</div>
                </div>
            );
        },
    },
    {
        title: 'Ultimo Ingreso',
        dataIndex: 'last_login',
        key: 'last_login',
        sorter: true,
        render: (t: string, r) => {
            return (
                <div>
                    <div>{dayjs(r.last_login).format('DD/MM/YYYY')}</div>
                    <div>{dayjs(r.last_login).fromNow()}</div>
                </div>
            );
        },
    },
    {
        title: 'Ultima Order',
        dataIndex: 'last_order_date',
        sorter: true,
        key: 'last_order_date',
        render: (_, r: UserReportProps) => {
            return (
                <div>
                    <div>{dayjs(r.last_order_date).format('DD/MM/YYYY')}</div>
                    <div>{dayjs(r.last_order_date).fromNow()}</div>
                </div>
            );
        },
    },
    {
        title: 'Name',
        dataIndex: 'first_name',
        key: 'first_name',
        render: (_t, r) => {
            return (
                <div className={'flex flex-row justify-start'}>
                    <div>
                        {r.first_name} {r.last_name}
                    </div>
                </div>
            );
        },
    },

    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (_t, r) => {
            return (
                <div className={'flex flex-row justify-start'}>
                    <div>{r.email}</div>
                </div>
            );
        },
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        render: (_t, r) => {
            return (
                <div className={'flex flex-row justify-start'}>
                    <div>{r.phone}</div>
                </div>
            );
        },
    },
    {
        title: 'Phone Verified',
        dataIndex: 'verified_phone',
        key: 'verified_phone',
        sorter: true,
        render: (_t, r) => {
            return (
                <div className={'flex flex-row justify-start'}>
                    <div className="ml-2">{r.verified_phone ? <CheckCircleOutlineIcon /> : <CloseRoundedIcon />}</div>
                </div>
            );
        },
    },

    {
        title: 'Nro Pedidos',
        dataIndex: 'orders_count',
        sorter: true,
        key: 'orders_count',
        render: (_, r: UserReportProps) => {
            return (
                <div>
                    <div>{r.orders_count}</div>
                </div>
            );
        },
    },
    {
        title: 'Dispositivos Activos',
        dataIndex: 'active_devices',
        key: 'active_devices',
    },
];

const ReportCustomers = () => {
    const [form] = Form.useForm();

    const formRef = React.createRef<FormInstance>();

    const [sorter, setSorter] = useState<SorterResult<UserReportProps> | null>(null);

    const handleGetData = (values: any) => {
        let params: any = {
            search: values.search,
        };

        if (values.date_joined) {
            params = {
                ...params,
                date_joined_min: values.date_joined[0].format('YYYY-MM-DD'),
                date_joined_max: values.date_joined[1].format('YYYY-MM-DD'),
            };
        }

        if (values.last_login) {
            params = {
                ...params,
                last_login_min: values.last_login[0].format('YYYY-MM-DD'),
                last_login_max: values.last_login[1].format('YYYY-MM-DD'),
            };
        }
        if (values.last_order_date) {
            params = {
                ...params,
                last_order_date_min: values.last_order_date[0].format('YYYY-MM-DD'),
                last_order_date_max: values.last_order_date[1].format('YYYY-MM-DD'),
            };
        }

        params = {
            ...params,
            orders_count_greater_than: values.orders_count_greater_than,
        };

        params = {
            ...params,
            orders_count_lower_than: values.orders_count_lower_than,
        };

        params = {
            ...params,
            orders_count: values.orders_count,
        };

        params = {
            ...params,
            active_devices_greater_that: values.active_devices_greater_that,
        };

        if (values.verified_phone !== null) {
            params['verified_phone'] = values.verified_phone;
        }

        if (sorter) {
            const sort_direction = sorter?.order == 'descend' ? '-' : '';

            params = {
                ...params,
                ordering: `${sort_direction}${sorter.field}`,
            };
        }

        UserAdminStore.getReportAll(params);
    };

    return (
        <div className={'flex flex-col p-4'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reportes de Clientes</title>
            </Helmet>
            <div className={'w-full pt-4 flex flex-row'}>
                <Form layout="inline" onFinish={handleGetData} autoComplete="off" ref={formRef}>
                    <Form.Item label="Buscar" name="search">
                        <Input.Search />
                    </Form.Item>
                    <Form.Item label="Fecha de Registro" name="date_joined">
                        <DateRangePicker />
                    </Form.Item>
                    <Form.Item label="Ultimo Acceso" name="last_login">
                        <DateRangePicker />
                    </Form.Item>
                    <Form.Item label="Ultima Orden" name="last_order_date">
                        <DateRangePicker />
                    </Form.Item>
                    <Form.Item label="Verified Phone:" name="verified_phone">
                        <Radio.Group>
                            <Radio value={null}>Todos</Radio>
                            <Radio value={true}>Si</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Nro de pedidos mayor que:" name="orders_count_greater_than">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Nro de pedidos menor que:" name="orders_count_lower_than">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Nro de pedidos exacto:" name="orders_count">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Nro de dispositivos activo mayor que:" name="active_devices_greater_that">
                        <InputNumber />
                    </Form.Item>
                    <Button htmlType="button" onClick={() => formRef.current?.resetFields()}>
                        Reset
                    </Button>
                    <Button htmlType="submit" type={'primary'} loading={UserAdminStore.loading}>
                        Filter
                    </Button>
                </Form>
            </div>

            <div className={'pt-4'}>
                <Table<UserReportProps>
                    pagination={false}
                    columns={columns}
                    loading={UserAdminStore.loading}
                    dataSource={UserAdminStore.customers}
                    onChange={(pagination, filter, sorter) => {
                        //@ts-ignore
                        setSorter(sorter);
                    }}
                />
            </div>

            <div className={'p-4 text-center'}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Statistic title="# Mostrados" value={UserAdminStore.count} prefix={<FieldNumberOutlined />} />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default observer(ReportCustomers);
