import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { OrderStore } from '../../stores/OrderStore';
import OrderCard from '../../components/cards/OrderCard';
import { Alert, Button, message, Modal, PageHeader } from 'antd';
import { Link, Skeleton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Redirect, useHistory } from 'react-router-dom';
import { OrderProps, UserProps } from '../../types/types.ds';
import ContactCard from '../../components/cards/ContactCard';
import { Chat } from '../../components/Chat/Chat';
import { RoomProps } from '../../stores/ChatStore';
import { UserStore } from '../../stores/UserStore';
import { Helmet } from 'react-helmet';

const OrderDetail = () => {
    const history = useHistory();
    const [showContactDetail, setShowContactDetails] = useState<{
        user: UserProps;
        order: OrderProps;
    } | null>(null);

    const [room, setRoom] = useState<RoomProps | null>();

    const order = OrderStore.selected;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!order) {
        return <Redirect to={'/'} />;
    }

    return (
        <div
            style={{
                margin: 10,
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                {/*<title>#{order.id}</title>*/}
            </Helmet>

            <PageHeader
                style={{
                    marginTop: 20,
                }}
                onBack={() => history.goBack()}
                title={<div className={'capitalizeert'}>Regresar</div>}
            />
            <div
                className="row"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                }}
            >
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 1200,
                        margin: 'auto',
                    }}
                >
                    <div className={'order-card'} style={{ marginTop: 15 }}>
                        <OrderCard
                            key={order.id}
                            order={order}
                            handleShowContactDetails={(user, order) => {
                                setShowContactDetails({
                                    user: user,
                                    order: order,
                                });
                            }}
                        />
                    </div>
                </div>

                {room && (
                    <div
                        style={{
                            marginTop: 40,
                        }}
                    >
                        <Chat room={room} currentUserId={UserStore?.user?.uid ?? ''} onClose={() => setRoom(null)} />
                    </div>
                )}

                {showContactDetail?.user && (
                    <ContactCard
                        user={showContactDetail.user}
                        handleClose={() => setShowContactDetails(null)}
                        onClickChat={() => {
                            OrderStore.getOrCreateRoomByOrder(showContactDetail.order.uid)
                                .then(r => {
                                    setRoom(r.data);
                                    setShowContactDetails(null);
                                })
                                .finally(() => {});
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default observer(OrderDetail);
