import React, { useEffect, useMemo } from 'react';
import { ChatStore, MessageProps, RoomProps, UUID } from '../../stores/ChatStore';
import { UserStore } from '../../stores/UserStore';
import { observer } from 'mobx-react';
import useChat from '../../hooks/useChat';
import ChatItem from './ChatItem';
import ChatInput from './ChatInput';
import { Modal } from 'antd';

// function normalize_message(item: MessageProps) {
//     console.log(item);
//     const { created_by } = item || {};
//     // const { first_name, last_name, company } = created_by;
//     // const display_name = company ?? first_name ?? 'Usuario';
//
//     const { thumbnail, facebook_avatar, google_avatar } = created_by;
//
//     const avatar = thumbnail || facebook_avatar || google_avatar || undefined;
//
//     return {
//         _id: item.uid,
//         text: item.message,
//         createdAt: item.created_time,
//         sent: !!item.created_time,
//         received: !!item.created_time,
//         pending: !item.created_time,
//         user: {
//             _id: item.created_by.uid,
//             name: '',
//             avatar: avatar,
//         },
//     };
// }

interface Props {
    currentUserId: string;
    room: RoomProps;
    onClose: () => void;
}

export const Chat = observer((props: Props) => {
    const { room } = props;
    const { new_notification, sendMessage } = useChat(room.uid);

    const messages = ChatStore.room?.messages;

    useEffect(() => {
        if (room?.uid) {
            ChatStore.setRoom(room);
            ChatStore.getRoomById(room.uid);
        }
    }, []);

    useEffect(() => {
        if (new_notification?.message) {
            //Only append messages from other users

            if (new_notification.message.created_by.uid !== UserStore.user?.uid) {
                ChatStore.appendMessage(new_notification.message);
            }
        }
    }, [new_notification]);

    useEffect(() => {
        if (ChatStore.room?.uid && UserStore.user?.messages_count) {
            ChatStore.markAsViewed(ChatStore.room.uid).finally(() => {
                UserStore.loadCurrentUser();
            });
        }
    }, [UserStore.user?.messages_count]);

    return (
        <div>
            <Modal
                visible
                onCancel={() => props.onClose()}
                footer={[
                    <div key={'send'}>
                        <ChatInput
                            onSendMessage={text => {
                                if (text && UserStore.user) {
                                    const new_message: any = {
                                        uid: UUID(),
                                        room: room.uid,
                                        message: text,
                                        created_by: UserStore.user,
                                    };
                                    ChatStore.sendMessage(new_message);
                                }
                            }}
                        />
                    </div>,
                ]}
                style={{
                    height: '400',
                }}
                bodyStyle={{
                    height: 400,
                    overflowY: 'auto',
                }}
            >
                <div style={{ marginTop: 40 }}>
                    <div
                        style={{
                            width: '90%',
                            margin: 'auto',
                        }}
                    >
                        {messages?.map(m => {
                            const { thumbnail, facebook_avatar, google_avatar } = m.created_by;
                            return (
                                <div key={m.uid} className={'my-3'}>
                                    <ChatItem
                                        _id={m.uid}
                                        text={m.message}
                                        createdAt={m.created_time}
                                        sent={!!m.created_time}
                                        received={true}
                                        pending={false}
                                        user={{
                                            _id: m.created_by.uid,
                                            name: m.created_by.company ?? m.created_by.first_name,
                                            avatar: thumbnail ?? facebook_avatar ?? google_avatar ?? '',
                                        }}
                                        self={m.created_by.uid === props.currentUserId}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </div>
    );
});
