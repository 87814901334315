import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Login } from './login';
import { Helmet } from 'react-helmet';

export function useQueryParameters() {
    return new URLSearchParams(useLocation().search);
}

export const Authentication = () => {
    const history = useHistory();
    const query = useQueryParameters();
    const next_url = query.get('next') || '/';

    return (
        <div className={'p-2'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Iniciar Sesión</title>
            </Helmet>
            <div className="md:max-w-md m-auto">
                <Login
                    onSuccess={() => {
                        history.replace(next_url);
                    }}
                />
            </div>
        </div>
    );
};

export default Authentication;
