import React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { NotificationItem } from './NotificationItem';
import { NotificationStore } from '../../stores/NotificationStore';

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const NotificationsList = () => {
    const items = NotificationStore.notifications.map(notification => {
        return <NotificationItem key={notification.uid} notification={notification} />;
    });
    return (
        <div
            style={{
                padding: 20,
                overflowY: 'auto',
                height: 450,
            }}
        >
            {items}
        </div>
    );
};

export default observer(NotificationsList);
