import ExtraItem from './ExtraItem';
import { Alert, Card, Popconfirm } from 'antd';
import { ProductAdminStore } from '../../../../stores/Admin/ProductAdminStore';
import { DeleteOutlined } from '@ant-design/icons';
import { OptionAddForm, OptionEditForm } from '../../../../components/products/options';
import React, { useState } from 'react';
import { ExtraListProps } from '../../../../types/types.ds';

interface Props {
    item: ExtraListProps;
    onUpdate: () => void;
    onDelete: () => void;
}

const ExtraCard = ({ item, onUpdate, onDelete }: Props) => {
    const [isLoading, setLoading] = useState(false);
    return (
        <Card
            key={item.uid}
            title={
                <div>
                    <ExtraItem item={item} loadProduct={onUpdate} />
                </div>
            }
            className={'m-4'}
            extra={
                <span
                    className={'cursor-pointer'}
                    style={{
                        color: 'red',
                        fontSize: 14,
                    }}
                >
                    <Popconfirm
                        title="Esta seguro que desea eliminar esta variacion y todas sus opciones permanetemente?"
                        onConfirm={() => {
                            setLoading(true);
                            ProductAdminStore.removeExtra(item.uid)
                                .then(() => {
                                    onDelete();
                                })
                                .finally(() => setLoading(false));
                        }}
                        okText="Sí, borrar"
                        cancelText="No"
                    >
                        Eliminar
                    </Popconfirm>
                </span>
            }
        >
            <div className={'flex md:flex-row sm:flex-col'}>
                {item.pricing == 'add' && item.min > item.options.length && (
                    <Alert
                        message={`Las reglas requiren que el cliente seleccione 4 opciones, pero solo ha agregado ${item.options.length}`}
                        type="error"
                    />
                )}
            </div>

            <div className={'flex md:flex-row sm:flex-col mt-1'}>
                {item.required && item.options.length == 0 && (
                    <Alert message={`Ha marcado como obligatorio pero no ha agregado ningunca opcion`} type="error" />
                )}
            </div>

            <div className={'flex md:flex-row sm:flex-col mt-3'}>
                <div className={'sm:w-full md:w-1/6 '}>
                    <strong>Opción</strong>
                </div>
                <div className={'sm:w-full md:w-1/6 '}>
                    <strong>{item.pricing === 'add' ? 'Valor Adicional' : 'Nuevo Precio'}</strong>
                </div>

                {item.pricing == 'replace' && (
                    <div className={' sm:w-full md:w-1/6 font-bold'}>Price (Rush Delivery)</div>
                )}

                <div className={' sm:w-full md:w-1/6'}>
                    <strong> Seleccionado por defecto?</strong>
                </div>

                <div className={'sm:w-full md:w-1/6 '}>
                    <strong>Orden</strong>
                </div>

                <div className={'sm:w-full md:w-1/6 '}>
                    <strong>Eliminar</strong>
                </div>
            </div>

            {item.options.map(option => (
                <OptionEditForm
                    key={option.uid}
                    pricing={item.pricing}
                    option={option}
                    handleChanged={() => {
                        onUpdate();
                    }}
                />
            ))}

            <OptionAddForm
                pricing={item.pricing}
                handleSave={values => {
                    values['extra'] = item.uid;
                    ProductAdminStore.AddOption(values)
                        .then(() => {
                            onUpdate();
                        })
                        .catch(err => {
                            alert(err?.response?.data);
                        });
                }}
            />
        </Card>
    );
};

export default ExtraCard;
