import { action, computed, observable, runInAction } from 'mobx';
import { get, patch, post } from '../utils/request';
import { CitiesProps, UserProps } from '../types/types.ds';
import { AuthenticationStore } from './AuthenticationStore';

interface FirebaseUserProps {
    phoneNumber: string | null;
    tenantId: string | null;
    displayName: string | null;
    isAnonymous: boolean;
    email: string | null;
    providerData: [
        { email: null; providerId: 'phone'; photoURL: null; phoneNumber: '+12626290073'; displayName: ''; uid: '' }
    ];
    emailVerified: boolean | string;
    photoURL: string | null;
    providerId: string;
    metadata: { lastSignInTime: number; creationTime: number };
    uid: string;
}

interface CoordinatesProps {
    latitude: number;
    longitude: number;
}

class User {
    @observable checkingUser = false;
    @observable token?: string;
    @observable user?: UserProps;
    @observable location?: CoordinatesProps;
    @observable connectedSocket = false;
    @observable push_notification_token = '';
    @observable isOlderThan18 = false;
    @observable userFirebase?: FirebaseUserProps;
    @observable city?: CitiesProps;

    constructor() {
        this.checkingUser = true;
    }

    @computed get getUId() {
        return this.user?.uid;
    }

    @computed get fullName() {
        if (this.user?.is_seller) {
            return this.user.company;
        }
        if (this.user?.first_name) {
            return this.user.first_name + ' ' + this.user.last_name;
        }

        return this.user?.email;
    }
    @computed get thumbnail() {
        if (this.user?.is_seller) {
            return this.user.thumbnail;
        }
        if (this.user?.facebook_avatar) {
            return this.user.facebook_avatar;
        }

        return null;
    }

    @computed get isDriver() {
        return this.user?.is_delivery_driver || false;
    }

    @computed get isStaff() {
        return this.user?.is_staff || false;
    }

    @action cleanNumber() {
        if (this.user) {
            this.user.verified_phone = false;
        }
    }

    @computed get isStore() {
        return this.user?.is_seller || false;
    }

    @action removeOldToken() {
        this.token = undefined;
    }

    @action loadCurrentUser = () => {
        return get('/auth/user/')
            .then(response => {
                runInAction(() => {
                    this.user = response?.data;
                });
            })
            .catch(err => {
                if (err?.response?.status === 403) {
                    AuthenticationStore.removeToken();
                    this.user = undefined;
                    this.token = undefined;
                }
            })
            .finally(() => (this.checkingUser = false));
    };

    @action setConnectedSocket = (connected: boolean) => {
        this.connectedSocket = connected;
    };

    @action setUser = (data: any) => {
        this.user = { ...this.user, ...data };
    };

    @action setLocation = (location: { latitude: number; longitude: number }) => {
        this.location = location;
    };

    @action updateAccount = (payload: any) => {
        this.user = {
            ...this.user,
            ...payload,
        };

        delete payload.thumbnail;
        delete payload.feature_graphic;

        patch('/auth/user/', payload)
            .then(response => {
                this.user = response.data;
            })
            .catch(err => {
                //Todo show alert on error
                // alert(JSON.stringify(err?.response.data))
            });
    };

    @action addAndroidFCMToken = (payload: {
        name?: string;
        registration_id?: string;
        device_id?: string;
        cloud_message_type: string;
    }) => {
        const url = '/device/gcm/';
        post(url, payload).catch(err => {
            // Todo the gcm is saved with error unique
            console.log(JSON.stringify(err.response.data));
        });
    };

    @action addIOSFCMToken = (payload: any) => {
        const url = '/device/gcm/';

        post(url, payload).then(response => {});
    };

    @action addWebFCMToken = (payload: any) => {
        const url = '/device/web/';

        post(url, payload).then(response => {});
    };

    @action updateThumbnail = (file: any) => {
        const data = new FormData();
        data.append('thumbnail', file);
        patch('/auth/user/', data, true)
            .then(response => {})
            .catch(err => {
                // console.warn('Error', JSON.stringify(err.response))
            });
    };

    @action acceptTermsAndConditions = (accepted: boolean) => {
        return patch('/auth/user/', {
            accepted_terms_and_conditions: accepted,
        });
    };

    @action forgotPassword(email: string) {
        return post('/auth/password/reset/', {
            email: email,
        });
    }

    @action setOlderThan18() {
        this.isOlderThan18 = true;
    }

    @action setUserFirebase(user: any) {
        //Todo check user type
        this.userFirebase = user;
    }

    @action savePushNotificationToken() {
        if (this.push_notification_token) {
            const device_id = window.navigator.userAgent.replace(/\D+/g, '').substring(0, 8);
            const payload = {
                name: window.navigator.userAgent.toLowerCase(),
                registration_id: this.push_notification_token,
                device_id: device_id,
                cloud_message_type: 'FCM',
            };
            this.addAndroidFCMToken(payload);
        }
    }

    @action isThisPhoneVerified(phone: string) {
        if (!this.user?.phone) {
            return false;
        }

        if (!this.user?.verified_phone) {
            return false;
        }

        return this.user?.phone === phone;
    }

    @computed get hasLocation() {
        return !!this.location?.latitude && !!this.location.longitude;
    }

    @action setMyCity(city: CitiesProps) {
        this.city = city;
    }

    @action setPushNotificationToken(token: string) {
        this.push_notification_token = token;
    }

    @action setUserProperty(field: keyof UserProps, value: any) {
        if (this.user) {
            //@ts-ignore
            this.user[field] = value;
        }
    }
}

export const UserStore = new User();
