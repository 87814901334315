import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Col, Input, Row, Space, Statistic, Table, Tag, Image } from 'antd';
import { UserStore } from '../../../stores/UserStore';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PaginationComponent } from '../../../components/pagination';
import { ColumnsType } from 'antd/es/table';
import { ProductProps } from '../../../types/types.ds';
import { ProductAdminStore } from '../../../stores/Admin/ProductAdminStore';
import { Helmet } from 'react-helmet';
import { Loading } from '../../../components/loading/loading';
import { FieldNumberOutlined } from '@ant-design/icons';
import ProductFilterForm from './components/ProductFilterForm';
import { useQueryParameters } from '../../../hooks/useQueryParameters';
import { Key } from 'antd/lib/table/interface';

const { Search } = Input;

const ProductList = observer(() => {
    const history = useHistory();
    const parameters = useQueryParameters();
    const location = useLocation();
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([]);

    const handleGetData = (values: {
        search: string;
        owner: string | undefined;
        is_promotion: boolean | undefined;
    }) => {
        ProductAdminStore.searchProducts(values);
    };

    useEffect(() => {
        const params: any = {};

        if (parameters.get('search')) {
            params['search'] = parameters.get('search');
        }

        if (parameters.get('is_promotion')) {
            params['is_promotion'] = parameters.get('is_promotion');
        }

        if (parameters.get('owner')) {
            params['owner'] = parameters.get('owner');
        }

        // Not Staff User get only its own products
        if (!UserStore.isStaff) {
            params['owner'] = UserStore.user?.uid;
        }

        handleGetData(params);
    }, [location.search]);

    const columns: ColumnsType<ProductProps> = [
        {
            title: 'Imagen',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            render: text => {
                return <Image width={50} className={'object-cover rounded-lg'} src={text} />;
            },
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: text => <div>{text}</div>,
        },
        {
            title: 'Precio (Consumo Local)',
            dataIndex: 'local_price',
            key: 'local_price',
        },
        {
            title: 'Precio (Para LLevar)',
            dataIndex: 'original_price',
            key: 'original_price',
            render: (text, r) => {
                return (
                    <div>
                        <div>{r.original_price}</div>
                    </div>
                );
            },
        },
        {
            title: 'Precio (Rush Delivery)',
            dataIndex: 'price',
            key: 'price',
            render: (text, r) => {
                return (
                    <div>
                        <div>{r.price}</div>
                    </div>
                );
            },
        },
        {
            title: 'Categoría',
            dataIndex: 'category',
            key: 'category',
            render: (t, r) => <div>{r?.category_related?.name}</div>,
        },
        {
            title: 'Publicado',
            dataIndex: 'published',
            key: 'published',
            render: (text, r) => <div>{r.published ? <Tag color="green">Sí</Tag> : <Tag color="red">No</Tag>}</div>,
        },
        {
            title: 'Órden',
            dataIndex: 'order',
            key: 'order',
            render: (text, r) => <div>{r.order >= 0 ? r.order : '0'}</div>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/admin/product/${record.uid}/edit`}>Modificar</Link>
                </Space>
            ),
        },
    ];

    if (!UserStore.user?.uid) {
        return (
            <div>
                <Loading />
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gestionar Productos</title>
            </Helmet>
            <section className="mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            {UserStore.user.is_seller && !UserStore.user.published && (
                                <div className={'my-4'}>
                                    <Alert
                                        message="El establemiento no esta publicado todavía, comunicate con un representante de rush delivery para ayudarte"
                                        type="warning"
                                    />
                                </div>
                            )}

                            <Button
                                className="rounded"
                                type="primary"
                                onClick={() => {
                                    history.push('/Admin/product/new/');
                                }}
                            >
                                Nuevo Producto
                            </Button>

                            <div className={'my-6'}>
                                <ProductFilterForm />
                            </div>

                            <div className={'my-4'}>
                                <Table
                                    rowSelection={{
                                        selectedRowKeys: selectedRowsKeys,
                                        onChange: selectedRows => {
                                            setSelectedRowsKeys(selectedRows);
                                        },
                                    }}
                                    pagination={false}
                                    columns={columns}
                                    loading={ProductAdminStore.loading_search}
                                    dataSource={ProductAdminStore.search_products}
                                    footer={() => {
                                        return (
                                            <PaginationComponent
                                                pageSize={100}
                                                total={ProductAdminStore.total_records}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className={'p-4 text-center'}>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Statistic
                                            title="#"
                                            value={ProductAdminStore.total_records}
                                            prefix={<FieldNumberOutlined />}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
});

export default ProductList;
