import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Layout, Modal } from 'antd';
import { Login } from '../pages/authentication/login';
import Menu from './menus/menu';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthenticationStore } from '../stores/AuthenticationStore';
import HamburgerIcon from '../components/icons/BurgerIcon';
import { CartStore } from '../stores/CartStore';
import Drawer from './Drawer';
import LOGO from '../assets/images/logo.png';
import { UserStore } from '../stores/UserStore';
import LoadServices from '../components/LoadServices';
import CartButton from '../components/buttons/CartButton';
import SignInButton from '../components/buttons/SignInButton';
import UserCard from '../components/cards/UserCard';
import DownloadAndroidStore from 'components/buttons/DownloadAndroidStore';
import DownloadAppStore from 'components/buttons/DownloadAppStore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import CalculateIcon from '@mui/icons-material/Calculate';
import NotificationsIcon from '@mui/icons-material/Notifications';

import AccountMenu from './menus/AccountMenu';
import { NotificationStore } from '../stores/NotificationStore';
import { OrderStore } from '../stores/OrderStore';

const Header = () => {
    const history = useHistory();
    const [displayAuthForm, setDisplayAuthForm] = useState<boolean>(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const { pathname } = useLocation();

    const handleShowHideDrawer = () => setShowDrawer(!showDrawer);

    useEffect(() => {
        if (showDrawer) {
            handleShowHideDrawer();
        }
    }, [pathname]);

    return (
        <>
            <Layout
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    zIndex: 1,
                    width: '100%',
                    backgroundColor: '#fff',
                    padding: 5,
                    maxHeight: 40,
                }}
            >
                <HamburgerIcon className="block  mt-2 mx-4" onClick={handleShowHideDrawer} />

                {UserStore.user?.uid && AuthenticationStore.token && (
                    <LoadServices user_uid={UserStore.user.uid} token={AuthenticationStore.token} />
                )}

                <div className="logo my-auto mr-2 hidden md:block">
                    <img src={LOGO} width={30} className={'rounded-lg'} />
                </div>

                {CartStore.number_cart_items > 0 && (
                    <div className={'ml-auto'}>
                        <CartButton
                            onClick={() => {
                                if (AuthenticationStore.isAuthenticated) {
                                    history.push('/checkout/');
                                } else {
                                    setDisplayAuthForm(true);
                                }
                            }}
                        />
                    </div>
                )}

                <div className={'flex flex-row items-center ml-auto'}>
                    <AccountMenu
                        userId={UserStore.user?.uid ?? null}
                        firstName={UserStore.user?.first_name}
                        isAuthenticated={AuthenticationStore.isAuthenticated}
                        isAPartner={UserStore.isStaff || UserStore.isStore}
                        isStaff={UserStore.isStaff}
                        notificationCount={NotificationStore.notifications.filter(n => !n.read).length}
                        messagesCount={UserStore.user?.messages_count ?? 0}
                        ordersCount={OrderStore.orders.filter(o => !o.completed).length}
                    />
                </div>

                <Drawer
                    title={
                        AuthenticationStore.isAuthenticated ? (
                            <div className={'flex flex-row items-center'}>
                                {UserStore.thumbnail ? (
                                    <img className="rounded-full" src={UserStore.thumbnail} width={60} height={60} />
                                ) : (
                                    <AccountCircleIcon
                                        style={{
                                            fontSize: 60,
                                        }}
                                    />
                                )}

                                <div className="ml-4">
                                    <UserCard
                                        fullName={UserStore.fullName ?? ''}
                                        onClick={() => {
                                            if (UserStore.user?.is_seller) {
                                                history.push('/admin/my-account/');
                                            }
                                        }}
                                    />
                                    <div
                                        className="cursor-pointer"
                                        style={{
                                            color: 'gray',
                                            fontSize: 14,
                                        }}
                                        onClick={() => {
                                            AuthenticationStore.logout();
                                            setShowDrawer(false);
                                            history.push('/');
                                        }}
                                    >
                                        <LockIcon
                                            style={{
                                                fontSize: 14,
                                            }}
                                        />
                                        Salir
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <SignInButton
                                handleClick={() => {
                                    setDisplayAuthForm(true);
                                }}
                            />
                        )
                    }
                    visible={showDrawer}
                    onClose={() => {
                        setShowDrawer(false);
                    }}
                >
                    <Menu
                        isAuthenticated={AuthenticationStore.isAuthenticated}
                        isAPartner={UserStore.isStore}
                        isStaff={UserStore.isStaff}
                        type={'vertical'}
                    />
                </Drawer>
            </Layout>

            {displayAuthForm && (
                <Modal
                    title={'Login'}
                    visible
                    onCancel={() => {
                        setDisplayAuthForm(false);
                    }}
                    footer={[]}
                >
                    <Login
                        onSuccess={() => {
                            setDisplayAuthForm(false);
                        }}
                    />
                </Modal>
            )}
        </>
    );
};

export default observer(Header);
