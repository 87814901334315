import { ExtraListProps, OptionsProps, ProductProps } from 'types/types.ds';
import React, { useEffect, useState } from 'react';
import Toast from '../../components/Toast/toast';
import Quantity from 'pages/RestaurantDetail/Quantity';
import useWindowDimensions from 'hooks/useWindowDimensions';
import TextArea from '../../components/TextArea';

interface PropsProductModal {
    product: ProductProps;
    isReadOnly?: boolean;
    onPressAddToCart: (
        product: ProductProps,
        extras_list: ExtraListProps[],
        quantity: number,
        total: number,
        special_instructions: string
    ) => void;
    onPressCancel: () => void;
}

const ProductView = ({ product, isReadOnly, onPressAddToCart, onPressCancel }: PropsProductModal) => {
    const [special_instructions, setSpecialInstructions] = useState<string>('');
    const [quantity, setQuantity] = useState<number>(1);
    const [extras_list, setExtrasList] = useState<ExtraListProps[]>([]);

    const { height } = useWindowDimensions();

    useEffect(() => {
        setExtrasList(product.extras_list);
    }, [product.extras_list]);

    const Group = ({ group }: { group: ExtraListProps }) => {
        let number_of_options = 'varias';

        if (group.min && group.max) {
            if (group.min === group.max) {
                number_of_options = `${group.min}`;
            } else {
                number_of_options = `${group.min} a ${group.max}`;
            }
        } else if (group.min && !group.max) {
            number_of_options = `mínimo ${group.min}`;
        } else if (!group.min && group.max) {
            number_of_options = `máximo ${group.max}`;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        width: '90%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        padding: 10,
                    }}
                >
                    <h5
                        style={{
                            fontWeight: 700,
                        }}
                    >
                        {group.multiple ? ` ${group.name?.toUpperCase()}` : `${group.name?.toUpperCase()}`}
                    </h5>
                    <div
                        style={{
                            marginBottom: 10,
                            marginLeft: 4,
                            color: 'rgb(118, 118, 118)',
                        }}
                    >
                        {group.multiple ? `(Elija ${number_of_options} opciones)` : `(Elija una opción)`}
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'right',
                        color: 'red',
                        marginRight: 'auto',
                    }}
                >
                    {group.required && 'Requerido'}
                </div>
            </div>
        );
    };

    const Option = (props: {
        group: ExtraListProps;
        option: OptionsProps;
        handleClick: (selected: boolean) => void;
    }) => {
        const { group, option, handleClick } = props;

        return (
            <div
                onClick={() => handleClick(!option.picked)}
                style={{
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <label className={group.multiple ? 'container_check' : 'container_radio'}>
                    {option.name}
                    <input type={group.multiple ? 'checkbox' : 'radio'} checked={option.picked} />
                    <span className="checkmark" />
                </label>
                <div>
                    {parseFloat(option.extra_price) > 0 && (
                        <span>
                            {group.pricing === 'add' ? '+' : ''} {option.extra_price}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    let unit_price: number = parseFloat(product.price);
    let extra_total = 0;

    // Calculate all extra prices with option "add"

    extras_list
        ?.filter(extra => extra.pricing === 'add')
        ?.forEach(item => {
            extra_total += item.options
                .filter(option => option.picked)
                .reduce((total, option) => total + parseFloat(option.extra_price), 0);
        });

    const pricing = extras_list?.find(extra => extra.pricing === 'replace') || null;

    if (pricing) {
        const option = pricing.options.find(option => option.picked);
        if (option) {
            unit_price = parseFloat(option.extra_price);
        }
    }

    const total = quantity * (unit_price + extra_total);

    return (
        <div
            style={{
                margin: 0,
                height: height - 120,
            }}
        >
            <div
                className="content"
                style={{
                    padding: 8,
                    height: '90%',
                    overflowY: 'auto',
                }}
            >
                <div
                    className={'thumbnail'}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxHeight: 268,
                        overflow: 'hidden',
                        borderRadius: 5,
                    }}
                >
                    <img
                        src={product.thumbnail ?? ''}
                        style={{
                            maxWidth: '100%',
                        }}
                        alt={product.name}
                    />
                </div>

                <div
                    className="description"
                    style={{
                        padding: 5,
                        marginTop: 20,
                    }}
                >
                    {product.description}
                </div>

                <div
                    className={'extras'}
                    style={{
                        marginTop: 20,
                    }}
                >
                    {extras_list?.map(group => {
                        return (
                            <div key={group.uid}>
                                <Group group={group} />

                                <div className="clearfix">
                                    {group.options?.map(option => (
                                        <Option
                                            key={option.uid}
                                            group={group}
                                            option={option}
                                            handleClick={() => {
                                                if (!option.in_stock) {
                                                    Toast.show(`Agotado por ahora`, {
                                                        position: Toast.position.CENTER,
                                                        duration: 900,
                                                    });

                                                    return;
                                                }

                                                // Lets keep only one selected if is not multiple
                                                if (!group.multiple) {
                                                    const _updated_extra_list = extras_list.map(extra => {
                                                        if (extra.uid === option.extra) {
                                                            const _options = extra.options.map(o => {
                                                                if (option.uid == o.uid) {
                                                                    return {
                                                                        ...o,
                                                                        picked: true,
                                                                    };
                                                                }
                                                                return {
                                                                    ...o,
                                                                    picked: false,
                                                                };
                                                            });
                                                            return {
                                                                ...extra,
                                                                options: _options,
                                                            };
                                                        }
                                                        return extra;
                                                    });

                                                    setExtrasList([..._updated_extra_list]);

                                                    return;
                                                }

                                                // Check if the quantity of selected items are the maximum of options allowed

                                                if (group.multiple && group.max) {
                                                    if (!option?.picked) {
                                                        const c =
                                                            group.options?.filter(option => option.picked)?.length + 1;
                                                        if (c > group.max) {
                                                            Toast.show(`Máximo ${group.max} opciones`, {
                                                                position: Toast.position.BOTTOM,
                                                                duration: 900,
                                                            });

                                                            return;
                                                        }
                                                    }
                                                }

                                                const _updated_extra_list = extras_list.map(_group => {
                                                    const updated_options = _group.options.map(_option => {
                                                        if (_option.uid === option.uid) {
                                                            return {
                                                                ...option,
                                                                picked: !option?.picked,
                                                            };
                                                        }
                                                        return _option;
                                                    });
                                                    return {
                                                        ..._group,
                                                        options: updated_options,
                                                    };
                                                });

                                                setExtrasList([..._updated_extra_list]);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {!isReadOnly ? (
                    <>
                        <h5
                            style={{
                                fontWeight: 700,
                                width: '90%',
                                padding: 10,
                            }}
                        >
                            Instrucciones Especiales
                        </h5>

                        <div className={'notes'}>
                            {product.owner.allow_special_instructions ? (
                                <TextArea
                                    placeHolder={'Agregar instrucciones especiales'}
                                    value={special_instructions}
                                    onChange={setSpecialInstructions}
                                />
                            ) : (
                                <div>*Esta tienda ha decidido no aceptar solicitudes especiales</div>
                            )}
                        </div>
                    </>
                ) : null}
            </div>

            {!isReadOnly ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 10,
                        height: '10%',
                    }}
                >
                    <div className="col-md-3" style={{ marginBottom: 10 }}>
                        <Quantity quantity={quantity} handleChange={setQuantity} />
                    </div>
                    <div className="col-md-9" style={{ marginBottom: 10 }}>
                        <button
                            type="reset"
                            className="btn_1 full-width"
                            style={{
                                fontWeight: 'bold',
                                fontSize: 17,
                                backgroundColor: '#00b970',
                            }}
                            onClick={() => {
                                onPressAddToCart(product, extras_list, quantity, total, special_instructions);
                            }}
                        >
                            Agregar {total.toFixed(2)}
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default ProductView;
