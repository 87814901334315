/**
 * Toast simulation mobile library
 */

import { message, notification } from 'antd';
import Swal from 'sweetalert2';

const position = {
    TOP: 40,
    BOTTOM: -40,
    CENTER: 0,
};

class Toast {
    static position = position;

    static sweet(m: string, params?: { duration?: number; position?: any; type?: 'success' | 'error' }) {
        Swal.fire({
            title: m,
            // text: m,
            icon: params?.type ?? 'success',
            confirmButtonText: 'ok',
            confirmButtonColor: 'rgb(0, 185, 112)',
            focusConfirm: false,
        });
    }

    static show(m: string, params?: { duration?: number; position?: any; type?: 'success' | 'error' }) {
        message.info(m);
        // notification[params.type || 'success']({
        //     message: m,
        //     description: '',
        //     placement: 'topRight',
        //     duration: 1,
        // });
    }
}

export default Toast;
