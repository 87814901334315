import { useEffect, useRef, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { BASE_URL_WS } from '../utils/settings';

const options = {
    maxRetries: 10,
};

interface UserNotificationProps {
    type: 'NEW-MESSAGE' | 'ORDER-CREATED' | 'ORDER-UPDATED';
    message: {
        type: string;
        payload: any;
    };
    payload: any;
}

const useUserSocketConnection = (user_uid: string | undefined, token: string | null) => {
    const socketRef = useRef<ReconnectingWebSocket>();
    const [new_notification, setNewNotification] = useState<UserNotificationProps>();

    useEffect(() => {
        if (!user_uid || !token) {
            return;
        }

        // Creates a WebSocket connection
        socketRef.current = new ReconnectingWebSocket(
            `${BASE_URL_WS}/ws/user/${user_uid}/?token=${token}`,
            [],
            options
        );

        socketRef.current.addEventListener('error', error => {
            console.log(error);
        });

        socketRef.current.addEventListener('message', message => {
            const data = JSON.parse(message.data);
            setNewNotification(data);
        });

        // Destroys the socket reference
        // when the connection is closed
        return () => {
            socketRef.current?.close();
        };
    }, [user_uid]);

    return { new_notification };
};

export default useUserSocketConnection;
