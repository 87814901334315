import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';

import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://6d292447208b4f3390dc367e66483fc6@o225151.ingest.sentry.io/6210472',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
