import React, { useState } from 'react';
import { Button as ANTButton } from 'antd';
import { OrderStore } from '../../stores/OrderStore';
import dayjs from 'dayjs';

interface Props {
    order_uid: string;
    onClose: () => void;
}

const Button = ({
    children,
    onClick,
}: {
    children?: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLElement>;
}) => (
    <ANTButton
        onClick={onClick}
        type={'primary'}
        style={{
            fontWeight: 'bold',
            fontSize: 18,
            margin: 4,
            width: 80,
            height: 40,
        }}
    >
        {children}
    </ANTButton>
);

const ConfirmPicker = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const handleClick = (time: number) => {
        const selected_date = dayjs().add(time, 'minute').toDate();
        const pickup_time = dayjs(selected_date).format('YYYY-MM-DD HH:mm:ss');
        setLoading(true);
        props.onClose();
        OrderStore.updateStatus(props.order_uid, 'in-progress', pickup_time)
            .catch(() => {
                alert('Error, por favor intenta muevamente');
                OrderStore.getOrders({
                    completed: false,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div
            className={'flex flex-col m-6'}
            style={{
                maxWidth: 400,
                margin: 'auto',
            }}
        >
            <div className={'flex flex-row justify-between items-center'}>
                <Button onClick={() => handleClick(15)}>+15</Button>
                <Button onClick={() => handleClick(20)}>+20</Button>
                <Button onClick={() => handleClick(25)}>+25</Button>
            </div>
            <div className={'flex flex-row justify-between'}>
                <Button onClick={() => handleClick(30)}>+30</Button>
                <Button onClick={() => handleClick(35)}>+35</Button>
                <Button onClick={() => handleClick(40)}>+40</Button>
            </div>

            <div className={'flex flex-row justify-center mt-4'}>
                <ANTButton
                    style={{
                        color: 'red',
                    }}
                    type={'link'}
                    onClick={() => {
                        setLoading(true);
                        props.onClose();
                        OrderStore.updateStatus(props.order_uid, 'cancelled')
                            .catch(() => {
                                alert('Error, por favor intenta muevamente');
                                OrderStore.getOrders({
                                    completed: false,
                                });
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                >
                    Cancelar
                </ANTButton>
            </div>
        </div>
    );
};

export default ConfirmPicker;
