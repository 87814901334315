import * as React from 'react';
import { useState, useEffect } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { observer } from 'mobx-react';
import { CompanyStore } from '../../stores/Admin/CompanyAdminStores';
import { CityStore } from '../../stores/CityStore';
import { CitiesProps } from '../../types/types.ds';

interface Props extends SelectProps {
    OnSelect?: (value: any) => void;
    onChange?: (value: CitiesProps) => void;
}
let busy: any;

const CityPicker = (props: Props) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        CityStore.getCities();
    }, []);

    const cities = CityStore.cities;

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            placeholder="Seleccione una ciudad"
            optionFilterProp="children"
            // filterOption={false}
            filterOption={(input, option: any) =>
                option?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={loading ? <Spin size="small" /> : null}
            onSearch={name => {
                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    search: name,
                };

                setLoading(true);

                busy = setTimeout(() => CompanyStore.getCompanies(params), 800);
            }}
            {...props}
            onChange={value => {
                if (props.onChange) {
                    const c = cities.find(city => city.uid === value.key);
                    if (c) props.onChange(c);
                }
            }}
        >
            {cities.map(entity => (
                <Select.Option key={entity.uid} value={entity.uid} entity={entity}>
                    {entity.name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default observer(CityPicker);
