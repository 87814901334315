import React from 'react';
import { Button, Form, Input, Checkbox } from 'antd';
import { ProductProps } from '../../../types/types.ds';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

interface Props {
    isLoading?: boolean;
    product: ProductProps;
    handleSave: (values: any) => void;
}

export const ScheduleForm = ({ isLoading, product, handleSave }: Props) => {
    return (
        <React.Fragment>
            <Form
                {...layout}
                initialValues={{
                    available_days: product.available_days,
                    start_time: product.start_time,
                    end_time: product.end_time,
                    start_date_available: product.start_date_available,
                    end_date_available: product.end_date_available,
                }}
                onFinish={values => {
                    const payload: any = {};
                    payload['available_days'] = values.available_days || null;
                    payload['start_time'] = values.start_time || null;
                    payload['end_time'] = values.end_time || null;
                    handleSave(payload);
                }}
            >
                <Form.Item
                    name="available_days"
                    label="Días"
                    rules={[{ required: false }]}
                    help={
                        'Use esta opción si es que desea que este item este disponible solo en determinados días, no seleccione nada si no aplica'
                    }
                >
                    <Checkbox.Group
                        options={[
                            { label: 'Lunes', value: 0 },
                            { label: 'Martes', value: 1 },
                            { label: 'Miércoles', value: 2 },
                            { label: 'Jueves', value: 3 },
                            { label: 'Viernes', value: 4 },
                            { label: 'Sábado', value: 5 },
                            { label: 'Domingo', value: 6 },
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    name="start_time"
                    label="Hora Inicio"
                    rules={[{ required: false }]}
                    help={'Use esta opción si es que desea que este item este disponible solo en determinadas horas'}
                >
                    <Input
                        style={{
                            maxWidth: '200px',
                        }}
                        type={'time'}
                        placeholder={'08.00'}
                    />
                </Form.Item>

                <Form.Item name="end_time" label="Hora Final" rules={[{ required: false }]}>
                    <Input
                        type={'time'}
                        placeholder={'12.00'}
                        style={{
                            maxWidth: '200px',
                        }}
                    />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" className="s-btn btn-link " loading={isLoading}>
                        <i className="fas fa-search" /> &nbsp; Guardar y Continuar
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};

export default ScheduleForm;
