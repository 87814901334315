import { ProductProps } from '../../types/types.ds';
import React from 'react';
import { Tag } from 'antd';

const Dish = ({ product, handleClick }: { product: ProductProps; handleClick: () => void }) => {
    const trimDescription = (text: string, maxLength = 50) => {
        if (text.length > 40) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    return (
        <div className="col-md-8 col-lg-6 my-1" onClick={handleClick}>
            <div
                className={'border px-4'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    // paddingBottom: '10px',
                    // paddingTop: '10px',
                    // borderBottom: '1px solid #d4d4d4',
                    minHeight: 150,
                }}
            >
                <div
                    className="information"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        WebkitBoxPack: 'center',
                        justifyContent: 'center',
                        minHeight: '142px',
                        width: '60%',
                    }}
                >
                    <div
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            maxWidth: '90%',
                        }}
                    >
                        {product.name}
                    </div>
                    <div
                        style={{
                            maxWidth: '90%',
                        }}
                    >
                        {trimDescription(product.description)}
                    </div>

                    <strong>${product.price}</strong>
                    <small style={{ color: 'red' }}> {product.in_stock ? '' : 'Agotado por hoy'}</small>
                </div>
                <div
                    className={'thumbnail'}
                    style={{
                        display: 'flex',
                        minHeight: '142px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40%',
                    }}
                >
                    <img
                        style={{
                            borderRadius: 10,
                            maxWidth: '100%',
                            maxHeight: 100,
                        }}
                        src={product.thumbnail ?? ''}
                        data-src={product.thumbnail}
                        alt={product.name}
                        className="lazy"
                    />
                </div>
            </div>
        </div>
    );
};

export default Dish;
