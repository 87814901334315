import { CategoryProps } from 'types/types.ds';
import React from 'react';
import { useScrollSection } from 'react-scroll-section/lib';

interface Props {
    category: CategoryProps;
    handleClick: (category: CategoryProps) => void;
}

const CategoryItem = ({ category, handleClick }: Props) => {
    const section = useScrollSection(category.uid);

    const active = section.selected;

    return (
        <li>
            <a
                className={`${active && 'active'}`}
                onClick={() => {
                    section.onClick();
                    handleClick(category);
                }}
            >
                {category.name}
            </a>
        </li>
    );
};

export default CategoryItem;
