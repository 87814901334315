import React from 'react';
import ANDROID_LOGO from '../../assets/images/android.svg';

const DownloadAndroidStore = () => {
    return (
        <div
            style={{
                width: 230,
            }}
        >
            <a href="https://play.google.com/store/apps/details?id=app.rushdelivery">
                <img src={ANDROID_LOGO}></img>
            </a>
        </div>
    );
};

export default DownloadAndroidStore;
