import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

interface MarkerProps {
    lat: number;
    lng: number;
}

const Marker = (props: MarkerProps) => (
    <div
        style={{
            position: 'absolute',
            width: 40,
            height: 40,
            backgroundColor: '#000',
            borderRadius: '100%',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <HomeRoundedIcon
            style={{
                color: '#fff',
            }}
        />
    </div>
);

interface Props {
    lat: number;
    lng: number;
    onChange: (value: { lat: number; lng: number }) => void;
}

const LocationPicker = (props: Props) => {
    const [defaultCenter] = useState<{ lat: number; lng: number }>({
        lat: props.lat,
        lng: props.lng,
    });
    const [value, setValue] = useState<{ lat: number; lng: number }>({
        lat: props.lat,
        lng: props.lng,
    });

    const lat = value?.lat;
    const lng = value?.lng;

    return (
        <div
            style={{
                width: '100%',
                height: 300,
            }}
        >
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDEA0FaTgML52XOs2UqtSI8-m9GmuDHbiA' }}
                defaultCenter={defaultCenter}
                defaultZoom={18}
                onChange={e => {
                    setValue(e.center);
                    props.onChange({
                        lat: e.center.lat,
                        lng: e.center.lng,
                    });
                }}
            >
                <Marker lat={lat} lng={lng} />
            </GoogleMapReact>
        </div>
    );
};

export default LocationPicker;
