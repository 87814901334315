import { action, observable, runInAction } from 'mobx';
import { get } from '../../utils/request';

export interface UserReportProps {
    uid: string;
    date_joined: Date;
    last_login: Date | null;
    first_name: string;
    last_name: string;
    email: string;
    phone: null | string;
    verified_phone: boolean;
    orders_count: number;
    active_devices: number;
    last_order_date: Date | null;
}

class User {
    @observable loading = false;
    @observable customers: Array<UserReportProps> = [];
    @observable count = 0;

    @action getReportAll(params: any) {
        this.loading = true;
        get('/reports/customers/', params)
            .then(r => {
                this.customers = r.data.results;
                this.count = r.data.count;
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }
}

export const UserAdminStore = new User();
