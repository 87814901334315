import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, PageHeader, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../../components/loading/loading';
import { CategoryAdminStore } from '../../../stores/Admin/CategoryAdminStore';
import { Helmet } from 'react-helmet';
import CategoryAddForm from '../../../components/categories/CategoryAddForm';
import { ProductAdminStore } from '../../../stores/Admin/ProductAdminStore';

const CategoryEdit = observer(() => {
    const history = useHistory();

    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //@ts-ignore
    const { uid } = params;

    const loadCategory = () => {
        CategoryAdminStore.getById(uid);
        // CategoryAdminStore.getExtraList(uid);
    };

    useEffect(() => {
        loadCategory();
    }, []);

    if (CategoryAdminStore.loading) {
        return <Loading />;
    }

    return (
        <div className="container margin_30_20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Modificar Categoria</title>
            </Helmet>
            <PageHeader
                style={{
                    marginTop: 20,
                }}
                onBack={() => history.goBack()}
                title={<div className={'capitalizeert'}>Regresar</div>}
            />

            <div className="container">
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Información Básica" key="1">
                        <CategoryAddForm
                            category={CategoryAdminStore.category}
                            handleSave={async payload => {
                                await CategoryAdminStore.update(uid, payload);
                                history.goBack();
                            }}
                        />
                        <Button
                            type="default"
                            style={{ backgroundColor: 'red', color: 'white' }}
                            onClick={() => {
                                if (CategoryAdminStore.category?.uid) {
                                    CategoryAdminStore.delete(CategoryAdminStore.category?.uid);
                                    history.push('/admin/categories/');
                                }
                            }}
                            loading={CategoryAdminStore.loading}
                        >
                            &nbsp; Eliminar
                        </Button>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
});

export default CategoryEdit;
