import { action, computed, observable, toJS } from 'mobx';
import { post } from '../utils/request';
import { ExtraListProps, ProductProps } from '../types/types.ds';
import { create, persist } from 'mobx-persist';

function UUID(length = 15) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export interface CartProps {
    key: string;
    product: ProductProps;
    quantity: number;
    extras_list: Array<ExtraListProps>;
    total: number;
    special_instructions?: string;
}

export interface CouponsProps {
    uid: string;
}

class Cart {
    /**
     * Line Items
     * @type {*[]}
     */

    @persist('list') @observable line_items: Array<CartProps> = [];
    @persist @observable loading_shipping_cost = false;
    @persist @observable shipping_cost: number | null = null;
    @persist @observable discount = 0;
    @persist('list') @observable coupons: CouponsProps[] = [];
    @persist('object') @observable location: {
        latitude: number;
        longitude: number;
    } = {
        latitude: -2.889005287006074,
        longitude: -78.77808783248126,
    };

    @action setDiscountValue(value: number) {
        this.discount = value;
    }

    //Todo fix this any type
    @action setCoupons(value: any) {
        this.coupons = value;
    }

    @computed get subtotal() {
        return this.line_items?.reduce((total, item) => total + item.total, 0) || 0;
    }

    @computed get total() {
        if (this.shipping_cost) {
            return this.subtotal - this.discount + this.shipping_cost;
        }
        return this.subtotal - this.discount;
    }

    @computed get number_cart_items() {
        return this.line_items?.reduce((total, item) => total + item.quantity, 0);
    }

    @action setShippingCost(shipping_cost: number | null) {
        this.shipping_cost = shipping_cost;
    }

    @action setLocation(location: { latitude: number; longitude: number }) {
        this.location = location;
        this.discount = 0;
    }

    @computed get store() {
        if (this.line_items.length > 0) {
            return this.line_items[0].product?.owner;
        }

        return null;
    }

    isSameStore = (product: ProductProps) => {
        return (
            this.line_items.filter(item => {
                return item.product.owner.uid !== product.owner.uid;
            }).length === 0
        );
    };

    addToCart = (
        product: ProductProps,
        quantity: number,
        extras_list: ExtraListProps[],
        total: number,
        special_instructions: string
    ) => {
        const new_item: CartProps = {
            key: UUID(),
            product: product,
            quantity: quantity,
            extras_list: toJS(extras_list),
            total: total,
            special_instructions: special_instructions,
        };

        if (this.line_items?.length) {
            this.line_items = [...this.line_items, new_item];
        } else {
            this.line_items = [new_item];
        }

        this.discount = 0;
    };

    updateCartItem = (
        product: ProductProps,
        quantity: number,
        extras_list: Array<ExtraListProps>,
        total: number,
        cartItemIndex: string,
        special_instructions = ''
    ) => {
        this.line_items = this.line_items.map(item => {
            if (item.key === cartItemIndex) {
                return {
                    ...item,
                    product: product,
                    quantity: quantity,
                    extras_list: toJS(extras_list),
                    total: total,
                    special_instructions: special_instructions,
                };
            }
            return {
                ...item,
            };
        });

        this.discount = 0;
    };

    addOrUpdateItemCart = (
        product: ProductProps,
        quantity: number,
        extras_list: Array<ExtraListProps>,
        total: number,
        special_instructions: ''
    ) => {
        let updated = false;
        this.line_items = this.line_items.map(item => {
            if (item.key === product.uid) {
                updated = true;
                return {
                    ...item,
                    product: product,
                    quantity: quantity,
                    extras_list: toJS(extras_list),
                    total: total,
                    special_instructions: special_instructions,
                };
            }
            return {
                ...item,
            };
        });

        if (!updated) {
            const new_item: CartProps = {
                key: product.uid,
                product: product,
                quantity: quantity,
                extras_list: toJS(extras_list),
                total: total,
            };

            if (this.line_items?.length) {
                this.line_items = [...this.line_items, new_item];
            } else {
                this.line_items = [new_item];
            }
        }

        this.discount = 0;
    };

    removeToCart = (key: string) => {
        this.line_items = this.line_items.filter(item => item.key !== key);
        this.discount = 0;
    };

    emptyCart = () => {
        this.line_items = [];
        this.discount = 0;
    };

    @action getShippingCost(lat: number, lng: number) {
        // Todo better way to get the owner
        const { uid } = this.store || {
            uid: null,
        };

        if (uid && this.subtotal > 0 && lat && lng) {
            const data = {
                store: uid,
                purchase_amount: this.subtotal,
                user_location: {
                    lat: lat,
                    lon: lng,
                },
            };

            this.loading_shipping_cost = true;
            post(`/orders/shipping-cost/`, data)
                .then(response => {
                    this.shipping_cost = response.data.shipping_cost;
                })
                .catch(err => {
                    console.log(err?.response?.data);
                })
                .finally(() => (this.loading_shipping_cost = false));
        } else {
            console.log('no store selected');
            this.shipping_cost = null;
        }

        console.log('shiping' + this.shipping_cost);
    }
}

const hydrate = create({
    storage: localStorage,
    jsonify: true,
});
export const CartStore = new Cart();

hydrate('CartStore', CartStore);
