import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal } from 'antd';
import DownloadAndroidStore from 'components/buttons/DownloadAndroidStore';
import DownloadAppStore from 'components/buttons/DownloadAppStore';

const DownloadModal = () => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        sessionStorage.setItem('opened', '1');
        setOpen(false);
    };

    React.useEffect(() => {
        if (!sessionStorage.getItem('opened')) {
            setOpen(true);
        }
    }, []);

    return (
        <div>
            <Modal visible={open} onCancel={handleClose} footer={[]} centered>
                <div className="mt-4">
                    <div className="flex flex-col items-center justify-center  m-auto max-w-md">
                        <div className="font-bold text-xl">Descarga la App Movil!</div>
                        <div className="font-thin px-2 mt-2 text-center">
                            Con la app todo es más fácil, ¡descarga la app a tu celular!{' '}
                        </div>
                        <div className="flex flex-row justify-center max-w-xs">
                            <DownloadAndroidStore />
                            <DownloadAppStore />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DownloadModal;
