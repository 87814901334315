import React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { MessageItem } from './MessageItem';
import { ChatStore, RoomProps } from '../../stores/ChatStore';

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
interface Props {
    onClickItem: (room: RoomProps) => void;
}

const MessagesList = (props: Props) => {
    const items = ChatStore.rooms
        .filter(r => r.messages.length)
        .map(room => {
            return (
                <MessageItem
                    key={room.uid}
                    room={room}
                    onClick={() => {
                        props.onClickItem(room);
                    }}
                />
            );
        });
    return (
        <div
            style={{
                padding: 20,
                overflowY: 'auto',
                height: 450,
            }}
        >
            {items}
        </div>
    );
};

export default observer(MessagesList);
